import React, { useContext } from 'react';
import { baseContext } from '../../../../respondent/common/contexts';
import { IndexData } from '../../../common/index-context';
import Checkbox from '../../../verizon_customer_case_v2024/components/checkbox';
import TwoColumns from '../opportunityAction/twoColumns';
import TwoColumnsLegend from '../opportunityAction/twoColumnsLegend';
import ArrowsNote from '../arrowsNote';
import Arrow from '../../../verizon_customer_case_v2024/components/arrow';

const PricingFeedback = () => {
  const { model, setModel } = useContext(baseContext);
  const { data } = useContext(IndexData);
  const dataPF = data.pricing_feedback;

  return (
    <div className="comparisons_plates">
      <div className="comparisons_section">
        <h2 className="comparisons_section-title">Pricing Feedback</h2>
        <Checkbox
          title="Add to Report"
          value={ model.pricing_feedback }
          paramName="pricing_feedback"
          onChange={ (v) => { setModel({ ...model, pricing_feedback: v }); } }
        />
      </div>

      <div className="comparisons_plates-grid">
        <div className="comparisons_plates-grid-item">
          <div className="comparisons_plate -full-height">
            <h3 className="comparisons_plate-title -center">Willingness to  Pay</h3>

            <div className="comparisons_grid">
              <div className="comparisons_grid-item -thin -two-columns-wrapper -no-title">
                <TwoColumns
                  value1={ dataPF.vcc_willingness_to_pay['45'].percent_value }
                  arrow1={ dataPF.vcc_willingness_to_pay['45'].arrow }
                  text1={ dataPF.vcc_willingness_to_pay['45'].percent_text }
                  value2={ dataPF.vcc_willingness_to_pay['12'].percent_value }
                  arrow2={ dataPF.vcc_willingness_to_pay['12'].arrow }
                  text2={ dataPF.vcc_willingness_to_pay['12'].percent_text }
                />
              </div>
              <div className="comparisons_grid-item wrapper-for-huge-symbol -two-columns-wrapper -no-title">
                <div className="huge-symbol-arrow-right" />
              </div>
              <div className="comparisons_grid-item -two-columns-wrapper -no-title">
                <div className="huge-number">
                  <div className="number">
                    { dataPF.vcc_how_much['AVG'].percent_text }
                    {
                      !!dataPF.vcc_how_much['AVG'].arrow &&
                      <Arrow color={ dataPF.vcc_how_much['AVG'].arrow } />
                    }
                  </div>
                  <div className="text">(average)</div>
                </div>
              </div>
            </div>

            <hr />
            <TwoColumnsLegend text1="Extremely/Very likely" text2="Not at all/Not very likely" />
          </div>
        </div>
      </div>
      <div className="comparisons_plate-legend -margin-xs">
        <ArrowsNote arrows={ data.arrows_mode } text={ data.arrows_label_text } blackText />
      </div>
    </div>
  );
};

export default PricingFeedback;
