import React from 'react';
import classnames from 'classnames';
import renderRawHtml from '../../../../../common/render_raw_html';
import { plainText } from '../../../../../common/string_utils';
import ZoomImage from '../zoom_image';
import ProcessView from './processView';
import VerizonStripe from './verizonStripe';

const ConceptView = ({
  className, title, leadInPhrase, leadInIsOther, customerBenefit, reasonsToBelieve,
  processKind, process, additionalImageUrl, additionalImageNotes, branded,
  zoomImage, imageClassName, details, priced, showImageIfExist
}) => {
  const full = title && processKind;
  return (
    <div className={ classnames("cit-concept", { '-full': full }, className) }>
      { !!title && <h2 { ...renderRawHtml(title) } /> }
      { !!plainText(customerBenefit) && <div className="cit-idea" { ...renderRawHtml(customerBenefit) } /> }
      { !!plainText(reasonsToBelieve) && <div className="cit-idea" { ...renderRawHtml(reasonsToBelieve) } /> }
      { !!plainText(details) && <div className="cit-details" { ...renderRawHtml(details) } /> }
      { !!plainText(priced) && <div className="cit-details" { ...renderRawHtml(priced) } /> }
      {
        !!leadInPhrase &&
        <h3
          className={ classnames("cit-steps-label", { "-other": leadInIsOther }) }
          { ...renderRawHtml(leadInPhrase) }
        />
      }
      {
        !!processKind &&
        <ProcessView
          processKind={ processKind }
          process={ process }
          zoomImage={ zoomImage }
          imageClassName={ imageClassName }
        />
      }
      {
        (full || showImageIfExist) && additionalImageUrl &&
        <div className="cit-image cit-additional-image">
          {
            zoomImage ? (
              <ZoomImage
                className={ classnames("radio-plate_part-image", imageClassName) }
                popupClassName="-custom-image-in-modal"
                src={ additionalImageUrl }
                altText={ additionalImageNotes }
              >
                <img src={ additionalImageUrl } alt={ additionalImageNotes } />
              </ZoomImage>
            ) : (
              <img
                className={ imageClassName }
                src={ additionalImageUrl }
                alt={ additionalImageNotes }
              />
            )
          }
        </div>
      }
      { branded === 'branded' && <VerizonStripe /> }
    </div>
  );
};

export default ConceptView;
