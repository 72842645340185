import React, { useContext } from 'react';
import { round } from 'lodash';
import { IndexData } from '../../../common/index-context';
import OpenEndFeedback from '../../../verizon_customer_case_v2024/components/openEndFeedback';


const IdeaAppeal = () => {
  const { data } = useContext(IndexData);

  const labels = data.labels;

  const appealStats = {
    high: {
      percent: round(data?.appeal_feedback?.stats?.high || 0), nsize: data?.appeal_feedback?.stats?.high_n
    },
    low: {
      percent: round(data?.appeal_feedback?.stats?.low || 0), nsize: data?.appeal_feedback?.stats?.low_n
    }
  };

  const donutTextAppeal = {
    high: `Mentions among the ${appealStats.high.percent}% (n=${appealStats.high.nsize}) of respondents who said the idea is extremely/very appealing.`,
    low: `Mentions among the ${appealStats.low.percent}% (n=${appealStats.low.nsize}) of respondents who said the idea is somewhat to not at all appealing.`
  };

  return (
    <>
      <h2 className="comparisons_section-title">Customer Feedback - Idea Appeal</h2>
      <OpenEndFeedback
        projectId={ data.project_id }
        conceptId={ data.concept_id }
        openEndData={ data.appeal_feedback }
        entityTitle="Customer Feedback - Idea Appeal"
        titles={ labels.appeal.slider_titles }
        donutCaptions={ labels.appeal.donut_labels }
        donutTexts={ donutTextAppeal }
        slidePrefix={ 'appeal' }
        questionText={ data.appeal_question_text }
        switchColors
      />
    </>
  );
};

export default IdeaAppeal;
