import React, { Fragment } from 'react';

const HiddenFields = ({ data, options, model }) => {
  return (
    <>
      <input type="hidden" name="step" id="step" value={ options.step } />
      <input type="hidden" name="generate_step" id="generate_step" value={ options.generate } />
      <input type="hidden" name="express" id="express" value={ data.express } />
      <input type="hidden" name="project[concepts_mode]" id="project_concepts_mode" value={ model.conceptsMode } />
      <input type="hidden" name="project[verizon_express_setting][questions_shown]" id="project_questions_shown" value={ model.questionsShown } />
      <input type="hidden" name="project[verizon_express_setting][concept_type]" id="project_concept_type" value={ model.conceptType } />
      <input type="hidden" name="project[verizon_express_setting][action_word]" id="project_action_word" value={ model.actionWord } />
      <input type="hidden" name="project[verizon_express_setting][priced]" id="project_priced" value={ model.priced } />
      <input type="hidden" name="project[verizon_express_setting][pay_frequency]" id="project_pay_frequency" value={ model.payFrequency } />
      <input type="hidden" name="project[verizon_express_setting][cit]" id="project_cit" value={ model.cit } />
      <input type="hidden" name="project[verizon_express_setting][process_description]" id="project_process_description" value={ model.processDescription } />
      <input type="hidden" name="project[verizon_express_setting][fee_and_policy]" id="project_fee_and_policy" value={ model.feeAndPolicy } />
      <input type="hidden" name="project[verizon_express_setting][ask_awareness]" id="project_ask_awareness" value={ model.askAwareness } />
      <input type="hidden" name="project[verizon_express_setting][existing_awareness]" id="project_existing_awareness" value={ model.existingAwareness } />
      <input type="hidden" name="project[verizon_express_setting][awareness_verb]" id="project_awareness_verb" value={ model.awarenessVerb } />
    </>
  );
};

export default HiddenFields;
