import Http from '../../../common/http';

const defaultUrl = 'verizon_customer_case_v2024';
const baseUrlByKind = {
  vcc2024: defaultUrl,
  v_cat2024: 'verizon_cat_v2024'
};

const sendApplyChanges = (
  { userId, email, visibilityGroups, sortingField, sortingOrder, kind },
  goodCallback, badCallback) => {
  Http.post(
    `/${baseUrlByKind[kind] || defaultUrl}/api/users/visibility_groups`,
    {
      user_id: userId,
      email,
      visibility_groups: visibilityGroups,
      sorting_field: sortingField,
      sorting_order: sortingOrder
    }
  ).then(
    (http) => {
      if (http.response.ok) {
        if (http.data.errors) {
          badCallback && badCallback(http);
        } else {
          goodCallback && goodCallback(http);
        }
      }
    },
    (reject) => {
      badCallback && badCallback(reject);
    });
};

const sendListUsers = ({ sortingField, sortingOrder, kind }, goodCallback, badCallback) => {
  Http.get(
    `/${baseUrlByKind[kind] || defaultUrl}/api/users`,
    { sorting_field: sortingField, sorting_order: sortingOrder }
  ).then(
    (http) => {
      if (http.response.ok) {
        goodCallback && goodCallback(http);
      } else {
        badCallback && badCallback(http);
      }
    },
    (reject) => {
      badCallback && badCallback(reject);
    }
  );
};

export { sendApplyChanges, sendListUsers };
