import React from 'react';
import Stimuli from '../../express/respondent/verizon_customer_case_v2024/components/stimuli';
import { baseContext } from '../../express/respondent/common/contexts';

const ScreenshotsApp = ({ data }) => {
  const {
    idea_name, customer_benefit, reasons_to_believe,
    process_lead_in_options, process_lead_in, process_lead_in_other,
    process_steps_kind, steps, additional_image_url, additional_image_notes, branded,
    translations, details, priced, show_image_if_exist
  } = data;

  const baseContextValue = { translations };
  return (
    <baseContext.Provider value={ baseContextValue }>
      <Stimuli
        className="-width-650"
        ideaName={ idea_name }
        customerBenefit={ customer_benefit }
        reasonsToBelieve={ reasons_to_believe }
        details={ details }
        priced={ priced }
        processLeadInOptions={ process_lead_in_options }
        processLeadIn={ process_lead_in }
        processLeadInOther={ process_lead_in_other }
        processStepsKind={ process_steps_kind }
        steps={ steps }
        showImageIfExist={ show_image_if_exist }
        additionalImageUrl={ additional_image_url }
        additionalImageNotes={ additional_image_notes }
        branded={ branded }
      />
    </baseContext.Provider>
  );
};

export default ScreenshotsApp;
