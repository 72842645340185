import React from 'react';
import Arrow from '../../../verizon_customer_case_v2024/components/arrow';


const MetricRow = ({ mainText, smallText, percent, arrow }) => {
  return (
    <div className="comparisons_plate-row">
      <div className="texts">
        <div className="title">{mainText}</div>
        <div className="note -sm -grayer">{smallText}</div>
      </div>
      <div className="value -black">
        {percent}
        <Arrow color={ arrow } />
      </div>
    </div>
  );
};


export default MetricRow;
