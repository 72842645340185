import React, { Fragment } from 'react';

const HiddenFields = ({ data, options, model }) => {
  return (
    <>
      <input type="hidden" name="step" id="step" value={ options.step } />
      <input type="hidden" name="generate_step" id="generate_step" value={ options.generate } />
      <input type="hidden" name="express" id="express" value={ data.express } />
      <input type="hidden" name="project[verizon_express_setting][idea_name]" id="project_idea_name" value={ model.ideaName } />
      <input type="hidden" name="project[verizon_express_setting][problem_solution]" id="project_problem_solution" value={ model.problemSolution } />
      <input type="hidden" name="project[verizon_express_setting][how_it_works]" id="project_how_it_works" value={ model.howItWorks } />
      <input type="hidden" name="project[verizon_express_setting][details]" id="project_details" value={ model.details } />
      <input type="hidden" name="project[verizon_express_setting][priced]" id="project_priced" value={ model.priced.value } />
      <input type="hidden" name="project[verizon_express_setting][priced_text]" id="project_priced_text" value={ model.pricedText } />
      <input type="hidden" name="project[verizon_express_setting][pay_frequency]" id="project_pay_frequency" value={ model.payFrequency } />
      <input type="hidden" name="project[verizon_express_setting][reasons_to_believe]" id="project_reasons_to_believe" value={ model.reasonsToBelieve } />
      <input type="hidden" name="project[verizon_express_setting][process_lead_in]" id="project_process_lead_in" value={ model.processLeadIn } />
      <input type="hidden" name="project[verizon_express_setting][process_lead_in_other]" id="project_process_lead_in_other" value={ model.processLeadInOther } />
      <input type="hidden" name="project[verizon_express_setting][deviation_scenario]" id="project_deviation_scenario" value={ model.deviationScenario } />
      <input type="hidden" name="project[verizon_express_setting][process_steps]" id="project_process_steps" value={ model.processSteps } />
      <input type="hidden" name="project[verizon_express_setting][additional_image_data]" id="project_additional_image_data" value={ model.additionalImageData || "" } />
      <input type="hidden" name="project[verizon_express_setting][additional_image_notes]" id="project_additional_image_notes" value={ model.additionalImageNotes || "" } />

      {
        model.steps.map((step, index) => {
          if (model.processSteps === 'text' && step.kind !== 'text' || model.processSteps === 'images' && step.kind === 'text') {
            if (!step.id) {
              return null;
            }
            return (
              <Fragment key={ index }>
                <input type="hidden" name="project[verizon_process_steps][][id]" id="project_verizon_process_steps_id" value={ step.id } />
                <input type="hidden" name="project[verizon_process_steps][][_destroy]" id="project_verizon_process_steps_destroy" value="1" />
              </Fragment>
            );
          }

          return (
            <Fragment key={ index }>
              <input type="hidden" name="project[verizon_process_steps][][id]" id="project_verizon_process_steps_id" value={ step.id || "" } />
              <input type="hidden" name="project[verizon_process_steps][][position]" id="project_verizon_process_steps_position" value={ index } />
              <input type="hidden" name="project[verizon_process_steps][][notes]" id="project_verizon_process_steps_notes" value={ step.notes || "" } />
              <input type="hidden" name="project[verizon_process_steps][][kind]" id="project_verizon_process_steps_kind" value={ step.kind || "" } />
              <input type="hidden" name="project[verizon_process_steps][][image_data]" id="project_verizon_process_steps_image_data" value={ step.image_data || "" } />
              <input type="hidden" name="project[verizon_process_steps][][source_data]" id="project_verizon_process_steps_source_data" value={ step.source_data || "" } />
              <input type="hidden" name="project[verizon_process_steps][][s3_folder]" id="project_verizon_process_steps_s3_folder" value={ step.s3_folder || "" } />
              <input type="hidden" name="project[verizon_process_steps][][base_css_file_name]" id="project_verizon_process_steps_base_css_file_name" value={ step.base_css_file_name || "" } />
              <input type="hidden" name="project[verizon_process_steps][][unique_css_file_name]" id="project_verizon_process_steps_" value={ step.unique_css_file_name || "" } />
              <input type="hidden" name="project[verizon_process_steps][][html_file_name]" id="project_verizon_process_steps_" value={ step.html_file_name || "" } />
              <input type="hidden" name="project[verizon_process_steps][][prefix]" id="project_verizon_process_steps_prefix" value={ step.prefix || "" } />
              <input type="hidden" name="project[verizon_process_steps][][_destroy]" id="project_verizon_process_steps_destroy" value={ step._destroy || "" } />
            </Fragment>
          );
        })
      }
    </>
  );
};

export default HiddenFields;
