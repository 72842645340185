import React, { useState, useEffect, useRef, useContext } from 'react';
import { map, each, isEmpty, keys, find, trim }  from 'lodash';
import classnames from 'classnames';
import renderRawHtml from '../../../../../../common/render_raw_html';
import ZoomImage from "../../zoom_image";
import Grid from "../grid";
import { baseContext } from "../../../../common/contexts";
import { OpenEnd } from '../openEnd';
import { plainText } from "../../../../../../common/string_utils";
import { Number } from '../../screening/number';

const Question = ({
  question, questionNotice, underTextHtml,
  selectAnswer, noText, customText
}) => {
  const questionText = noText ? '' : (customText || question.replacedText || question.text);
  const questionTextAria = customText || question.text;
  const single = question.kind === 'Single';
  const multi = question.kind === 'Multi';
  const grid = question.kind === 'Grid';
  const number = question.title === 'HOW MUCH WILLING TO PAY';
  const open = question.kind === 'Open' && !number;
  const answers = question.detailed_answers || question.answers;

  const [ radioAnswer, updateRadioAnswer ] = useState(null);
  const [ checkboxAnswers, updateCheckboxAnswers ] = useState({});
  const [ otherAnswer, setOtherAnswer ] = useState('');
  const [ gridAnswers, updateGridAnswers ] = useState({});
  const [ openEndAnswer, updateOpenEndAnswer ] = useState('');

  const otherTextRef = useRef([]);
  const { translations } = useContext(baseContext);

  const isOther = (id) => ((id || '').match(/_-2/));
  const isNone = (id) => ((id || '').match(/_-1/));
  const haveOther = find(answers, (answer) => isOther(answer.id));
  const isRadioOtherSelected = () => (isOther(radioAnswer));
  const isCheckboxOtherSelected = () => (
    find(keys(checkboxAnswers), (item) => isOther(item))
  );
  const getImage = (id) => (
    question.answer_images?.find((item) => item?.id === id)?.image_url
  );

  const defineDisabledByKind = (kind) => {
    let newDisabled;
    let otherSelected;
    let enableWhenPresent;

    if (kind === 'checkbox') {
      otherSelected = isCheckboxOtherSelected();
      enableWhenPresent = !otherSelected || !!trim(otherAnswer);
      newDisabled = isEmpty(checkboxAnswers) || !enableWhenPresent;
    } else if (kind === 'grid') {
      newDisabled = isEmpty(gridAnswers) || keys(gridAnswers).length < answers.length;
    } else if (kind === 'open') {
      newDisabled = !openEndAnswer;
    } else if (kind === 'number') {
      newDisabled = (parseFloat(openEndAnswer) || 0) < 0 || openEndAnswer === '';
    } else {
      otherSelected = isRadioOtherSelected();
      enableWhenPresent = !otherSelected || !!trim(otherAnswer);
      newDisabled = !radioAnswer || !enableWhenPresent;
    }
    return newDisabled;
  };

  const updateOtherText = (event) => {
    setOtherAnswer(event.target.value);
  };

  const updateStateAndSend = (isRadio) => {
    if (isRadio && !grid) {
      const newDisabled = defineDisabledByKind('radio');
      const otherText = isRadioOtherSelected() && !isEmpty(otherAnswer) ?
        otherAnswer :
        null;
      selectAnswer(
        newDisabled,
        isEmpty(radioAnswer) ? null : [ radioAnswer ],
        otherText
      );
    } else if (isRadio && grid) {
      const newDisabled = defineDisabledByKind('grid');
      const values = map(keys(gridAnswers), (key) => (`${key}_${gridAnswers[key]}`));
      selectAnswer(newDisabled, values, null);
    } else if (open) {
      const newDisabled = defineDisabledByKind('open');
      selectAnswer(newDisabled, { [question.name]: openEndAnswer }, null);
    } else if (number) {
      const newDisabled = defineDisabledByKind('number');
      selectAnswer(newDisabled, { [question.name]: openEndAnswer }, null);
    } else {
      const newDisabled = defineDisabledByKind('checkbox');
      const otherText = isCheckboxOtherSelected() && !isEmpty(otherAnswer) ?
        otherAnswer :
        null;
      const preparedAnswers = isEmpty(checkboxAnswers) ?
        null :
        map(checkboxAnswers, (value, key) => `${key}_${value}`);
      selectAnswer(newDisabled, preparedAnswers, otherText);
    }
  };

  useEffect(() => {
    updateStateAndSend(false);
  }, [ checkboxAnswers ]);

  useEffect(() => {
    updateStateAndSend(true);
  }, [ radioAnswer ]);

  useEffect(() => {
    updateStateAndSend(!multi);
  }, [ otherAnswer ]);

  useEffect(() => {
    updateStateAndSend(true);
  }, [ gridAnswers ]);

  useEffect(() => {
    updateStateAndSend(false);
  }, [ openEndAnswer ]);

  const updateCheckbox = (value) => {
    updateCheckboxAnswers({ ...value });
  };
  const updateRadio = (value) => {
    updateRadioAnswer(value);
  };

  const radioChange = (value) => {
    updateRadio(value);
  };

  const radioClick = (event, answerId, focusNext) => {
    // for click only by mouse (and not by keyboard) add condition 'event.clientX > 0'
    if (focusNext && isOther(answerId)) {
      setTimeout(() => {
        focusNext.focus();
      }, 0);
    }
  };

  const checkboxChange = (value, answerId, focusNext) => {
    if (value) {
      if (isNone(answerId)) {
        each(checkboxAnswers, (v, k) => {
          delete checkboxAnswers[k];
        });
      }
      checkboxAnswers[answerId] = value;
      if (focusNext && isOther(answerId)) {
        setTimeout(() => {
          focusNext.focus();
        }, 0);
      }
    } else {
      delete checkboxAnswers[answerId];
    }
    updateCheckbox(checkboxAnswers);
  };

  const disableCheckboxItem = (answerId) => {
    if (isNone(answerId)) {
      return false;
    }
    return !!find(checkboxAnswers, (v, k) => {
      return isNone(k);
    });
  };

  const singleOutput = () => (
    <ul
      className={
        classnames(
          "survey-options_list",
          { "-full-width": haveOther }
        )
      }
      role="radiogroup"
      aria-label={ plainText(questionTextAria) }
    >
      {
        map(answers, (answer, idx) => (
          <li
            key={ answer.id }
            className={
              classnames({
                'option-top-border': isNone(answer.id)
              })
            }
          >
            {
              !getImage(answer.id) &&
              <div
                className={
                  classnames(
                    "radio",
                    { "-full-width": isOther(answer.id) }
                  )
                }
              >
                <input
                  type="radio"
                  name={ question.name }
                  value={ answer.id }
                  id={ answer.id }
                  checked={ answer.id === radioAnswer }
                  onChange={ () => { radioChange(answer.id); } }
                  onClick={ (event) => {
                    radioClick(event, answer.id, otherTextRef.current[idx]);
                  } }
                />
                {
                  isOther(answer.id) &&
                  <label
                    className={
                      classnames(
                        "radio_label -centered",
                        { "-full-width": isOther(answer.id) }
                      )
                    }
                    htmlFor={ answer.id }
                  >
                    <div className="other-text_wrapper">
                      <div className="other-text_title" { ...renderRawHtml(`${answer.label}:`) } />
                      <input
                        type="text"
                        name={ `${question.name}-other` }
                        ref={ (el) => otherTextRef.current[idx] = el }
                        className="js-other-text form-field -other-text"
                        disabled={ answer.id !== radioAnswer }
                        value={ otherAnswer }
                        onChange={ updateOtherText }
                        aria-label={ translations.enter_text_specify }
                        autoComplete="off"
                      />
                    </div>
                  </label>
                }
                {
                  !isOther(answer.id) &&
                  <label
                    className="radio_label"
                    htmlFor={ answer.id }
                    { ...renderRawHtml(answer.label) }
                  />
                }
              </div>
            }
            {
              getImage(answer.id) &&
              <div className="radio-plate -base" role="group">
                <div className="radio-plate_part -no-padding -right-margin -clickable">
                  <div className="radio">
                    <input
                      type="radio"
                      name={ question.name }
                      value={ answer.id }
                      id={ answer.id }
                      checked={ answer.id === radioAnswer }
                      aria-label={ answer.label || plainText(questionTextAria) }
                      onChange={ () => {
                        radioChange(answer.id);
                      } }
                    />
                    <label htmlFor={ answer.id } className="radio_label -no-text">
                      <span className="radio_label_hidden-text" aria-hidden>{answer.label || plainText(questionTextAria)}</span>
                    </label>
                  </div>
                </div>
                <div className="radio-plate_part-image">
                  <div
                    className="radio-plate_part-title"
                    aria-hidden
                    { ...renderRawHtml(answer.label) }
                  />
                  <div className="survey-options_image-plate -fix-width">
                    <ZoomImage
                      className="radio-plate_part-image"
                      popupClassName="-custom-image-in-modal"
                      src={ getImage(answer.id) }
                      altText={ answer.label || plainText(questionTextAria) }
                    >
                      <img src={ getImage(answer.id) } alt={ answer.label || plainText(questionTextAria) } />
                    </ZoomImage>
                  </div>
                </div>
              </div>
            }
          </li>
        ))
      }
    </ul>
  );

  const multiOutput = () => (
    <ul
      className={
        classnames(
          "survey-options_list",
          { "-full-width": haveOther }
        )
      }
      role="radiogroup"
      aria-label={ plainText(questionTextAria) }
    >
      {
        map(answers, (answer, idx) => (
          <li
            key={ answer.id }
            className={
              classnames({
                'option-top-border': isNone(answer.id)
              })
            }
          >
            <div className="option-list-inner">
              {
                !getImage(answer.id) &&
                <div
                  className={
                    classnames(
                      "checkbox",
                      { "-full-width": isOther(answer.id) }
                    )
                  }
                >
                  <input
                    type="checkbox"
                    name={ question.name }
                    id={ answer.id }
                    disabled={ disableCheckboxItem(answer.id) }
                    checked={ !!checkboxAnswers[answer.id] }
                    onChange={ (event) => {
                      checkboxChange(event.target.checked, answer.id, otherTextRef.current[idx]);
                    } }
                  />
                  {
                    isOther(answer.id) &&
                    <label
                      className={
                        classnames(
                          "checkbox_label -centered",
                          { "-full-width": isOther(answer.id) }
                        )
                      }
                      htmlFor={ answer.id }
                    >
                      <div className="other-text_wrapper">
                        <div className="other-text_title" { ...renderRawHtml(`${answer.label}:`) } />
                        <input
                          type="text"
                          name={ `${question.name}-other` }
                          ref={ (el) => otherTextRef.current[idx] = el }
                          className="js-other-text form-field -other-text"
                          disabled={ disableCheckboxItem(answer.id) || !checkboxAnswers[answer.id] }
                          value={ otherAnswer }
                          onChange={ updateOtherText }
                          aria-label={ translations.enter_text_specify }
                          autoComplete="off"
                        />
                      </div>
                    </label>
                  }
                  {
                    !isOther(answer.id) &&
                    <label
                      className="checkbox_label"
                      htmlFor={ answer.id }
                      { ...renderRawHtml(answer.label) }
                    />
                  }
                </div>
              }
              {
                getImage(answer.id) &&
                <div className="radio-plate -base" role="group">
                  <div className="radio-plate_part -no-padding -clickable">
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        name={ question.name }
                        disabled={ disableCheckboxItem(answer.id) }
                        id={ answer.id }
                        checked={ !!checkboxAnswers[answer.id] }
                        aria-label={ answer.label || plainText(questionTextAria) }
                        onChange={ (event) => {
                          checkboxChange(event.target.checked, answer.id);
                        } }
                      />
                      <label htmlFor={ answer.id } className="checkbox_label -no-text">
                        <span className="checkbox_label_hidden-text" aria-hidden>{answer.label || plainText(questionTextAria)}</span>
                      </label>
                    </div>
                  </div>
                  <div className="radio-plate_part-image">
                    <div
                      className="radio-plate_part-title"
                      aria-hidden
                      { ...renderRawHtml(answer.label) }
                    />
                    <div className="survey-options_image-plate -fix-width">
                      <ZoomImage
                        className="radio-plate_part-image"
                        popupClassName="-custom-image-in-modal"
                        src={ getImage(answer.id) }
                        altText={ answer.label || plainText(questionTextAria) }
                      >
                        <img src={ getImage(answer.id) } alt={ answer.label || plainText(questionTextAria) } />
                      </ZoomImage>
                    </div>
                  </div>
                </div>
              }
            </div>
          </li>
        ))
      }
    </ul>
  );

  return (
    <>
      <div className="survey-options_question">
        <h2
          className="survey-question-group-item_title"
          { ...renderRawHtml(questionText) }
        />
        { underTextHtml }
        {
          !!questionNotice &&
          <div
            className="survey-question-note"
            { ...renderRawHtml(questionNotice) }
          />
        }
      </div>
      <div
        className={
          classnames(
            "survey-layout_container",
            { "-custom-metric": !open, "-grid": grid, "-open": open }
          )
        }
      >
        <div
          className={
            classnames(
              "survey-options",
              { "-custom-metric": !grid && !open, "-grid": grid, "-open": open, "-not-grid": !grid }
            )
          }
        >
          { single && singleOutput() }
          { multi && multiOutput() }
          {
            grid &&
            <Grid
              question={ question }
              gridAnswers={ gridAnswers }
              updateGridAnswers={ updateGridAnswers }
              updateStateAndSend={ updateStateAndSend }
            />
          }
          { open && <OpenEnd question={ question } updateText={ updateOpenEndAnswer } /> }
          {
            number &&
            <Number
              question={ question }
              updateValue={ updateOpenEndAnswer }
              prefix="$"
              postfix={ translations.pay_frequency_text }
            />
          }
        </div>
      </div>
    </>
  );
};

export default Question;
