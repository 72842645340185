import {
  filter, findIndex, map, find, values, each, fromPairs, toPairs
} from 'lodash';

const condIndexHash = {
  other: {
    notConverted: -2,
    converted: 'other'
  },
  noneOfTheAbove: {
    notConverted: -1,
    converted: 'noneOfTheAbove'
  }
};

const fromNotConvertedToConverted = (index) => {
  if (index > 0) {
    return (index - 1);
  }
  const foundCond = find(
    values(condIndexHash),
    (el) => el.notConverted === index
  );
  if (!foundCond) {
    return null;
  }
  return foundCond.converted;
};

const initConditionList = (question, gridData, images = {}) => {
  question.condition_list = map(question.condition, (c, idx) => {
    const index = parseInt(idx, 10);
    return {
      index: fromNotConvertedToConverted(index),
      cond: gridData?.conditions?.[index] || c,
      quota: question.kind === "Grid" ? gridData?.quotas?.[idx] : question.quotas[idx] || ''
    };
  });
  question.answer_images = images;
  return question;
};

const reIndexConditionList = (q, delIndex) => {
  // We already deleted one answer, so we take "length"
  // not "length" - 1
  if (q.answers.length === delIndex) {
    return;
  }
  each(q.condition_list, (el) => {
    if (parseInt(el.index, 10) && el.index > delIndex) {
      el.index -= 1;
    }
  });
};

const findConditionByIndex = (question, index) => {
  const cIdx = findIndex(question.condition_list, (c) => {
    return c.index === index;
  });
  if (cIdx === -1) {
    return null;
  }
  return question.condition_list[cIdx].cond;
};

const getConditionForQuestion = (question) => {
  const generatedConditions = {};
  each(question.answers, (answer, idx) => {
    const found = findConditionByIndex(question, idx);
    if (question.kind === "Grid") {
      const asArray = map(toPairs(found), (item) => [ parseInt(item[0]) + 1, item[1] ]);
      generatedConditions[idx + 1] = fromPairs(asArray);
    } else {
      generatedConditions[idx + 1] = found;
    }
  });
  // We should not include condition for noneOfTheAbove and Other Grid questions
  if (question.kind !== "Grid") {
    each(values(condIndexHash), (value) => {
      generatedConditions[value.notConverted] = findConditionByIndex(
        question, value.converted
      );
    });
  }
  return generatedConditions;
};

const getQuotasForQuestion = (question) => {
  const generatedQuotas = {};
  each(question.answers, (answer, index) => {
    const found = find(
      question.condition_list,
      (item) => item.index === index
    );
    if (found?.quota) {
      generatedQuotas[index + 1] = found.quota;
    }
  });
  each(values(condIndexHash), (value) => {
    const found = find(
      question.condition_list,
      (item) => item.index === value.converted
    );
    if (found?.quota) {
      generatedQuotas[value.notConverted] = found.quota;
    }
  });

  return generatedQuotas;
};

const calculateGridQuota = (q) => {
  each(q.condition_list, (el) => {
    el.quota = fromPairs(map(q.scales, (scale, index) => ([ index, "" ])));
  });

  const conditionsList = map(q.answers, (answer, index) => (
    find(q.condition_list, (el) => (el.index === index))
  ));

  each(conditionsList, (el) => {
    if (!el?.cond) {
      return;
    }
    const hashSums = { appliedSum: 0, restSum: 0 };
    const asArray = Object.entries(el.cond);
    const filteredConditionsList = filter(asArray, (item) => (item[1] !== 'is_not'));
    const quota = {};
    each(filteredConditionsList, (item, idx) => {
      const isLast = (idx === filteredConditionsList.length - 1);
      if (el) {
        quota[item[0]] = `${calculateQuotaValue(hashSums, filteredConditionsList.length, isLast)}`;
      }
    });
    el.quota = quota;
  });
};

const findConditionItem = (question, index) => {
  let found = find(question.condition_list, (el) => {
    return el.index === index;
  });

  if (!found) {
    found = { index, cond: '' };
    question.condition_list.push(found);
  }

  return found.cond;
};

const findGridConditionItem = (question, rowIndex, index) => {
  let found = find(question.condition_list, (el) => {
    return el.index === rowIndex;
  });
  if (!found && found?.length !== 0) {
    found = {};
    found.index = rowIndex;
    found.cond = fromPairs(map(question.scales, (scale, index) => ([ index, "" ])));
    found.quota = fromPairs(map(question.scales, (scale, index) => ([ index, "" ])));
    if (!question.condition_list) {
      question.condition_list = [];
    }
    question.condition_list.push(found);
  }

  return found.cond[index];
};

const calculateGridQuotaItem = (q, index) => {
  const found = find(q.condition_list, (el) => {
    return el.index === index;
  });
  found.quota = fromPairs(map(q.scales, (scale, index) => ([ index, "" ])));

  if (!found?.cond) {
    return;
  }
  const hashSums = { appliedSum: 0, restSum: 0 };
  const asArray = Object.entries(found.cond);
  const filteredConditionsList = filter(asArray, (item) => (item[1] !== 'is_not'));
  const quota = {};
  each(filteredConditionsList, (item, idx) => {
    const isLast = (idx === filteredConditionsList.length - 1);
    quota[item[0]] = `${calculateQuotaValue(hashSums, filteredConditionsList.length, isLast)}`;
  });
  found.quota = quota;
};

const filteredQuestionsForFields = (questions) => filter(
  questions, (el) => (!el._destroy || el.id)
);

const firstNotDeletedIndex = (question, questionIndex, questions) => {
  if (question._destroy) {
    return false;
  }
  let index = 0;
  let destroyed = true;
  while (index < questionIndex && destroyed) {
    destroyed = questions[index]._destroy;
    if (destroyed) {
      index += 1;
    }
  }
  return index === questionIndex;
};

const reorderQuestions = (items) => {
  const filteredList = filter(items, (q) => !q._destroy);
  each(filteredList, (item, index) => (item.position = index));
};

export {
  condIndexHash,
  initConditionList,
  reIndexConditionList,
  getConditionForQuestion,
  getQuotasForQuestion,
  calculateGridQuota,
  calculateGridQuotaItem,
  findConditionItem,
  findGridConditionItem,
  filteredQuestionsForFields,
  firstNotDeletedIndex,
  reorderQuestions
};
