import React from "react";
import SvgIcon from '../../../../common/components/svg-icon';

const Arrow = ({ color }) => {
  if (!color) {
    return (null);
  }

  return (
    <span className={ `arrow -${color}` }>
      {color === 'red' && <SvgIcon name="arrow-pointer-down" className="icon-link_icon" />}
      {color === 'green' && <SvgIcon name="arrow-pointer-up" className="icon-link_icon" />}
    </span>
  );
};

export default Arrow;
