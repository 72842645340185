import React from 'react';
import { map } from "lodash";

const ShortAISummaries = ({ text, shorts }) => {
  return (
    <div className="comparisons_plate-section">
      <div className="comparisons_plate-text">
        <p className="grayer">{text}</p>
        <ul>
          {map(shorts, (summary, index) => (
            <li key={ index } className="bold">{summary}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};


export default ShortAISummaries;
