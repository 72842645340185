import React, { useState, useContext } from 'react';
import classnames from 'classnames';
import AnswerPlayControl from "../../../common/components/express-open-end-card/answer-play-control";
import SvgIcon from "../../../../../common/components/svg-icon";
import Http from "../../../../../common/http";
import { OpenEndContext } from '../../../common/openEndContext';

const AnswerRow = ({ projectId, conceptId, record, onToggleVisibility }) => {
  const [ , setHidden ] = useState(record.hidden);
  const { isAdmin } = useContext(OpenEndContext);
  const toggleVisibility = (hide) => {
    onToggleVisibility(record, hide);
    setHidden(hide);

    const url = `/dashboard/api/open_ends/database_concepts/${conceptId}/answers/${hide ? 'hide' : 'unhide'}`;

    Http.post(
      url,
      {
        linked_id: record.id,
        linked_type: record.type
      }
    ).then(
      (http) => {
        if (http.response.status === 200) { }
      },
      (reject) => {console.log('something went wrong', reject);}
    );
  };

  const hideAnswer = () => toggleVisibility(true);
  const unhideAnswer = () => toggleVisibility(false);

  return (
    <li className={ classnames("sentiments-list_item sentiment", { '-hidden': record.hidden }) }>
      <div className="sentiment_content">
        <div className="answer-block">
          <div className="answer-block_txt">{record.answer}</div>
          <div className="answer-block_author">{record.signature}</div>
        </div>
      </div>

      {
        isAdmin &&
        <div className="admin_control">
          {
            record.hidden ? (
              <div className="info-tooltip -left-margin">
                <SvgIcon role="button" className="eye-button" name="eye-closed" onClick={ unhideAnswer } />
                <div className="info-tooltip_drop -left">
                  <div className="info-tooltip_drop-inner -tiny">Show for client</div>
                </div>
              </div>
            ) : (
              <div className="info-tooltip -left-margin">
                <SvgIcon role="button" className="eye-button" name="eye-open" onClick={ hideAnswer } />
                <div className="info-tooltip_drop -left">
                  <div className="info-tooltip_drop-inner -tiny">Hide for client</div>
                </div>
              </div>
            )
          }
        </div>
      }
      <AnswerPlayControl
        conceptId={ conceptId }
        answer={ record }
        projectId={ projectId }
      />

    </li>
  );
};

export default AnswerRow;
