import React, { useState } from 'react';
import { map } from 'lodash';
import TrGray from '../../../../../verizonCustomerCaseV2024/admin/projects/components/comparison/trGray';
import SliderRadioButtons
  from '../../../../../express/researcher/verizon_customer_case_v2024/components/sliderRadioButtons';
import CommonTrs from './commonTrs';


const Reasons = ({ pids, data }) => {
  const [ filter, setFilter ] = useState('high');

  const options = [
    { value: 'high', title: 'High Likelihood' },
    { value: 'low', title: 'Low Likelihood' }
  ];

  const rData = data.reasons;

  const handleChangeFilter = (newVal) => {
    setFilter(newVal);
  };

  return (
    <div className="comparisons-table_wrapper">
      <table className="table comparisons-table">
        <tbody>
          <CommonTrs pids={ pids } data={ data } />

          <TrGray colSpan={ pids.length + 1 } text="Reasons to Take Action" />

          <tr>
            <td className="col-name -sticky-left -with-tabs">
              <SliderRadioButtons
                initValue={ filter }
                options={ options }
                onChange={ handleChangeFilter }
                modificator={ `-gray-bg -bordered -in-table -full-width -items-${options.length}` }
              />
            </td>
            {map(pids, (pid) => (
              <td className="col-data -center" key={ pid }>
                ({rData[pid][`n_${filter}`]})
              </td>
            ))}
          </tr>

          <tr>
            <td className="col-name -sticky-left -top -grayed">
              {
                filter === 'high' ? (
                  <>
                    Unaided, top themes among respondents who are extremely/very likely to take action.
                  </>
                ) : (
                  <>
                    Unaided, top themes among respondents who are somewhat to not at all likely to take action.
                  </>
                )
              }
            </td>
            {map(pids, (pid) => (
              <td key={ pid } className="col-data -top">
                {map(rData[pid][`ai_summary_${filter}`], (record, index) => (
                  <div key={ index } className="paragraph">
                    <b>{record.name}:</b> {record.long_summary}
                  </div>
                ))}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Reasons;
