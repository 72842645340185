import React from 'react';

const TwoColumnsLegend = ({ text1, text2 }) => {
  return (
    <div className="comparisons_plate-legend">
      <div className="comparisons_legend black-text">
        <div className="comparisons_legend-grid">
          <div className="comparisons_legend-grid-item">
            <div className="comparisons_legend-label gray1">&nbsp;</div>
            <div className="comparisons_legend-text lighter">{text1}</div>
          </div>
          <div className="comparisons_legend-grid-item">
            <div className="comparisons_legend-label gray2">&nbsp;</div>
            <div className="comparisons_legend-text lighter">{text2}</div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default TwoColumnsLegend;
