import React, { Fragment, useRef, useState, useEffect } from 'react';
import { find, isEmpty, map, includes, intersection, filter } from 'lodash';
import cn from "classnames";
import Header from "../../common/header";
import WithSubmit from "../../common/WithSubmit";
import FieldHeaderWithText from "../../common/FieldHeaderWithText";
import Select from "../../verizon_customer_case_v2024/common/select";
import DatePicker from "../../verizon_customer_case_v2024/common/datePicker";
import PageLoader from '../../common/PageLoader';
import Radio from '../../common/radio';
import HiddenFields from "./hiddenFields";
import EmailsList from './emailsList';
import BrandedTabs from "./brandedTabs";

const App = ({ data, options, errors }) => {
  const [ model, setModel ] = useState({
    platformId: data.platform_id || '',
    title: options.verizon_cat_project_title || '',
    segment: data.verizon_express_setting.segment || 'gen_pop',
    testKind: data.verizon_express_setting.test_kind || '',
    branded: data.verizon_express_setting.branded || '',
    brand: data.verizon_express_setting.brand || '',
    note: data.note || '',
    submissionDate: data.verizon_express_setting.submission_date || '',
    softwareProduct: data.verizon_express_setting.software_product || '',
    audienceCategory: data.verizon_express_setting.audience_category || '',
    subgroups: (
      data.verizon_express_setting.subgroups ||
      [ find(options.subgroups_list, (item) => item.default_check).value ]
    ),
    subgroupsList: options.subgroups_list,
    subgroupsOther: data.verizon_express_setting.subgroups_other || '',
    projectLeadEmail: data.verizon_express_setting.project_lead_email || '',
    emails: data.verizon_express_setting.emails || [],
    conceptTitle: data.verizon_express_setting.concept_title || '',
    caseID: data.verizon_express_setting.case_id || '',
    vertical: data.verizon_express_setting.vertical || '',
    verticalOther: data.verizon_express_setting.vertical_other || '',
    developmentStage: data.verizon_express_setting.development_stage || '',
    errors
  });

  const maxEmails = options.max_emails;

  const initHasChanges = useRef(options.generate || !isEmpty(errors));
  const hasChanges = useRef(initHasChanges.current);

  const subgroupSelected = (value) => (model.subgroups[0] === value);

  const toggleSubgroup = (item) => {
    model.subgroups = [];
    model.subgroups.push(item.value);
    setModel({ ...model, subgroups: model.subgroups });
  };

  const handleUpdateSubgroupOther = (value) => {
    setModel({ ...model, subgroupsOther: value });
  };

  const handleVerticalOtherChange = (event) => {
    setModel({ ...model, verticalOther: event.target.value });
  };

  const segmentOptions = () => {
    if (model.audienceCategory === "wireless") {
      return options.segment_wireless_options;
    } else if (model.audienceCategory === "home_internet") {
      return options.segment_home_internet_options;
    }
    return options.segment_corporate_options;
  };

  const getBrandOptions = (branded, audienceCategory) => {
    if (audienceCategory === 'wireless') {
      return options.brand_wireless_options;
    }

    if (audienceCategory === 'home_internet') {
      return options.brand_home_internet_options;
    }

    if (audienceCategory === 'corporate') {
      return filter(options.brand_corporate_options, (item) => (item.value === 'verizon'));
    }

    return [];
  };

  const brandOptions = (audience = model.audienceCategory) => {
    return getBrandOptions(model.branded, audience);
  };

  const handleSegmentChange = (newSegment) => {
    if (newSegment === 'gen_pop' || newSegment === '') {
      setModel({ ...model, subgroupsList: options.subgroups_list, segment: newSegment });
    } else if (intersection([ "vzw_customers", "vzw_prospects" ], model.subgroups).length) {
      setModel({ ...model, subgroupsList: options.subgroups_list.slice(2), subgroups: [], segment: newSegment });
    } else {
      setModel({ ...model, subgroupsList: options.subgroups_list.slice(2), segment: newSegment });
    }
  };

  const handleDevelopmentStageChange = (value) => {
    setModel({ ...model, developmentStage: value });
  };

  const handleVerticalChange = (value) => {
    setModel({ ...model, vertical: value });
  };


  const handleAudienceCategoryChange = (value) => {
    const opts = brandOptions(value);
    const selectedBrand = (includes(map(opts, (item) => item.value), model.brand) ? model.brand : opts[0].value);
    setModel({ ...model, audienceCategory: value, brand: selectedBrand });
  };

  const handleBrandedChange = (v) => {
    setModel({ ...model, branded: v });
  };

  useEffect(() => {
    const selectedSegment = (
      includes(map(segmentOptions(), (item) => item.value), model.segment) ?
        model.segment :
        segmentOptions()[0].value
    );

    if (selectedSegment === 'gen_pop' || selectedSegment === '') {
      setModel({ ...model, segment: selectedSegment, subgroupsList: options.subgroups_list });
    } else if (intersection([ "vzw_customers", "vzw_prospects" ], model.subgroups).length) {
      setModel({ ...model, segment: selectedSegment, subgroupsList: options.subgroups_list.slice(2), subgroups: [] });
    } else {
      setModel({ ...model, segment: selectedSegment, subgroupsList: options.subgroups_list.slice(2) });
    }
  }, [ model.audienceCategory ]);

  return (
    <WithSubmit formId="setup_form" errors={ errors } hasChangesFunc={ () => (hasChanges) } options={ options }>
      <HiddenFields data={ data } options={ options } model={ model } />
      <div className="content -relative">
        <Header title="Project Setup" />

        <div className="content_body">
          <div className="form">
            {!!options.jid && <PageLoader options={ options } />}
            <FieldHeaderWithText
              id="concept_title"
              title="Case Name"
              placeholder="Please name your project"
              errors={ errors['verizon_express_setting.concept_title'] }
              initValue={ model.conceptTitle }
              onChange={ (v) => {setModel({ ...model, conceptTitle: v });} }
            />

            <div className="form_section">
              <div className="form_grid">
                <div className="form_grid-item -width-1-2">
                  <FieldHeaderWithText
                    id="project_platform_id"
                    title="Buzzback project number"
                    placeholder="Enter project number"
                    errors={ errors['platform_id'] }
                    initValue={ model.platformId }
                    onChange={ (v) => {setModel({ ...model, platformId: v });} }
                  />
                </div>
                <div className="form_grid-item -width-1-2">
                  <FieldHeaderWithText
                    id="case_id"
                    title="Case ID"
                    placeholder="Please name your project"
                    errors={ errors['verizon_express_setting.case_id'] }
                    initValue={ model.caseID }
                    onChange={ (v) => {setModel({ ...model, caseID: v });} }
                  />
                </div>
              </div>
            </div>

            <div className="form_section">
              <div className="form_grid">
                <div className="form_grid-item -width-1-2">
                  <DatePicker
                    id="sub-dt"
                    title="Submission Date"
                    initValue={ model.submissionDate }
                    onChange={ (v) => {setModel({ ...model, submissionDate: v });} }
                    errors={ errors['verizon_express_setting.submission_date'] }
                  />
                </div>
                <div className="form_grid-item -width-1-2">
                  <Select
                    id="select_vertical"
                    title="Vertical"
                    initValue={ model.vertical }
                    options={ options.vertical_options }
                    onChange={ handleVerticalChange }
                    errors={ errors['verizon_express_setting.vertical'] }
                  />
                </div>
              </div>
            </div>

            {
              model.vertical === 'other' &&
              <div className="form_section">
                <div className="form_grid">
                  <div className="form_grid-item -width-1-2" />
                  <div className="form_grid-item -width-1-2">
                    <input
                      className={ cn("form-field -block", errors['verizon_express_setting.vertical_other'] ? 'has-error' : '') }
                      maxLength="250"
                      size="250"
                      type="text"
                      value={ model.verticalOther }
                      onChange={ handleVerticalOtherChange }
                    />
                    <div className="form-error">{errors['verizon_express_setting.vertical_other']}</div>
                  </div>
                </div>
              </div>
            }

            <div className="form_section">
              <div className="form_grid">
                <div className="form_grid-item -width-1-2">
                  <Select
                    id="select_development_stage"
                    title="Stage of development "
                    initValue={ model.developmentStage }
                    options={ options.development_stage_options }
                    onChange={ handleDevelopmentStageChange }
                    errors={ errors['verizon_express_setting.development_stage'] }
                  />
                </div>
                <div className="form_grid-item -width-1-2">
                  <FieldHeaderWithText
                    id="project_project_lead_email"
                    title="Project Lead"
                    placeholder="Enter project lead email"
                    errors={ errors['verizon_express_setting.project_lead_email'] }
                    initValue={ model.projectLeadEmail }
                    onChange={ (v) => {setModel({ ...model, projectLeadEmail: v });} }
                  />
                </div>
              </div>
            </div>

            <div className="form_section">
              <div className="form_grid">
                <div className="form_grid-item -width-1-2">
                  <Select
                    id="audience_category"
                    title="Test Topic"
                    initValue={ model.audienceCategory }
                    options={ options.audience_category_options }
                    onChange={ handleAudienceCategoryChange }
                    errors={ errors['verizon_express_setting.audience_category'] }
                  />
                </div>
                <div className="form_grid-item -width-1-2">
                  <Select
                    key={ `segment-${model.audienceCategory}` }
                    id="segment"
                    title="Audience"
                    initValue={ model.segment }
                    options={ segmentOptions() }
                    onChange={ handleSegmentChange }
                    errors={ errors['verizon_express_setting.segment'] }
                  />
                </div>
              </div>
            </div>

            <div className="form_section">
              <div className="form_grid">
                <div className="form_grid-item -width-1-2">
                  <BrandedTabs
                    initValue={ model.branded }
                    onChange={ handleBrandedChange }
                  />
                </div>

                <div className="form_grid-item -width-1-2">
                  <Select
                    key={ `brand-${model.brand}` }
                    id="project_brand"
                    title="Brand to refer to in the survey"
                    initValue={ model.brand }
                    options={ brandOptions() }
                    onChange={ (v) => {setModel({ ...model, brand: v });} }
                    errors={ errors['verizon_express_setting.brand'] }
                  />
                </div>
              </div>
            </div>

            <div className="form_section">
              <div className={ cn("form_group", errors['verizon_express_setting.subgroups'] ? 'has-error' : '') } >
                <h2>Targeted Subgroup</h2>
                <div className="form_section -follow-up">
                  {
                    map(model.subgroupsList, (item, index) => {
                      const enabled = subgroupSelected(item.value);
                      return (
                        <Fragment key={ `${item.value}${index}` }>
                          <div className="form_metrics-item -compact">
                            <Radio
                              label={ item.title }
                              name={ "subgroup" }
                              value={ item.value }
                              bool={ enabled }
                              onChange={ () => { toggleSubgroup(item); } }
                            />
                          </div>
                          {
                            item.other && enabled &&
                            <div className="form-other-input">
                              <FieldHeaderWithText
                                id="project_subgroups_other"
                                errors={ errors['verizon_express_setting.subgroups_other'] }
                                initValue={ model.subgroupsOther }
                                onChange={ handleUpdateSubgroupOther }
                              />
                            </div>
                          }
                        </Fragment>
                      );
                    })
                  }
                </div>
              </div>
            </div>

            <div className="form_group">
              <h2>Email addresses to send results{ model.emails.length ? ` (${model.emails.length} added)` : ''}</h2>
              <div className="form_section -follow-up">
                <EmailsList
                  limit={ maxEmails }
                  errors={ errors['verizon_express_setting.emails'] }
                  model={ model }
                  setModel={ setModel }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </WithSubmit>
  );
};

export default App;
