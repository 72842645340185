import React, { useState, useContext } from 'react';
import cn from 'classnames';
import Select from "react-select";
import { QuestionContext } from '../contexts';
import { vgSelectStyles } from "../../../../../verizonCatV2024/admin/projects/components/helpers";
import { generateId } from "../../../../../common/dateUtils";

const AdditionalAnswerLine = ({
  question, questionIndex, options,
  screenerQuestions, setScreenerQuestions,
  fieldBoolName, fieldTextName, fieldOptionsName,
  onSwitchAdditionalAnswer, subgroups, needSubgroup, handleAddSubgroups,
  getSubgroups
}) => {
  const [ focusState, setFocusState ] = useState(false);
  const { fivePtScaleMode } = useContext(QuestionContext);
  const [ uniqId ] = useState(() => (generateId()));

  const onChangeNoneAnswer = (event) => {
    question[fieldBoolName] = event.target.checked;
    if (onSwitchAdditionalAnswer) {
      onSwitchAdditionalAnswer();
    }
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const onChangeNoneAnswerText = (event) => {
    question[fieldTextName] = event.target.value;
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <li className="form-question_answer">
      <div className="form-question_grid -grow">
        <div className="form-question_grid-item -first-answer-item">
          <div className={ cn("form-question_answer-move", { '-disabled': fivePtScaleMode }) }>
            <div className="checkbox">
              <input
                type="checkbox"
                id={ `checkbox-id-${fieldBoolName}-${questionIndex}-${uniqId}` }
                checked={ !!question[fieldBoolName] }
                onChange={ onChangeNoneAnswer }
                disabled={ fivePtScaleMode }
              />
              <label
                key={ `checkbox-label-id-${fieldBoolName}-${questionIndex}-${uniqId}` }
                className={
                  cn(
                    `checkbox_label -for-additional-answer -for-${fieldBoolName}`,
                    { '-disabled': fivePtScaleMode }
                  )
                }
                htmlFor={ `checkbox-id-${fieldBoolName}-${questionIndex}-${uniqId}` }
              />
            </div>
          </div>
        </div>

        <div
          id={ uniqId }
          className={
            cn(
              "form-question_grid-item -grow -with-custom-placeholder",
              { '-disabled': !question[fieldBoolName] }
            )
          }
        >
          <input
            className="form-field -block"
            type="text"
            value={ question[fieldTextName] || '' }
            onChange={ onChangeNoneAnswerText }
            disabled={ !question[fieldBoolName] }
            placeholder={ options[fieldOptionsName] }
            onFocus={ () => { setFocusState(true); } }
            onBlur={ () => { setFocusState(false); } }
            onKeyDown={ handleKeyDown }
          />
          {
            (!!question[fieldTextName] || focusState) &&
            <div className="custom-placeholder-label">
              Substitute for "{ options[fieldOptionsName] }"
            </div>
          }
        </div>

        {
          !needSubgroup &&
          <div className="form-question_grid-item -buttons">
            <button className="button -circle form-question_grid-button -transparent" />
            <button className="button -circle form-question_grid-button -transparent" />
            <button className="button -circle form-question_grid-button -transparent" />
          </div>
        }

        {
          needSubgroup &&
          <>
            <div
              className={
                cn(
                  "form-question_grid-item -flex-1-3",
                  { 'has-error': question?.errors?.answers }
                )
              }
            >
              <Select
                value={ getSubgroups(fieldBoolName === 'other_answer' ? -2 : -1) }
                className="react-select-container"
                classNamePrefix="react-select"
                options={ subgroups }
                placeholder="Select subgroup"
                onChange={ (event, action) => { handleAddSubgroups(fieldBoolName === 'other_answer' ? -2 : -1, action); } }
                isSearchable
                isMulti
                autosize={ false }
                maxMenuHeight={ 150 }
                styles={ vgSelectStyles }
                isDisabled={ !question[fieldBoolName] }
              />
            </div>
            <div className="form-question_grid-item -buttons">
              <button className="button -circle form-question_grid-button -transparent" />
              <button className="button -circle form-question_grid-button -transparent" />
              <button className="button -circle form-question_grid-button -transparent" />
            </div>
          </>
        }
      </div>
    </li>
  );
};

export default AdditionalAnswerLine;
