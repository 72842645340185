import React, { useContext, useEffect, useState } from 'react';
import { keys, pickBy, isEmpty, compact, map, some, includes } from 'lodash';
import { BaseContext } from '../contexts';
import Http from '../../../../../common/http';
import PoweredByBuzzback
  from '../../../../../express/researcher/verizon_customer_case_v2024/common/components/poweredByBuzzback';
import ArrowsNote from '../../../../../express/researcher/verizon_cat_v2024/components/arrowsNote';
import TabsWithFilter from './tabsWithFilter';
import CaseComparisonList from './caseComparisonList';
import Opportunity from './opportunity';
import Reasons from './reasons';
import Diagnostics from './diagnostics';
import Unaided from './unaided';

const Comparison = ({ loading, setLoading, showList, setShowList }) => {
  const [ data, setData ] = useState({});
  const [ tab, setTab ] = useState('opportunity');
  const { selectedForComparison, setSelectedForComparison } = useContext(BaseContext);
  const [ selectedOptions, setSelectedOptions ] = useState([]);
  const [ selectedBsg, setSelectedBsg ] = useState('none');

  const pids = keys(selectedForComparison);
  const pidsTotal = pids.length;
  const pidsSelected = keys(pickBy(selectedForComparison, (hash) => (hash.show)));
  const pidsSelectedTotal = compact(pidsSelected).length;

  const getData = (filters = [], selectedBsg = 'none') => {
    setLoading(true);
    Http.get(
      `/verizon_cat_v2024/api/comparisons`,
      { pids: pids.join(','), sub_groups: filters.join(','), selected_bsg: selectedBsg }
    ).then(
      (http) => {
        if (http.response.status === 200) {
          setData(http.data);
          setSelectedOptions(http.data.selected_sub_groups);
        }
        setLoading(false);
      },
      (reject) => {console.log('something went wrong', reject);});
  };

  const onFilterApply = (filters) => {
    const filterRow = map(filters, (item) => (item.value));
    getData(filterRow, selectedBsg);
  };

  const onBsgApply = (newSelectedBsg) => {
    setSelectedOptions([]);
    setSelectedBsg(newSelectedBsg);
    getData([], newSelectedBsg);
  };

  useEffect(() => {
    if (pidsTotal) {
      getData();
    }
  }, []);

  const hasBsg = !!data.scorecard_data && some(pidsSelected, (pid) => {
    return data.scorecard_data[pid].subgroup !== 'none';
  });

  return (
    <div className="content_body vcc-scorecard">
      {loading && <div className="page-loader -inner-loader -transparent -fixed" />}

      {!pidsTotal && <h3>No cases selected</h3>}

      {!!pidsTotal && !isEmpty(data) &&
        <>
          <h1 className="comparisons_title">Case Comparison</h1>

          <TabsWithFilter
            tab={ tab }
            setTab={ setTab }
            pids={ pidsSelected }
            subGroups={ data.sub_groups }
            selectedOptions={ selectedOptions }
            setSelectedOptions={ setSelectedOptions }
            onFilterApply={ onFilterApply }
            onBsgApply={ onBsgApply }
            hasBsg={ hasBsg }
            selectedBsg={ selectedBsg }
          />

          {tab === 'opportunity' && <Opportunity pids={ pidsSelected } data={ data } />}
          {tab === 'reasons' && <Reasons pids={ pidsSelected } data={ data } />}
          {tab === 'diagnostics' && <Diagnostics pids={ pidsSelected } data={ data } />}
          {tab === 'unaided' && <Unaided pids={ pidsSelected } data={ data } />}
          {
            includes([ 'opportunity', 'diagnostics' ], tab) &&
            <div className="comparisons_plate-legend -margin-xs">
              <ArrowsNote arrows={ data.arrows_mode } text={ data.arrows_label_text } blackText />
            </div>
          }
          <PoweredByBuzzback />

          {showList &&
            <CaseComparisonList
              selectedForComparison={ selectedForComparison }
              setSelectedForComparison={ setSelectedForComparison }
              data={ data }
              selected={ pidsSelectedTotal }
              total={ pidsTotal }
              setShowList={ setShowList }
            />}
        </>
      }
    </div>
  );
};

export default Comparison;
