import React, { useState } from 'react';
import { map } from "lodash";
import SQAMultiOption from "../../common/custom_questions/components/SQAMultiOption";
import SQAHeader from "./SQAHeader";
import SQAList from "./SQAList";

const ScreeningQuestionAnswers = ({
  options, question, screenerQuestions, setScreenerQuestions, questionIndex, needLocation
}) => {
  const [ subgroups, setSubgroups ] = useState(map((question.subgroups), (el) => (el !== 'null' && { value: el, label: el })) || []);

  return (
    <div className="form_section -sm-space">
      {
        question.kind !== 'Open' &&
        <>
          <SQAHeader
            title={ "Answers" }
            options={ options }
            question={ question }
            screenerQuestions={ screenerQuestions }
            setScreenerQuestions={ setScreenerQuestions }
            needLocation={ needLocation }
            subgroups={ subgroups }
            setSubgroups={ setSubgroups }
          />

          <SQAList
            options={ options }
            question={ question }
            screenerQuestions={ screenerQuestions }
            setScreenerQuestions={ setScreenerQuestions }
            questionIndex={ questionIndex }
            subgroups={ subgroups }
            setSubgroups={ setSubgroups }
          />
        </>
      }

      <SQAMultiOption
        options={ options }
        question={ question }
        screenerQuestions={ screenerQuestions }
        setScreenerQuestions={ setScreenerQuestions }
      />
    </div>
  );
};

export default ScreeningQuestionAnswers;
