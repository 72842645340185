import React, { useContext } from 'react';
import { map, findIndex } from "lodash";
import classnames from 'classnames';
import CreatableSelect from "react-select/creatable";
import SvgIcon from '../../../../common/components/svg-icon';
import { vgSelectStyles } from "../../../../verizonCatV2024/admin/projects/components/helpers";
import ToggleFivePtScale from "../../common/custom_questions/components/ToggleFivePtScale";
import QuestionLocation from "../../common/custom_questions/components/QuestionLocation";
import { QuestionContext } from './contexts';
import ToggleCustomSubgroups from "./ToggleCustomSubgroups";

const SQAHeader = ({ options, question, subgroups, setSubgroups, screenerQuestions, setScreenerQuestions, title, noToggles, needLocation }) => {
  const handleOnChange = (newValues, data) => {
    if (data.action === "create-option") {
      const newSubgroups = map(newValues, (el) => ({ value: el.label, label: el.label }));
      setSubgroups(newSubgroups);
      question.subgroups = map(newSubgroups, (el) => (el.label));
    } else if (data.action === "remove-value") {
      const idx = findIndex(subgroups, (item) => (item.label === data.removedValue.label));
      if (idx !== -1) {
        subgroups.splice(idx, 1);
        setSubgroups([ ...subgroups ]);
        question.subgroups = map(subgroups, (el) => (el.label));
      }
    } else {
      // clear
      setSubgroups([]);
      question.subgroups = [];
    }
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const onChangeAnswersOrders = (event) => {
    question.answers_order = event.target.value;
    setScreenerQuestions([ ...screenerQuestions ]);
  };
  const {
    needLogicEdit, manageFivePtToggle, fivePtScaleMode, setFivePtScaleMode,
    needFivePtScaleToggle, main, customSubgroupsMode, setCustomSubgroupsMode,
    showStimuli
  } = useContext(QuestionContext);

  const hasToggleCustomSubgroups = !main;
  const hasToggleFivePtScale = !noToggles && needFivePtScaleToggle && manageFivePtToggle && main;
  const hasAnyControls = hasToggleFivePtScale || hasToggleCustomSubgroups || needLocation || showStimuli;

  return (
    <>
      {
        hasAnyControls &&
        <div className="form_section-title -grid-between -custom-controls">
          <div className="form_section-title-block">
            {(hasToggleFivePtScale || hasToggleCustomSubgroups) &&
              <div className="form_section-title-item">
                {
                  hasToggleFivePtScale &&
                  <ToggleFivePtScale
                    key="five_point_toggle"
                    question={ question }
                    questions={ screenerQuestions }
                    setQuestions={ setScreenerQuestions }
                    manageFivePtToggle={ manageFivePtToggle }
                    fivePtScaleMode={ fivePtScaleMode }
                    setFivePtScaleMode={ setFivePtScaleMode }
                    bold
                  />
                }
                {
                  hasToggleCustomSubgroups &&
                  <ToggleCustomSubgroups
                    key="custom_subgroups_toggle"
                    question={ question }
                    questions={ screenerQuestions }
                    setQuestions={ setScreenerQuestions }
                    customSubgroupsMode={ customSubgroupsMode }
                    setCustomSubgroupsMode={ setCustomSubgroupsMode }
                  />
                }
              </div>
            }
          </div>
          {
            needLocation &&
            <div className="form_section-title-item -grid">
              <QuestionLocation
                question={ question }
                screenerQuestions={ screenerQuestions }
                setScreenerQuestions={ setScreenerQuestions }
              />
            </div>
          }
        </div>
      }

      {
        customSubgroupsMode &&
        <div className="form-question_grid-item -grow -with-bottom-margin">
          <CreatableSelect
            className="react-select-container"
            classNamePrefix="react-select"
            options={ subgroups }
            value={ subgroups }
            onChange={ handleOnChange }
            isSearchable
            isMulti
            autosize={ false }
            maxMenuHeight={ 150 }
            styles={ vgSelectStyles }
          />
        </div>
      }
      <h3 className="form_section-title">
        <div className="form-question_grid -title">
          <div className="form-question_grid-item -grow">
            { title }
            {
              !fivePtScaleMode && title !== "Columns" &&
              <span
                className={
                  classnames(
                    "form_inline-select",
                    `-${question.answers_order}`
                  )
                }
              >
                <select
                  className="js-random-order"
                  value={ question.answers_order }
                  onChange={ onChangeAnswersOrders }
                >
                  {
                    map(options.order, (value, name) => (
                      <option key={ name } value={ name }>
                        { value }
                      </option>
                    ))
                  }
                </select>
              </span>
            }
          </div>

          {
            needLogicEdit && question.kind !== "Grid" &&
            <div className="form-question_grid-item -select">
              <div className="form_section-title -grid">
                <div className="form_section-title-item">
                  Logic
                </div>
                <div className="form_section-title-item">
                  <div className="info-tooltip">
                    <div className="info-tooltip_control">
                      <SvgIcon name="i-info" />
                    </div>
                    <div className="info-tooltip_drop -top -big">
                      <div className="info-tooltip_drop-inner">
                        <span className="logic">Must select</span>
                        <span>&mdash; Respondent must select the answer to qualify</span>
                        <br />
                        <span className="logic">Must not select</span>
                        <span>&mdash; Respondent must not select the answer to qualify</span>
                        <br />
                        <span className="logic">Neutral</span>
                        <span>&mdash; Respondent can select the answer or not</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

          <div className="form-question_grid-item -buttons" />
        </div>
      </h3>
    </>

  );
};

export default SQAHeader;
