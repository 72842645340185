import React, { useContext } from 'react';
import { map, find } from 'lodash';
import SummaryPanel from '../../../verizon_customer_case_v2024/components/summary-panel';
import { OpenEndContext } from '../../../common/openEndContext';
import renderRawHtml from '../../../../../common/render_raw_html';
import AnswerRow from "./answerRow";

const QuestionOpen = ({ projectId, conceptId, openEndData }) => {
  const { handleUpdateSummary, aiSummaryAvailable } = useContext(OpenEndContext);

  const findAndUpdateHiddenValue = (answersList, id, hide) => {
    const answerObj = find(answersList || [], (item) => item.id === id);
    if (answerObj) {
      answerObj.hidden = hide;
    }
  };

  const handleToggleVisibility = (answerObj, hide) => {
    const answerObjId = answerObj.id;
    findAndUpdateHiddenValue(openEndData.answers, answerObjId, hide);
  };

  const metric = {
    project_id: projectId,
    concept_id: conceptId,
    source_id: openEndData.metric.id,
    source_type: openEndData.metric.type,
    tag: openEndData.metric.tag,
    answers: openEndData.answers
  };

  return (
    <>
      <div className="comparisons_plates">
        <div className="comparisons_plates-grid">
          <div className="comparisons_plates-grid-item">
            <div className="comparisons_plate -bordered -full-height">
              <h3 className="comparisons_plate-title -center -no-transform" { ...renderRawHtml(openEndData.text) } />

              <ul className="sentiments-list">
                {map(openEndData.answers, (record) => (
                  <AnswerRow
                    key={ record.id }
                    projectId={ projectId }
                    conceptId={ conceptId }
                    record={ record }
                    onToggleVisibility={ handleToggleVisibility }
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="summary-panel-wrapper">
        <SummaryPanel
          noAddToReportCheckbox
          available={ aiSummaryAvailable }
          metric={ metric }
          summaryClassName="pre"
          entityTitle={ 'Custom Question' }
          onGotSummary={ handleUpdateSummary }
          answers={ openEndData.answers }
          innerFilter={ '' }
        />
      </div>
    </>
  );
};

export default QuestionOpen;
