import React from 'react';
import Arrow from '../../../verizon_customer_case_v2024/components/arrow';

const TwoColumns = ({ max, value1, arrow1, text1, value2, arrow2, text2 }) => {
  const height1 = calcHeight(value1, max || value2);
  const height2 = calcHeight(value2, max || value1);

  return (
    <div className="two-columns">
      <div className="bar bar-1" style={ { height: `${height1}%` } }>
        <div className="label">
          {text1}
          <Arrow color={ arrow1 } />
        </div>
      </div>

      <div className="bar bar-2" style={ { height: `${height2}%` } }>
        <div className="label">
          {text2}
          <Arrow color={ arrow2 } />
        </div>
      </div>
    </div>
  );
};

const calcHeight = (base, compareTo) => {
  if (parseFloat(base) === 0) {
    return 0.0;
  }

  if (parseFloat(base) > parseFloat(compareTo)) {
    return 100.0;
  }

  return 100.0 * parseFloat(base) / parseFloat(compareTo);
};

export default TwoColumns;
