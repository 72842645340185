import React, { Fragment, useRef, useState } from 'react';
import { find, isEmpty, map } from "lodash";
import cn from "classnames";
import Header from "../../common/header";
import WithSubmit from "../../common/WithSubmit";
import PageLoader from "../../common/PageLoader";
import FieldHeaderWithText from "../../common/FieldHeaderWithText";
import Select from "../../verizon_customer_case_v2024/common/select";
import Wysiwyg from "../../../../common/components/wysiwyg";
import Stimuli from '../../../respondent/verizon_customer_case_v2024/components/stimuli';
import { baseContext } from '../../../respondent/common/contexts';
import Radio from "../../common/radio";
import HiddenFields from "./hiddenFields";
import ImageUpload from './imageUpload';

const App = ({ data, options, errors }) => {
  const [ model, setModel ] = useState(() => {
    return {
      projectId: data.id,
      ideaName: data.verizon_express_setting.idea_name || '',
      reasonsToBelieve: data.verizon_express_setting.reasons_to_believe || '',
      processLeadIn: data.verizon_express_setting.process_lead_in || '',
      processLeadInOther: data.verizon_express_setting.process_lead_in_other || '',
      includeDeviation: data.verizon_express_setting.include_deviation || false,
      deviationScenario: data.verizon_express_setting.deviation_scenario || '',
      processSteps: data.verizon_express_setting.process_steps || '',
      additionalImageData: data.verizon_express_setting.additional_image_data || '',
      additionalImageSrc: data.verizon_express_setting.additional_image_src || '',
      additionalImageNotes: data.verizon_express_setting.additional_image_notes || '',
      steps: data.verizon_process_steps || [],
      branded: data.verizon_express_setting.branded,
      problemSolution: data.verizon_express_setting.problem_solution || '',
      howItWorks: data.verizon_express_setting.how_it_works || '',
      details: data.verizon_express_setting.details || '',
      priced: data.verizon_express_setting.priced ? find(options.priced_options, (item) => item.value === data.verizon_express_setting.priced) : options.priced_options[0],
      pricedText: data.verizon_express_setting.priced_text || '',
      payFrequency: data.verizon_express_setting.pay_frequency || options.pay_frequency_options[0].value,
      errors
    };
  });

  const initHasChanges = useRef(options.generate || !isEmpty(errors));
  const hasChanges = useRef(initHasChanges.current);

  const handleAdditionalImageUpload = (imageObject) => {
    setModel(
      {
        ...model,
        additionalImageData: imageObject.imageData,
        additionalImageSrc: imageObject.imageSrc
      }
    );
  };
  const handleAdditionalImageDelete = () => {
    setModel(
      {
        ...model,
        additionalImageData: null, additionalImageSrc: null
      }
    );
  };

  const subgroupSelected = (value) => (model.priced?.value === value);

  const togglePriced = (item) => {
    setModel({ ...model, priced: item });
  };

  const handlePayFrequencyChange = (value) => {
    setModel({ ...model, payFrequency: value });
  };

  const baseContextValue = { translations: options.translations };

  return (
    <baseContext.Provider value={ baseContextValue }>
      <WithSubmit formId="form" errors={ errors } hasChangesFunc={ () => (hasChanges) } options={ options }>
        <HiddenFields data={ data } options={ options } model={ model } />
        <div className="content -relative -grey-background">
          <Header title="Stimuli" />
          <div className="content_body">
            <div className="form">
              {!!options.jid && <PageLoader options={ options } />}

              <div className="form_section -js-idea-name">
                <div className="form_section-title -grid">
                  <div className="form_section-title-item">
                    <h3>Idea Name</h3>
                  </div>
                </div>
                <div className={ cn("form_section-line", { 'has-error': !!errors['verizon_express_setting.idea_name'] }) }>
                  <Wysiwyg
                    customPicker
                    toolbarClassName="wysiwyg-menu"
                    editorClassName="wysiwyg-textarea"
                    placeholder="Add idea Name"
                    enableIndent
                    value={ model.ideaName }
                    onChange={ (newHtml) => {setModel({ ...model, ideaName: newHtml });} }
                  />
                </div>
              </div>

              <div className="form_section -js-problem-solution">
                <div className="form_section-title -grid">
                  <div className="form_section-title-item">
                    <h3>Problem/Solution</h3>
                  </div>
                </div>
                <div className={ cn("form_section-line", { 'has-error': !!errors['verizon_express_setting.problem_solution'] }) }>
                  <Wysiwyg
                    customPicker
                    toolbarClassName="wysiwyg-menu"
                    editorClassName="wysiwyg-textarea"
                    placeholder="Add Problem and Solution"
                    enableIndent
                    value={ model.problemSolution }
                    onChange={ (newHtml) => {setModel({ ...model, problemSolution: newHtml });} }
                  />
                </div>
              </div>

              <div className="form_section -js-how-it-works">
                <div className="form_section-title -grid">
                  <div className="form_section-title-item">
                    <h3>How it Works</h3>
                  </div>
                </div>
                <div className={ cn("form_section-line", { 'has-error': !!errors['verizon_express_setting.how_it_works'] }) }>
                  <Wysiwyg
                    customPicker
                    toolbarClassName="wysiwyg-menu"
                    editorClassName="wysiwyg-textarea"
                    placeholder="Add How it works description"
                    enableIndent
                    value={ model.howItWorks }
                    onChange={ (newHtml) => {setModel({ ...model, howItWorks: newHtml });} }
                  />
                </div>
              </div>

              <div className="form_section -js-detail">
                <div className="form_section-title -grid">
                  <div className="form_section-title-item">
                    <h3>Detail</h3>
                  </div>
                </div>
                <div className={ cn("form_section-line", { 'has-error': !!errors['verizon_express_setting.details'] }) }>
                  <Wysiwyg
                    customPicker
                    toolbarClassName="wysiwyg-menu"
                    editorClassName="wysiwyg-textarea"
                    placeholder="Add Details"
                    enableIndent
                    value={ model.details }
                    onChange={ (newHtml) => {setModel({ ...model, details: newHtml });} }
                  />
                </div>
              </div>

              <div className="form_section -js-reasons">
                <div className="form_section-title -grid">
                  <div className="form_section-title-item">
                    <h3>Optional Image</h3>
                  </div>
                </div>
                <div className={ cn("form_section-line", { 'has-error': !!errors['verizon_express_setting.additional_image_data'] }) }>
                  <ImageUpload
                    title="Optional Image is blank"
                    imageObject={
                      {
                        imageData: model.additionalImageData,
                        imageSrc: model.additionalImageSrc
                      }
                    }
                    onUpload={ handleAdditionalImageUpload }
                    onDelete={ handleAdditionalImageDelete }
                  />
                </div>
                <FieldHeaderWithText
                  id="project-OptionalImageNotes"
                  title="Optional Image Alt Text"
                  errors={ errors['verizon_express_setting.additional_image_notes'] }
                  initValue={ model.additionalImageNotes }
                  onChange={ (v) => { setModel({ ...model, additionalImageNotes: v }); } }
                />
              </div>

              <div className="form_section">
                <div className="form_group">
                  <h2>Is this priced?</h2>
                  <div className="form_section -follow-up">
                    {
                      map(options.priced_options, (item, index) => {
                        const enabled = subgroupSelected(item.value);
                        return (
                          <Fragment key={ `${item.value}${index}` }>
                            <div className="form_metrics-item -compact">
                              <Radio
                                label={ item.title }
                                name={ "subgroup" }
                                value={ item.value }
                                bool={ enabled }
                                onChange={ () => { togglePriced(item); } }
                              />
                            </div>
                          </Fragment>
                        );
                      })
                    }
                  </div>
                </div>
              </div>

              {
                model.priced?.value === 'priced' &&
                <div className="form_section -js-priced-text-name">
                  <div className="form_section-title -grid">
                    <div className="form_section-title-item">
                      <h3>Pricing</h3>
                    </div>
                  </div>
                  <div className={ cn("form_section-line", { 'has-error': !!errors['verizon_express_setting.priced_text'] }) }>
                    <Wysiwyg
                      customPicker
                      toolbarClassName="wysiwyg-menu"
                      editorClassName="wysiwyg-textarea"
                      placeholder="Add Pricing"
                      enableIndent
                      value={ model.pricedText }
                      onChange={ (newHtml) => {setModel({ ...model, pricedText: newHtml });} }
                    />
                  </div>
                </div>
              }

              {
                model.priced?.value === 'unpriced' &&
                <div className="form_section">
                  <div className="form_grid">
                    <div className="form_grid-item -width-1-2">
                      <Select
                        id="select_pay_frequency"
                        title="Pay Frequency"
                        initValue={ model.payFrequency }
                        options={ options.pay_frequency_options }
                        onChange={ handlePayFrequencyChange }
                        errors={ errors['verizon_express_setting.pay_frequency'] }
                      />
                    </div>
                  </div>
                </div>
              }

              <div className="stimuli-preview">
                <h3>Stimuli Preview</h3>
                <Stimuli
                  className="-with-650"
                  ideaName={ model.ideaName }
                  customerBenefit={ model.problemSolution }
                  reasonsToBelieve={ model.howItWorks }
                  details={ model.details }
                  priced={ model.priced.value === 'priced' ? model.pricedText : null }
                  processStepsKind={ model.additionalImageSrc && 'text' }
                  additionalImageUrl={ model.additionalImageSrc }
                  additionalImageNotes={ model.additionalImageNotes }
                  branded={ model.branded }
                  steps={ model.steps }
                />
              </div>
            </div>
          </div>
        </div>
      </WithSubmit>
    </baseContext.Provider>
  );
};

export default App;
