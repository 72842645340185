import React from 'react';
import TwoColumns from '../opportunityAction/twoColumns';
import TwoColumnsLegend from '../opportunityAction/twoColumnsLegend';

const QuestionSingle = ({ dataCQ }) => {
  return (
    <>
      <div className="comparisons_grid">
        <div className="comparisons_grid-item" />
        <div className="comparisons_grid-item -thin -two-columns-wrapper -no-title">
          <TwoColumns
            value1={ dataCQ.top2percent }
            arrow1={ dataCQ.top2arrow }
            text1={ dataCQ.top2percent_text }
            value2={ dataCQ.bottom2percent }
            arrow2={ dataCQ.bottom2arrow }
            text2={ dataCQ.bottom2percent_text }
          />
        </div>
        <div className="comparisons_grid-item" />
      </div>

      <hr />

      <TwoColumnsLegend
        text1={ dataCQ.top2text }
        text2={ dataCQ.bottom2text }
      />
    </>
  );
};

export default QuestionSingle;
