import React, { useContext } from "react";
import { map } from "lodash";
import SvgIcon from "../../../../common/components/svg-icon";
import { IndexData } from "../../common/index-context";

const ComparisonsMenu = () => {
  const { data } = useContext(IndexData);

  const menuList = [
    {
      id: 1,
      url: "#opportunity-action",
      title: "Opportunity & Action",
      show: true
    },
    {
      id: 2,
      url: "#concept-diagnostics",
      title: "Concept Diagnostics",
      show: true
    },
    {
      id: 3,
      url: "#pricing-feedback",
      title: "Pricing Feedback",
      show: data.pricing_feedback
    },
    {
      id: 4,
      url: "#custom-question",
      title: "Custom Question",
      show: !!data.custom_question || !!data.custom_question_demo
    },
    {
      id: 6,
      url: "#customer-feedback",
      title: "Customer Feedback – Likelihood to Take Action",
      show: true
    },
    {
      id: 7,
      url: "#idea-appeal",
      title: "Customer Feedback – Idea Appeal",
      show: true
    },
    {
      id: 8,
      url: "#study",
      title: "Study Details",
      show: true
    }
  ];

  return (
    <nav className="comparisons_menu">
      <ul className="comparisons_menu-list">
        {map(menuList, (item, index) => {
          if (item.show) {
            return (
              <li key={ index } className="comparisons_menu-item">
                <a className={ `comparisons_menu-link ${item.icon ? '-icon' : '-text'}` } href={ item.url }>
                  {!!item.icon &&
                    <SvgIcon name="to-top" />
                  }
                  {!!item.title && item.title}
                </a>
              </li>
            );
          }
          return null;
        })}
      </ul>
    </nav>
  );
};

export default ComparisonsMenu;
