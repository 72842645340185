import React, { useContext, useState } from 'react';
import { max, map } from "lodash";
import Checkbox from '../../../verizon_customer_case_v2024/components/checkbox';
import { baseContext } from '../../../../respondent/common/contexts';
import { IndexData } from '../../../common/index-context';
import SvgIcon from '../../../../../common/components/svg-icon';
import ArrowsNote from '../arrowsNote';
import BarWithMarks from './barWithMarks';
import TwoColumns from './twoColumns';
import ShortAISummaries from './shortAISummaries';
import TwoColumnsLegend from './twoColumnsLegend';
import NoteRow from './noteRow';
import MetricRow from './metricRow';
import AdditionalSubgroupsModal from './additionalSubgroupsModal';
import AdditionalSubgroupsList from './additionalSubgroupsList';

const OpportunityAction = () => {
  const { model, setModel, additionalSubgroups } = useContext(baseContext);
  const { data } = useContext(IndexData);
  const dataOA = data.opportunity_action;
  const [ showModal, setShowModal ] = useState(false);

  const shorts = map(dataOA.vcc_action_interest_open_end.ai_summary, (el) => (el.short_summary));

  const top2ai  = data.opportunity_action.vcc_action_interest['45'].percent_value;
  const bottom2ai  = data.opportunity_action.vcc_action_interest['12'].percent_value;

  const top2lm  = data.opportunity_action.vcc_learn_more['45'].percent_value;
  const bottom2lm  = data.opportunity_action.vcc_learn_more['12'].percent_value;

  const maxBase = 100;

  return (
    <>
      <div className="comparisons_plates">
        <div className="comparisons_section">
          <h2 className="comparisons_section-title">Opportunity &amp; Action</h2>
          <Checkbox
            title="Add to Report"
            value={ model.opportunity_action }
            paramName="opportunity_action"
            onChange={ (v) => { setModel({ ...model, opportunity_action: v }); } }
          />
        </div>
        <div className="comparisons_plates-grid -g2x2">
          <div className="comparisons_plates-grid-item -g-row-1 -g-col-1">
            <div className="comparisons_plate -full-height">
              <h3 className="comparisons_plate-title -center">Overall Take Rate</h3>
              {
                data?.messages?.total &&
                <div className="comparisons_plate-note -xxs -center">
                  { data?.messages?.total }
                </div>
              }
              <BarWithMarks
                value={ dataOA.overall_take_rate.value }
                text={ dataOA.overall_take_rate.percent_text }
                color={ dataOA.overall_take_rate.color }
                arrow={ dataOA.overall_take_rate.arrow }
                average={ dataOA.range.avg }
                averageText={ dataOA.range.avg_text }
                rangeMin={ dataOA.range.min }
                rangeMax={ dataOA.range.max }
              />
              <h3 className="comparisons_plate-title -center -with-icon">
                Take Rate Among Subgroups
                <SvgIcon name="change" onClick={ () => {setShowModal(true);} } />
              </h3>
              {!additionalSubgroups.length && <p className="comparisons_plate-note -center">Select subgroups</p>}
              {!!additionalSubgroups.length &&
                <AdditionalSubgroupsList
                  records={ data.additional_subgroups_data }
                />}
            </div>
          </div>

          <div className="comparisons_plates-grid-item -g-row-2 -g-col-1">
            <div className="comparisons_plate -full-height">
              <h3 className="comparisons_plate-title -center">Reasons To Take Action</h3>
              <ShortAISummaries
                text={ `Unaided, top themes shown among the ${dataOA.vcc_action_interest_open_end.percent}% 
                        (n=${dataOA.vcc_action_interest_open_end.n}) of respondents who are extremely/very likely to take action.` }
                shorts={ shorts }
              />
            </div>
          </div>

          <div className="comparisons_plates-grid-item -g-row-1 -g-col-2">
            <div className="comparisons_plate -full-height">
              <div className="comparisons_grid">
                <div className="comparisons_grid-item -narrow -two-columns-wrapper">
                  <h3 className="comparisons_plate-title -center">Likelihood To Seek More Info</h3>
                  <TwoColumns
                    max={ maxBase }
                    value1={ top2lm }
                    arrow1={ data.opportunity_action.vcc_learn_more['45'].arrow }
                    text1={ data.opportunity_action.vcc_learn_more['45'].percent_text }
                    value2={ bottom2lm }
                    arrow2={ data.opportunity_action.vcc_learn_more['12'].arrow }
                    text2={ data.opportunity_action.vcc_learn_more['12'].percent_text }
                  />
                </div>
                <div className="comparisons_grid-item -narrow -two-columns-wrapper">
                  <h3 className="comparisons_plate-title -center">Likelihood To Take Action</h3>
                  <TwoColumns
                    max={ maxBase }
                    value1={ top2ai }
                    arrow1={ data.opportunity_action.vcc_action_interest['45'].arrow }
                    text1={ data.opportunity_action.vcc_action_interest['45'].percent_text }
                    value2={ bottom2ai }
                    arrow2={ data.opportunity_action.vcc_action_interest['12'].arrow }
                    text2={ data.opportunity_action.vcc_action_interest['12'].percent_text }
                  />
                </div>
              </div>
              <hr />
              <TwoColumnsLegend text1="Extremely/Very likely" text2="Not at all/Not very likely" />
            </div>
          </div>

          <div className="comparisons_plates-grid-item -g-row-2 -g-col-2">
            <div className="comparisons_plate -full-height">
              <h3 className="comparisons_plate-title -center">Customer Need</h3>

              <NoteRow boldText="Category:" text={ data.category } />

              <MetricRow
                mainText="Category Need"
                smallText="“Absolutely” or “Very much” needed"
                percent={ dataOA.vcc_need['45'].percent_text }
                arrow={ dataOA.vcc_need['45'].arrow }
              />

              <MetricRow
                mainText="Satisfaction with Category Solutions"
                smallText="“Extremely” or “Very” satisfied"
                percent={ dataOA.vcc_cat_satisfaction['45'].percent_text }
                arrow={ dataOA.vcc_cat_satisfaction['45'].arrow }
              />
            </div>
          </div>
        </div>
        <div className="comparisons_plate-legend -margin-xs">
          <ArrowsNote arrows={ data.arrows_mode } text={ data.arrows_label_text } blackText />
        </div>
      </div>
      {showModal &&
        <AdditionalSubgroupsModal
          setShowModal={ setShowModal }
        />}
    </>
  );
};

export default OpportunityAction;
