import React, { useState } from 'react';
import { startsWith, trimStart } from "lodash";

const Number = ({ question, updateValue, prefix, postfix }) => {
  const [ value, setValue ] = useState(null);

  const handleUpdate = (event) => {
    let currentValue = event.target.value;

    currentValue = currentValue.replace(/[^0-9\\.]/gi, '');

    const parts = currentValue.split('.');

    // one dot
    // integer part up to 10 digits
    // decimal part if present up to 2 digits
    if (parts.length <= 2 && parts[0].length <= 10 && (!parts[1] || !!parts[1] && parts[1].length <= 2)) {
      // if integer part starts with zero - remove starting zeros
      if (parts[0] !== '0' && startsWith(parts[0], '0')) {
        currentValue = trimStart(currentValue, '0');
        // try to keep one zero before dot
        if (currentValue.split('.')[0] === '') {
          currentValue = `0${currentValue}`;
        }
      }
      setValue(currentValue);
      updateValue(currentValue);
    }
  };

  return (
    <div className="survey-options js-select">
      {prefix}
      <input
        className="zip-input form-field -with-lr-margin"
        type="text"
        onChange={ handleUpdate }
        value={ value || '' }
        aria-label={ question.text }
      />
      {postfix}
    </div>
  );
};

export { Number };
