import React from 'react';
import { map } from 'lodash';
import Arrow from '../../../verizon_customer_case_v2024/components/arrow';

const AdditionalSubgroupsList = ({ records }) => {
  return (
    <>
      {map(records, (record) => {
        return (
          <div className="comparisons_plate-row" key={ record.subgroup }>
            <div className="texts">
              <div className="title">{record.title}</div>
              <div className="note -sm">n={record.n}</div>
            </div>
            <div className="value -black">
              { record.overall_take_rate.percent_text }
              <Arrow color={ record.overall_take_rate.arrow } />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default AdditionalSubgroupsList;
