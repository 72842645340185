import React, { useContext, useState } from 'react';
import { compact, find, filter } from 'lodash';
import Select from 'react-select';
import Modal from '../../../../../common/components/modal';
import { IndexData } from '../../../common/index-context';
import { baseContext } from '../../../../respondent/common/contexts';

const AdditionalSubgroupsModal = ({ setShowModal }) => {
  const { loadData, selectedBsg, selectedOptions, loading } = useContext(IndexData);
  const {
    additionalSubgroups, setAdditionalSubgroups, filtersList
  } = useContext(baseContext);

  const filters = { selected_bsg: selectedBsg, selected_sub_groups: selectedOptions };

  const [ localData, setLocalData ] = useState([ ...additionalSubgroups ]);

  const handleAdd = () => {
    const newValue = compact(localData);
    setAdditionalSubgroups(newValue);
    loadData(
      filters, { additional_subgroups: newValue }, null, null, { fromUrl: true },
      () => { setShowModal(false); }
    );
  };

  const firstOption = () => (localData.length ? localData[0] : null);
  const secondOption = () => ((localData?.length > 1) ? localData[1] : null);
  const fullOption = (value) => find(filtersList, (item) => item.value === value);

  const handleSelectFirst = (data) => {
    setLocalData([ data?.value, secondOption() ]);
  };

  const handleSelectSecond = (data) => {
    setLocalData([ firstOption(), data?.value ]);
  };

  const filteredFiltersList = (index = 0) => {
    const hiddenValue = [ firstOption(), secondOption() ][index];
    return filter(filtersList, (item) => item.value !== hiddenValue);
  };

  return (
    <Modal
      modalClassName="verizon-modifier -research centered visible-full"
      dialogClassName="verizon-modifier w50p modal-sm"
      backDropModifier="verizon-modifier -summary-editor"
      modal
    >
      <div className="summary-editor">
        <div className="summary-editor_header">
          <h3 className="summary-editor_title">
            Select Subgroups
          </h3>
        </div>
        <div className="summary-editor_body">
          <div className="form_section">
            <div className="form_grid">
              <div className="form_grid-item -width-full -with-select">
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={ filteredFiltersList(1) }
                  placeholder="Select subgroup"
                  value={ fullOption(firstOption()) }
                  onChange={ handleSelectFirst }
                  isSearchable
                  isClearable={ !!firstOption() }
                />
              </div>
            </div>
            <div className="form_grid">
              <div className="form_grid-item -width-full -with-select">
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={ filteredFiltersList(0) }
                  placeholder="Select subgroup"
                  value={ fullOption(secondOption()) }
                  onChange={ handleSelectSecond }
                  isSearchable
                  isClearable={ !!secondOption() }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="summary-editor_footer">
          <div className="summary-editor_footer-buttons -right">
            <button
              className="button -default"
              type="button"
              onClick={  () => { setShowModal(false); } }
              disabled={ false }
            >
              Cancel
            </button>
            <button
              className="button"
              type="button"
              onClick={ handleAdd }
              disabled={ loading }
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AdditionalSubgroupsModal;
