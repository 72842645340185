import React, { useContext } from 'react';
import { map } from 'lodash';
import { baseContext } from '../../../../respondent/common/contexts';
import { IndexData } from '../../../common/index-context';
import Checkbox from '../../../verizon_customer_case_v2024/components/checkbox';
import BarsTable from '../customQuestion/barsTable';
import QuestionSingle from '../customQuestion/questionSingle';
import renderRawHtml from '../../../../../common/render_raw_html';
import ArrowsNote from '../arrowsNote';

const CustomQuestionDemo = () => {
  const { model, setModel } = useContext(baseContext);
  const { data } = useContext(IndexData);
  const dataCQD = data.custom_question_demo;

  return (
    <div className="comparisons_plates">
      <div className="comparisons_section">
        <h2 className="comparisons_section-title">Custom Question</h2>
        <Checkbox
          title="Add to Report"
          value={ model.custom_question_demo }
          paramName="custom_question_demo"
          onChange={ (v) => { setModel({ ...model, custom_question_demo: v }); } }
        />
      </div>

      <div className="comparisons_plates-grid">
        <div className="comparisons_plates-grid-item">
          <div className="comparisons_plate -full-height">
            <h3 className="comparisons_plate-title -center -no-transform" { ...renderRawHtml(dataCQD.text) } />
            {(dataCQD.kind === 'Multi' || dataCQD.kind === 'Single' && !dataCQD.as_point_scale) &&
              <BarsTable records={ map(dataCQD.answers, (el) => ([ el.text, el.percent_text, el.percent, el.arrow ])) } />
            }
            {dataCQD.kind === 'Single' && dataCQD.as_point_scale &&
              <QuestionSingle dataCQ={ dataCQD } />
            }
            {dataCQD.subgroups &&
              <>
                <hr />
                <h3 className="comparisons_plate-title -center">Subgroups</h3>
                <BarsTable records={ map(dataCQD.subgroups, (el) => ([ el.text, el.percent_text, el.percent, el.arrow ])) } />
              </>}
          </div>
        </div>
      </div>
      <div className="comparisons_plate-legend -margin-xs">
        <ArrowsNote arrows={ data.arrows_mode } text={ data.arrows_label_text } blackText />
      </div>
    </div>
  );
};

export default CustomQuestionDemo;
