import React from "react";
import classnames from 'classnames';
import Arrow from './arrow';

const arrowsNote = ({ arrows, text, blackText = true }) => (
  <>
    {
      arrows ? (
        <div className={ classnames("comparisons_legend", { 'black-text': blackText }) }>
          <div className="comparisons_legend-grid">
            <div className="comparisons_legend-grid-item">
              <Arrow color="green" />
              <div className="comparisons_legend-text">Meaningfully better than {text} for this case</div>
            </div>
            <div className="comparisons_legend-grid-item">
              <Arrow color="red" />
              <div className="comparisons_legend-text">Meaningfully worse than {text} for this case</div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )
    }
  </>
);

export default arrowsNote;
