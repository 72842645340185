import { each, find, some, includes, map } from "lodash";

const filterNames = [
  { title: "Case Name", name: "concept_title" },
  { title: "Case ID", name: "case_id" },
  { title: "Branded / Unbranded", name: "branded" },
  { title: "Test Type", name: "audience_category" },
  { title: "Take Rate From", name: "overall_take_rate_from" },
  { title: "Take Rate To", name: "overall_take_rate_to" },
  { title: "Awareness From", name: "awareness_from" },
  { title: "Awareness To", name: "awareness_to" },
  { title: "Date Submitted From", name: "submission_date_from" },
  { title: "Date Submitted To", name: "submission_date_to" },
  { title: "Concept Type", name: "concept_type" },
  { title: "Software Product Type(s)", name: "software_product", type: "multi" },
  { title: "Sample", name: "segment" },
  { title: "Targeted Subgroup", name: "subgroups" },
  { title: "Journey Stage", name: "journey_stage" },
  { title: "Development Stage", name: "development_stage" },
  { title: "Vertical", name: "vertical" },
  { title: "Priced", name: "priced" },
  { title: "Recommendation", name: "recommendation" },
  { title: "Includes Unhappy Path", name: "include_deviation" },
  { title: "Includes Image", name: "include_image" },
  { title: "Includes Main Custom", name: "include_main_custom" },
  { title: "Includes Demo Custom", name: "include_demo_custom" },
  { title: "Project Lead Email(s)", name: "project_lead_email", type: "multi" }
];

const sortNames = [
  { title: "Case Name", name: "concept_title" },
  { title: "Case ID", name: "case_id" },
  { title: "Test Type", name: "audience_category" },
  { title: "Take Rate", name: "take_rate" },
  { title: "Submission Date", name: "submission_date" },
  { title: "Sample", name: "segment" },
  { title: "Targeted Subgroup", name: "subgroups" },
  { title: "Journey Stage", name: "journey_stage" },
  { title: "Development Stage", name: "development_stage" },
  { title: "Vertical", name: "vertical" },
  { title: "Priced", name: "priced" },
  { title: "Recommendation", name: "recommendation" },
  { title: "Status", name: "status_text" },
  { title: "Completes", name: "respondents_count" }
];

const filterHumanView = (filters, baseFilters, styled = true) => {
  const values = [];
  each(filterNames, (item) => {
    const value = filters.get(item.name);
    if (value) {
      if (item.type === "multi") {
        const vals = value.split(",");
        const texts = map(vals, (v) => getHumanValue(v, baseFilters[item.name])).join(", ");
        values.push(`${styled ? '<b>' : ''}${item.title}:${styled ? '</b>' : ''} ${texts}`);
      } else {
        values.push(`${styled ? '<b>' : ''}${item.title}:${styled ? '</b>' : ''} ${getHumanValue(value, baseFilters[item.name], filters, item.name)}`);
      }
    }
  });

  return values.join("   ");
};

const exportFilters = (filters, baseFilters) => {
  const values = {};
  each(filterNames, (item) => {
    const value = filters.get(item.name);
    if (value) {
      values[item.name] = value;
    }
  });
  values.text = filterHumanView(filters, baseFilters, false).replace('&', '<amp>');

  return values;
};

const exportSort = (filters) => {
  const sort = filters.get('sort');
  const order = filters.get('order');
  let text = 'None';
  if (sort) {
    text = find(sortNames, (item) => (item.name === sort))?.title;
    return { sort, order, text };
  }

  return { text };
};

const getHumanValue = (value, data, filters = [], name = "") => {
  if (data) {
    if (name === "segment") {
      const topicValue = filters.get("audience_category");
      return find(data, (item) => (item.sub_index == value && item.test_topic == topicValue))?.label || value;
    }
    return find(data, (item) => (item.value == value))?.label || value;
  }

  return value;
};

const canDelete = (isAdmin, project, clientId) => (isAdmin);

const filterExists = (searchRow) => {
  const searchAsArray = Array.from(searchRow?.keys());
  return some(searchAsArray, (el) => (!includes([ "q", "page", "sort", "order" ], el)));
};

const vgSelectStyles = {
  control: (styles) => {
    return {
      ...styles,
      borderRadius: 20,
      fontSize: "13px",
      "&:hover": { },
      boxShadow: '#000000',
      borderColor: '#000000'
    };
  },
  option: (styles, state) => {
    return {
      ...styles,
      fontSize: "13px",
      color: "#000000",
      backgroundColor: state.isFocused ? '#f2f2f2' : styles.backgroundColor,
      "&:hover": { backgroundColor: '#f2f2f2' }
    };
  },
  menu: (provided) => {
    return {
      ...provided,
      fontSize: "13px",
      zIndex: 9999,
      color: "#000000"
    };
  },
  multiValue: (styles) => (
    {
      ...styles,
      fontSize: "13px",
      borderRadius: 70,
      color: "#ffffff",
      background: "#000000",
      paddingRight: "5px",
      paddingLeft: "5px"
    }
  ),
  multiValueLabel: (styles) => ({ ...styles, fontSize: "13px", color: "#ffffff", whiteSpace: "normal" }),
  valueContainer: (styles) => ({ ...styles, fontSize: "13px", paddingLeft: "5px" }),
  multiValueRemove: (styles) => {
    return { ...styles, color: "#ffffff", ":hover": { color: '#808080' } };
  }
};

export { filterHumanView, canDelete, exportFilters, filterExists, exportSort, vgSelectStyles };
