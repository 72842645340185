import React from 'react';

const Note = () => (
  <div className="ai-bottom-note">
    This summary was created with our BuzzIQ engine, powered by ChatGPT technology.
    { ' ' }
    It automatically summarizes and surfaces primary themes to help bring the voice
    { ' ' }
    of the respondent to life and aid in diagnostics. Keep in mind that this represents
    { ' ' }
    qualitative analysis. Consider reviewing actual responses to increase confidence and/or
    { ' ' }
    make appropriate revisions to the AI summary output.
  </div>
);

export default Note;
