import React, { useContext, useState, useEffect } from 'react';
import { each, flatten, intersection } from 'lodash';
import { GeneralContext } from '../../../contexts';
import FivePointScale from '../five_point_scale';
import Intro from '../../intro';
import renderRawHtml from '../../../../../../common/render_raw_html';
import ItemView from '../../ItemView';
import { fixQuestionTextTemplates } from '../../../logic/templatesProcessing';
import Stimuli from '../../../../verizon_customer_case_v2024/components/stimuli';

const Idea = () => {
  const {
    goForward, metricGroupStep, setMetricGroupStep, initialMetricGroupStep,
    concept, ideaMetrics, result, translations
  } = useContext(GeneralContext);

  const metricIndex = metricGroupStep - 2;

  const prepareCurrentQuestions = () => {
    if (metricIndex >= 0) {
      const questions = ideaMetrics[metricIndex];
      each(questions, (question, index) => {
        question.text = fixQuestionTextTemplates(
          question, flatten(ideaMetrics), result, translations
        );
        const wrapperClassName = "metric-question-initially-hidden";
        if (index) {
          question.wrapperClassName = wrapperClassName;
        } else {
          question.onSelect = (value) => {
            if (value) {
              each(questions, (question, index) => {
                if (index) {
                  question.wrapperClassName = `${wrapperClassName} -show`;
                  const fixedText = fixQuestionTextTemplates(question, flatten(ideaMetrics), result, translations, { answers: value });
                  question.replacedText = fixedText;
                }
              });
            }
          };
        }
      });
      return questions;
    }
    return [];
  };

  const [ currentQuestions, setCurrentQuestions ] = useState(prepareCurrentQuestions);
  useEffect(() => {
    setCurrentQuestions(prepareCurrentQuestions());
  }, [ metricGroupStep ]);


  const nextStep = () => {
    if (metricIndex < ideaMetrics.length - 1) {
      const isCustomer = intersection([ 'vz_customer', 'att_customer', 'tmobile_customer' ], result.tags).length > 0;
      const isProspect = intersection([ 'vz_prospect', 'att_prospect', 'tmobile_prospect' ], result.tags).length > 0;

      const nextIsChurn = !!(ideaMetrics[metricIndex + 1] && ideaMetrics[metricIndex + 1][0].title === 'CHURN POTENTIAL');
      const nextIsAcquisition = !!(ideaMetrics[metricIndex + 1] && ideaMetrics[metricIndex + 1][0].title === 'ACQUISITION POTENTIAL');

      if (nextIsChurn && !isCustomer) {
        setMetricGroupStep(metricGroupStep + 2);
      } else if (nextIsAcquisition && !isProspect) {
        setMetricGroupStep(metricGroupStep + 2);
      } else {
        setMetricGroupStep(metricGroupStep + 1);
      }
    } else {
      setMetricGroupStep(initialMetricGroupStep);
      goForward();
    }
  };

  const fullConceptData = concept.full_concept_data;

  return (
    <>
      {
        !metricGroupStep &&
        <Intro nextStep={ nextStep } >
          <>
            <div className="survey-start_description -left-aligned">
              <p className="intro-line" { ...renderRawHtml(translations.idea_intro_top) } />
              <p className="intro-line" { ...renderRawHtml(translations.idea_intro_middle) } />
              <p className="intro-line" { ...renderRawHtml(translations.idea_intro_bottom) } />
            </div>
          </>
        </Intro>
      }
      {
        metricGroupStep === 1 &&
        <>
          <ItemView nextStep={ nextStep }>
            <div className="survey-start_description -left-aligned">
              <Stimuli
                ideaName={ fullConceptData.idea_name }
                customerBenefit={ fullConceptData.customer_benefit }
                reasonsToBelieve={ fullConceptData.reasons_to_believe }
                details={ fullConceptData.details }
                priced={ fullConceptData.priced }
                processStepsKind={ fullConceptData.additional_image_url && 'text' }
                showImageIfExist={ fullConceptData.show_image_if_exist }
                additionalImageUrl={ fullConceptData.additional_image_url }
                additionalImageNotes={ fullConceptData.additional_image_notes }
                branded={ fullConceptData.branded }
              />
            </div>
          </ItemView>
        </>
      }
      {
        metricIndex >= 0 && !!currentQuestions[0] &&
        <>
          <FivePointScale
            key={ currentQuestions[0].name }
            customConceptView={
              <Stimuli
                ideaName={ fullConceptData.idea_name }
                customerBenefit={ fullConceptData.customer_benefit }
                reasonsToBelieve={ fullConceptData.reasons_to_believe }
                details={ fullConceptData.details }
                priced={ fullConceptData.priced }
                processStepsKind={ fullConceptData.additional_image_url && 'text' }
                showImageIfExist={ fullConceptData.show_image_if_exist }
                additionalImageUrl={ fullConceptData.additional_image_url }
                additionalImageNotes={ fullConceptData.additional_image_notes }
                branded={ fullConceptData.branded }
              />
            }
            concept={ concept }
            questions={ currentQuestions }
            result={ result }
            nextStep={ nextStep }
          >
            <></>
          </FivePointScale>
        </>
      }
    </>
  );
};

export default Idea;
