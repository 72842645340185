import React, { useContext } from 'react';
import { compact, keys, pickBy } from 'lodash';
import { BaseContext } from '../contexts';

const Header = ({ newProjectPath, setShowList }) => {
  const {
    projectPlatform, projectExpress, verizonViewer, verizonEditor, selectedForComparison,
    setDatabaseComparisonModal, comparisonMode, setComparisonMode
  } = useContext(BaseContext);

  const pids = keys(selectedForComparison);
  const pidsTotal = pids.length;
  const pidsSelected = keys(pickBy(selectedForComparison, (hash) => (hash.show)));
  const pidsSelectedTotal = compact(pidsSelected).length;

  const handleOnCreate = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.location.href = `${newProjectPath}?platform=${projectPlatform}&express=${projectExpress}`;
  };

  const countForComparison = keys(selectedForComparison).length;

  const handleToggle = () => {
    if (countForComparison < 2) {
      return;
    }
    if (comparisonMode === "database") {
      setComparisonMode("comparison");
    } else {
      setComparisonMode("database");
    }
  };

  const handleOpenComparisonPopup = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDatabaseComparisonModal(true);
  };

  return (
    <div className="content_top -sticky -white">
      <header className="header">
        <div className="header_grid">
          <div className="header_grid-item buttons-with-text">
            <div className="radio-tabs -gray-bg -bordered">
              <label id="label-product" className="radio-tabs_item">
                <input
                  id="project_brand_type_product"
                  type="radio"
                  checked={ comparisonMode === "database" }
                  value="database"
                  onChange={ handleToggle }
                />
                <div className="radio-tabs_label -header">Database View</div>
              </label>
              <label id="label-service" className="radio-tabs_item">
                <input
                  id="project_brand_type_service"
                  type="radio"
                  checked={ comparisonMode === "comparison" }
                  disabled={ countForComparison < 2 }
                  value="comparison"
                  onChange={ handleToggle }
                />
                <div className="radio-tabs_label -header">Comparison</div>
              </label>
            </div>
            {
              comparisonMode === "database" && countForComparison > 0 &&
              <div className="comparison-text">
                { countForComparison } item{ countForComparison > 1 ? 's' : '' } in comparison.
                { ' ' }
                <span className="link -light-blue" onClick={ handleOpenComparisonPopup }>
                  Set Cases for Comparison
                </span>
              </div>
            }
            {
              comparisonMode === "comparison" &&
              <div className="comparison-text">
                <span>
                  Showing {pidsSelectedTotal} of {pidsTotal} selected items.
                </span> <span className="link -light-blue" onClick={ () => { setShowList(true);} }>
                  Set Cases for Comparison
                </span>
              </div>
            }
          </div>

          {
            !verizonViewer && !verizonEditor && (comparisonMode === "database") &&
            <div className="header_grid-item -gl-buttons">
              <button
                className="button"
                onClick={ handleOnCreate }
              >
                Create Project
              </button>
            </div>
          }
        </div>
      </header>
    </div>
  );
};

export default Header;
