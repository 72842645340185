import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import { includes, map } from "lodash";
import { canDelete } from '../helpers';
import Loader from "../../../../../../../images/icons/loader.gif";
import { destroyProject } from '../../requests';
import renderRawHtml from "../../../../../common/render_raw_html";
import { IndexData, BaseContext } from "../contexts";
import Checkbox from '../../../../../express/admin/common/Checkbox';
import CopyProject from './copy-project';
import { TdOpenReport, TdOpenReportContext } from './tdOpenReport';
import VisibilityGroupModal from './visibilityGroupModal';

const ProjectAsTableRow = ({ project, isAdmin, index, total, canManageVisibilityGroups }) => {
  const [ loading, setLoading ] = useState(false);
  const [ showCopyForm, setShowCopyForm ] = useState(false);
  const handleOpenCopyForm = () => {
    setShowCopyForm(true);
  };
  const handleCloseCopyForm = () => {
    setShowCopyForm(false);
  };
  const { loadProjects, clientId } = useContext(IndexData);
  const {
    selectedForComparison, toggleSelectedForComparison, verizonRoles
  } = useContext(BaseContext);
  const verizonViewer = includes(verizonRoles, 'verizon_viewer');

  const handleSelectForComparison = () => {
    toggleSelectedForComparison(project);
  };

  const tdOpenReportValue = { project };

  const projectInComparison = selectedForComparison[project?.id];

  const optionsPopupClass = (index) => {
    if (total <= 3) {
      return '-to-right-top-offset';
    }

    return `${total - index <= 3 ? '-to-right-top' : '-to-right-bottom'}`;
  };

  const [ showVGModal, setShowVGModal ] = useState(false);

  return (
    <>
      <TdOpenReportContext.Provider value={ tdOpenReportValue }>
        <tr
          className={
            classnames("body-row", { "-copying": project.copying, '-selected': projectInComparison })
          }
        >
          <td className="-center -verizon_cell -sticky-left">
            <Checkbox
              className={
                classnames("concept-selection", { '-selected': projectInComparison })
              }
              bool={ selectedForComparison[project.id] || false }
              onChange={ handleSelectForComparison }
            />
          </td>
          {canManageVisibilityGroups &&
            <td className="-verizon_cell">
              {map(project.visibility_groups, (vg) => (
                <div key={ vg.value } className="table_status-label -tag">
                  {vg.label}
                </div>
              ))}
            </td>}
          <TdOpenReport className="project-case_name -verizon_cell">
            { project?.case_name }
          </TdOpenReport>
          <TdOpenReport className="project-case_id -verizon_cell">
            { project?.case_id }
          </TdOpenReport>
          <TdOpenReport className="project-branded -verizon_cell">
            { project?.branded_ui_text }
          </TdOpenReport>
          <TdOpenReport className="project-submission_date -verizon_cell">
            { project?.submission_date }
          </TdOpenReport>
          <TdOpenReport className="project-take_rate -verizon_cell -right">
            { project?.overall_take_rate }
          </TdOpenReport>
          <TdOpenReport className="project-journey_stage -verizon_cell">
            { project?.development_stage }
          </TdOpenReport>
          <TdOpenReport className="project-journey_stage -verizon_cell">
            { project?.vertical }
          </TdOpenReport>
          <TdOpenReport className="project-test_type -verizon_cell">
            { project?.test_type }
          </TdOpenReport>
          <TdOpenReport className="project-sample -verizon_cell -sample">
            { project?.sample }
          </TdOpenReport>
          <TdOpenReport
            className="project-interest_subgroups -verizon_cell"
            { ...renderRawHtml(project?.interest_subgroups.join("<br>")) }
          />
          <TdOpenReport className="project-status -verizon_cell">
            { project.status }
          </TdOpenReport>
          <TdOpenReport className="project-completes -verizon_cell">
            {
              (project.npassed || project.required_nsize) &&
              <>
                {project.npassed ? project.npassed : <span>&mdash;</span>}
                { ' ' }
                /
                { ' ' }
                {project.required_nsize ? project.required_nsize : <span>&mdash;</span>}
              </>
            }
            { !project.npassed && !project.required_nsize && <span>&mdash;</span> }
          </TdOpenReport>
          <td className="project-actions -verizon_cell">
            {
              loading &&
              <div className="dropdown header_button -right-sided -no-min-width">
                <img width="20px" height="20px" src={ Loader } />
              </div>
            }
            {
              !loading &&
              <div className="dropdown header_button -right-sided -no-min-width">
                <span
                  className="dropdown_toggle button" data-toggle="dropdown" role="button" aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg className="icon -vertical-vector"><use xlinkHref="#svg_vertical-vector" /></svg>
                </span>
                <div className={ `dropdown_container -corner-right ${optionsPopupClass(index)}` }>
                  <div className="dropdown_inset">
                    <div className="dropdown_content">
                      <ul>
                        {
                          (!verizonViewer && (!project.remote_respondents || project.force_edit_project)) &&
                          <li>
                            <a className="project-preview_link" href={ project.edit_path }>
                              {
                                project.remote_respondents ?
                                  <>Edit Project</> :
                                  <>Project Setup</>
                              }
                            </a>
                            {
                              project.remote_respondents &&
                              <span className="project-preview_link-addition">&nbsp;(Limited)</span>
                            }
                          </li>
                        }
                        <li>
                          <a className="project-preview_link" href={ project.report_path }>
                            Report
                          </a>
                        </li>
                        {
                          (project.can_review_respondents) &&
                          <li>
                            <a className="project-preview_link" href={ project.review_respondents_path }>
                              Review Respondents
                            </a>
                          </li>
                        }
                        {
                          !!project.can_edit_survey_flow &&
                          <li>
                            <a
                              className="project-preview_link"
                              href={ project.edit_survey_flow }
                            >
                              Edit Survey Flow
                            </a>
                          </li>
                        }
                        {
                          project.can_preview &&
                          <li>
                            <a
                              className="project-preview_link"
                              target="blank"
                              href={ project.survey_path }
                            >
                              Preview
                            </a>
                          </li>
                        }
                        {
                          project.can_copy_project &&
                          <li>
                            <a className="project-preview_link" onClick={ handleOpenCopyForm }>
                              Copy Project
                            </a>
                          </li>
                        }
                        {
                          canManageVisibilityGroups &&
                          <li>
                            <a
                              className="project-preview_link"
                              onClick={ () => { setShowVGModal(true); } }
                            >
                              Add Visibility Groups
                            </a>
                          </li>
                        }
                        {
                          canDelete(isAdmin, project, clientId) &&
                          <li className="-red">
                            <a
                              className="project-preview_link"
                              onClick={ () => {
                                destroyProject(
                                  project.title,
                                  project.destroy_path,
                                  project.status === "Unpublished",
                                  setLoading,
                                  loadProjects
                                );
                              } }
                            >
                              Delete Project
                            </a>
                          </li>
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            }
            {
              showCopyForm &&
              <CopyProject project={ project } onClose={ handleCloseCopyForm } />
            }
          </td>
        </tr>
      </TdOpenReportContext.Provider>
      {canManageVisibilityGroups && showVGModal &&
        <VisibilityGroupModal
          project={ project }
          closeModal={ () => {setShowVGModal(false);} }
        />}
    </>
  );
};

export default ProjectAsTableRow;
