import {
  destroyProject, getProjects as getBaseProjects
} from '../../../express/admin/ml-project/requests';
import Http from '../../../common/http';

const getProjects = (q, page, goodCallback, badCallback, options) => {
  getBaseProjects(
    q, page, goodCallback, badCallback,
    { customUrl: '/verizon_cat_v2024/api/projects', filters: options?.filters }
  );
};

const copyProject = (projectId, params, copyPath, goodCallback, badCallback) => {
  Http.post(copyPath, params).then(
    (http) => {
      if (goodCallback) {
        goodCallback();
      }
    },
    (reject) => {
      console.log('something went wrong', reject);
      if (badCallback) {
        badCallback();
      }
    });
};

export { copyProject, destroyProject, getProjects };
