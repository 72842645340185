import React, { useContext, useEffect, useState } from 'react';
import { includes, keys, pickBy, findIndex } from 'lodash';
import Header from "../common/components/header/index";
import { IndexData } from "../common/index-context";
import { baseContext } from '../../respondent/common/contexts';
import { userDownloadCCPlatform } from '../../../verizonCatV2024/common/helpers';
import { OpenEndContext } from '../common/openEndContext';
import Comparisons from "./components/comparisons";

const AppInner = () => {
  const { init, loadData, data, setSelectedOptions, setSelectedBsg, loading } = useContext(IndexData);
  const { isAdmin, verizon_roles } = init;
  const verizonViewer = includes(verizon_roles, 'verizon_viewer');
  const [ model, setModel ] = useState({
    opportunity_action: true,
    concept_diagnostics: true,
    pricing_feedback: true,
    custom_question: true,
    custom_question_demo: true,
    appeal_high: false,
    appeal_low: false,
    action_interest_high: false,
    action_interest_low: false,
    study: true
  });

  const [ additionalSubgroups, setAdditionalSubgroups ] = useState([]);

  useEffect(() => {
    loadData(null, { additional_subgroups: additionalSubgroups }, null, null, { fromUrl: true }, (data) => {
      setSelectedOptions(data?.selected_sub_groups || []);
      setSelectedBsg(data?.selected_bsg);

      setModel({
        ...model,
        pricing_feedback: !!data?.pricing_feedback,
        custom_question: !!data?.custom_question,
        custom_question_demo: !!data?.custom_question_demo,
        // custom_question_ai_summary_: !!data?.custom_question?.ai_summary,
        // appeal_high: findIndex(data.ai_summaries || [], (el) => (el.tag === 'vcc_appeal_open_end__high')) > -1,
        appeal_high: false,
        // appeal_low: findIndex(data.ai_summaries || [], (el) => (el.tag === 'vcc_appeal_open_end__low')) > -1,
        appeal_low: false,
        // action_interest_high: findIndex(data.ai_summaries || [], (el) => (el.tag === 'vcc_action_interest_open_end__high')) > -1,
        action_interest_high: false,
        // action_interest_low: findIndex(data.ai_summaries || [], (el) => (el.tag === 'vcc_action_interest_open_end__low')) > -1
        action_interest_low: false
      });
    });
  }, []);

  const reports = [];

  reports.push({
    title: 'Scorecard',
    name: 'cat_scorecard',
    kinds: [ 'google', 'google_pdf' ],
    kindTitles: [ 'Google Slides', 'PDF' ]
  });

  if (!verizonViewer) {
    reports.push({
      title: 'Respondent Level',
      name: 'verizon_cat_v2024_all_respondents',
      kinds: isAdmin ? [ 'xlsx', 'google' ] : [ 'google' ],
      kindTitles: isAdmin ? [ 'XLSX', 'Google Sheets' ] : [ 'Google Sheets' ]
    });
  }

  reports.push({
    title: 'Open Ends',
    name: 'verizon_cat_v2024_open_ends',
    kinds: [ 'google' ],
    kindTitles: [ 'Google Sheets' ]
  });

  if (isAdmin) {
    reports.push({ title: 'Quota Details', name: 'quotas_details', kinds: [ 'xlsx' ] });
  }

  const handleUpdateSummary = (aiSummary) => {
    const index = findIndex(data.ai_summaries || [], (el) => (el.tag === aiSummary.tag));
    if (index > -1) {
      data.ai_summaries[index].raw_data_hashes = aiSummary.raw_data_hashes;
    } else {
      data.ai_summaries.push(aiSummary);
    }

    if (aiSummary.tag === 'vcc_action_interest_open_end__high') {
      data.opportunity_action.vcc_action_interest_open_end.ai_summary = aiSummary.raw_data_hashes;
    }
    if (aiSummary.tag === 'vcc_appeal_open_end__high') {
      data.concept_diagnostics.vcc_appeal_open_end.ai_summary = aiSummary.raw_data_hashes;
    }
    if (aiSummary.tag === 'vcc_appeal_open_end__low') {
      data.concept_diagnostics.vcc_not_appeal_open_end.ai_summary = aiSummary.raw_data_hashes;
    }
    if (aiSummary.tag === 'express_custom_question_1') {
      data.custom_question.ai_summary = aiSummary.raw_data_hashes;
    }
  };

  const openEndContextValue = {
    handleUpdateSummary, isAdmin,
    aiSummaryAvailable: init.ai_summary_available
  };

  const baseContextValue = {
    translations: data?.respondent_translations,
    model,
    setModel,
    additionalSubgroups,
    setAdditionalSubgroups,
    additionalSubgroupsOptions: data.subgroups_list,
    filtersList: data.sub_groups
  };

  const handleGeneratingExportParams = (reportItem, reportKindIndex, reportTitle, formatTitle) => {
    return {
      platform: userDownloadCCPlatform,
      report_title: `${init.case_id} ${init.case_name} %date%: ${reportTitle}`,
      format_title: formatTitle,
      slides: keys(pickBy(model, (value) => (value === true))),
      additionalSubgroups
    };
  };

  return (
    <baseContext.Provider value={ baseContextValue }>
      <OpenEndContext.Provider value={ openEndContextValue }>
        <div className="content -loading-wrapper tour-parent-header -research verizon-modifier">
          <Header
            customTitle={ init.case_name }
            reports={ reports }
            generateExportParams={ handleGeneratingExportParams }
            plural="concepts"
            sortGroups={ [] }
            showCustomizeView={ false }
          />

          {loading && <div className="page-loader -inner-loader -transparent -fixed" />}

          <Comparisons />
        </div>
      </OpenEndContext.Provider>
    </baseContext.Provider>
  );
};

export default AppInner;
