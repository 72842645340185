import React, { useRef, useState } from 'react';
import { isEmpty } from "lodash";
import Header from "../../common/header";
import WithSubmit from "../../common/WithSubmit";
import PageLoader from "../../common/PageLoader";
import HiddenFields from "./HiddenFields";
import CMList from "./customMetrics";

const App = ({ data, options, errors }) => {
  const [ model, setModel ] = useState({
    projectId: data.id,
    errors
  });

  const initHasChanges = useRef(options.generate || !isEmpty(errors));
  const hasChanges = useRef(initHasChanges.current);

  return (
    <WithSubmit formId="form" errors={ errors } hasChangesFunc={ () => (hasChanges) } options={ options }>
      <HiddenFields data={ data } options={ options } model={ model } />
      <div className="content -relative -grey-background">
        <Header title="Custom Questions" />
        <div className="content_body">
          <div className="form">
            {!!options.jid && <PageLoader options={ options } />}

            <CMList
              key={ `Question-QF` }
              prefix={ "QF" }
              data={ data }
              options={ options }
              title="Custom Main Question"
              grid_data={ options.metrics_grid_data }
              images={ options.metrics_images }
              metricsHasChanges={ hasChanges }
              platform="verizon"
              main
            />
            <CMList
              key={ `Question-QS` }
              prefix={ "QS" }
              data={ data }
              options={ options }
              title="Custom Demo Question"
              grid_data={ options.metrics_grid_data }
              images={ options.metrics_images }
              metricsHasChanges={ hasChanges }
              platform="verizon"
            />
          </div>
        </div>
      </div>
    </WithSubmit>
  );
};

export default App;
