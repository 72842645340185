import React, { useState, useContext } from 'react';
import CreatableSelect from 'react-select/creatable';
import { map } from 'lodash';
import cn from "classnames";
import Modal from '../../../common/components/modal';
import { vgSelectStyles } from "../projects/components/helpers";
import { GeneralContext } from './contexts';
import { sendApplyChanges } from './requests';

const VisibilityGroupModal = ({ user, setUser }) => {
  const [ dirtyValues, setDirtyValues ] = useState(user.visibility_groups);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState("");
  const {
    createdVisibilityGroups, setCreatedVisibilityGroups, setUsers, closeModal,
    sortingField, sortingOrder, kind
  } = useContext(GeneralContext);

  const handleOnChange = (newValues) => {
    setDirtyValues(map(newValues, (el) => {
      return { value: el.__isNew__ ? '' : el.value, label: el.label };
    }));
  };

  const handleApply = (deleteMode) => {
    if (!deleteMode || window.confirm("Do you really want to delete groups attached to user?")) {
      setLoading(true);
      sendApplyChanges(
        {
          userId: user.id,
          email: user.email,
          visibilityGroups: deleteMode ? [] : dirtyValues,
          sortingField, sortingOrder, kind
        },
        (http) => {
          setUsers(http.data.users);
          setCreatedVisibilityGroups(http.data.visibility_groups);
          closeModal();
        },
        (http) => {
          if (http?.data?.errors) {
            setError(http.data.errors);
          } else {
            console.log('something went wrong', http);
          }
          setLoading(false);
        }
      );
    }
  };

  return (
    <Modal
      modalClassName="centered"
      dialogClassName="verizon-modifier -visibility-groups"
      backDropModifier="verizon-modifier -visibility-groups"
      modalContentClass="-overflow-visible"
      modalSize="md"
      modal
    >
      <div className="summary-editor">
        <div className="summary-editor_header">
          <h3 className="summary-editor_title">
            { !user.id ? "Add User" : "Edit User" }
          </h3>
        </div>

        <div>
          <h3>Email</h3>
          <div className="summary-editor_form-group">
            <input
              className="form-field -block"
              maxLength="250"
              size="250"
              type="text"
              value={ user.email }
              onChange={ (ev) => { setUser({ ...user, email: ev.target.value }); } }
              disabled={ !!user.id || loading }
            />
            {!!error && <div className="form-error">{error}</div>}
          </div>

          <h3>Visibility Groups</h3>
          <div className="summary-editor_form-group">
            <CreatableSelect
              defaultValue={ user.visibility_groups }
              className="react-select-container"
              classNamePrefix="react-select"
              options={ createdVisibilityGroups }
              onChange={ handleOnChange }
              isSearchable
              isMulti
              autosize={ false }
              maxMenuHeight={ 150 }
              styles={ vgSelectStyles }
              isDisabled={ loading }
            />
          </div>
        </div>
        <div className={ cn("summary-editor_footer", { "-flex": !!user.id }) }>
          {
            !!user.id &&
            <button
              className="button -vcc-delete"
              type="button"
              onClick={ () => { handleApply(true); } }
              disabled={ loading }
            >
              Delete
            </button>
          }
          <div className="summary-editor_footer-buttons -right">
            <button
              className="button -default"
              type="button"
              onClick={ closeModal }
              disabled={ loading }
            >
              Cancel
            </button>
            <button
              className="button"
              type="button"
              onClick={ () => { handleApply(false); } }
              disabled={ loading || !dirtyValues.length || !user.email }
            >
              Apply Changes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VisibilityGroupModal;
