import React from 'react';
import { map } from 'lodash';

const HiddenFields = ({ data, options, model }) => {
  return (
    <>
      <input type="hidden" name="step" id="step" value={ options.step } />
      <input type="hidden" name="generate_step" id="generate_step" value={ options.step } />
      <input type="hidden" name="express" id="express" value={ data.express } />
      <input type="hidden" name="platform" id="platform" value={ data.platform } />

      <input type="hidden" name="project[client_id]" id="project_client_id" value={ options.client_id } />
      <input type="hidden" name="project[platform_id]" id="project_platform_id" value={ model.platformId } />
      <input type="hidden" name="project[title]" id="project_title" value={ model.title } />
      <input type="hidden" name="project[note]" id="project_note" value={ model.note } />
      <input type="hidden" name="project[verizon_express_setting][concept_title]" id="concept_title" value={ model.conceptTitle } />
      <input type="hidden" name="project[verizon_express_setting][case_id]" id="case_id" value={ model.caseID } />
      <input type="hidden" name="project[verizon_express_setting][target]" id="project_target" value={ model.target } />
      <input type="hidden" name="project[verizon_express_setting][segment]" id="project_segment" value={ model.segment } />
      <input type="hidden" name="project[verizon_express_setting][test_kind]" id="project_test_kind" value={ model.testKind } />
      <input type="hidden" name="project[verizon_express_setting][branded]" id="project_branded" value={ model.branded } />
      <input type="hidden" name="project[verizon_express_setting][brand]" id="project_brand" value={ model.brand } />
      <input type="hidden" name="project[verizon_express_setting][submission_date]" id="project_submission_date" value={ model.submissionDate } />
      <input type="hidden" name="project[verizon_express_setting][software_product]" id="project_software_product" value={ model.softwareProduct } />
      <input type="hidden" name="project[verizon_express_setting][audience_category]" id="project_audience_category" value={ model.audienceCategory } />
      <input type="hidden" name="project[verizon_express_setting][subgroups_other]" id="project_subroups_other" value={ model.subgroupsOther } />
      <input type="hidden" name="project[verizon_express_setting][project_lead_email]" id="project_lead_email" value={ model.projectLeadEmail } />
      <input type="hidden" name="project[verizon_express_setting][executive_request_flag]" id="executive_request_flag" value={ model.executiveRequestFlag } />
      <input type="hidden" name="project[verizon_express_setting][idea]" id="idea" value={ model.idea } />
      <input type="hidden" name="project[verizon_express_setting][vertical]" id="vertical" value={ model.vertical } />
      <input type="hidden" name="project[verizon_express_setting][vertical_other]" id="vertical_other" value={ model.verticalOther } />
      <input type="hidden" name="project[verizon_express_setting][development_stage]" id="development_stage" value={ model.developmentStage } />

      {
        map(model.subgroups, (value, index) => (
          <input
            key={ `subgroups${index}` }
            type="hidden"
            name="project[verizon_express_setting][subgroups][]"
            value={ value }
          />
        ))
      }
      {
        !model.subgroups.length &&
        <input type="hidden" name="project[verizon_express_setting][subgroups][]" />
      }

      {
        map(model.emails, (value, index) => (
          <input
            key={ `emails${index}` }
            type="hidden"
            name="project[verizon_express_setting][emails][]"
            value={ value }
          />
        ))
      }
      <input
        type="hidden"
        name="project[verizon_express_setting][emails_size]"
        value={ model.emails?.length || 0 }
      />
    </>
  );
};

export default HiddenFields;
