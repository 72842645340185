import React, { useEffect, useState, useRef } from 'react';
import { includes } from 'lodash';
import Http from '../../../common/http';
import Header from './components/app-index/header';
import PlateOptions from "./components/app-index/plate-options";
import Projects from "./components/app-index/projects";
import { IndexData, BaseContext } from "./components/contexts";
import { getProjects } from './requests';
import BeforeLoadedProjects from './components/app-index/before-loaded-projects';
import Filters from "./components/app-index/filters";
import Comparison from "./components/comparison/index";
import FiltersModal from "./components/app-index/filtersModal";
import FiltersInfo from "./components/app-index/filtersInfo";
import { exportFilters, exportSort, filterExists } from "./components/helpers";
import CaseComparisonManager from './components/app-index/caseComparisonManager';

const App = ({
  mode, sort, dir, path, is_demo_user, new_project_path,
  per_page, is_bb_admin, buzzback_user, client_id, projects_allowance,
  project_platform, project_express, verizon_roles, base_filters, can_manage_visibility_groups
}) => {
  const [ loadedFirstTime, setLoadedFirstTime ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ total, setTotal ] = useState(0);
  const [ page, setPage ] = useState(1);
  const [ stateMode, setMode ] = useState(mode);
  const [ stateSort, setSort ] = useState(sort);
  const [ stateDir, setDir ] = useState(dir);
  const [ requestCounter, setRequestCounter ] = useState(0);
  const [ projects, setProjects ] = useState([]);
  const [ visibilityGroups, setVisibilityGroups ] = useState([]);
  const [ clients, setClients ] = useState([]);
  const [ selectedForComparison, setSelectedForComparison ] = useState({});
  const [ databaseComparisonModal, setDatabaseComparisonModal ] = useState(false);
  const [ comparisonMode, setComparisonMode ] = useState("database");
  const [ filters, setFilters ] = useState(window.location.search.replace('?', ""));
  const [ filterParams, setFilterParams ] = useState(new URLSearchParams(window.location.search));
  const [ showFilters, setShowFilters ] = useState(false);
  const [ sortParam, setSortParam ] = useState(filterParams.get("sort") || "");
  const [ order, setOrder ] = useState(filterParams.get("order") || "");
  const [ showList, setShowList ] = useState(false);

  const loadProjects = (text, searchRow = filters, sortRow = `sort=${sortParam}&order=${order}`) => {
    setLoading(true);
    getProjects((typeof text === 'string' ? text : ''), page,
      (data) => {
        setTotal(data.total);
        setProjects(data.projects);
        setClients(data.clients);
        setVisibilityGroups(data.visibility_groups);
        setLoading(false);
        setLoadedFirstTime(true);
        window.history.replaceState(null, "", `${location.pathname}?${searchRow}`);
        setFilterParams(new URLSearchParams(window.location.search));
      },
      () => {
        setLoading(false);
      },
      { filters: searchRow }
    );
  };

  const toggleSelectedForComparison = (project) => {
    const newValue = !selectedForComparison[project.id];
    if (newValue) {
      setSelectedForComparison({
        ...selectedForComparison,
        [project.id]: { id: project.id, caseName: project.case_name, show: true }
      });
    } else {
      delete selectedForComparison[project.id];
      setSelectedForComparison({ ...selectedForComparison });
    }
  };

  const saveSettings = () => {
    const rc = requestCounter + 1;
    setRequestCounter(rc);

    const params = {
      page,
      mode: stateMode,
      sort: stateSort,
      dir: stateDir,
      request_id: rc
    };

    setLoading(true);

    Http.post(
      path.settings,
      params
    ).then(
      (http) => {
        if (http.data.request_id === rc) {
          setTotal(http.data.total);
          setProjects(http.data.projects);
          setClients(http.data.clients);
        }

        setLoading(false);
      },
      (reject) => {
        console.log('something went wrong', reject);
        setLoading(false);
      });
  };

  const firstModeUpdate = useRef(true);
  useEffect(() => {
    if (firstModeUpdate.current) {
      firstModeUpdate.current = false;
    } else {
      saveSettings();
    }
  }, [ stateMode ]);

  useEffect(() => {
    loadProjects();
  }, [ page ]);

  const firstSortDirUpdate = useRef(true);
  useEffect(() => {
    if (firstSortDirUpdate.current) {
      firstSortDirUpdate.current = false;
    } else {
      saveSettings();
    }
  }, [ stateSort, stateDir ]);

  const createSortRow = () => (sortParam.length ? `sort=${sortParam}&order=${order}` : "");

  const contextValue = {
    projects,
    setProjects,
    clients,
    setClients,
    sort: stateSort,
    setSort,
    dir: stateDir,
    setDir,
    loadProjects,
    clientId: client_id,
    demoUser: is_demo_user,
    filters,
    order,
    setOrder,
    sortParam,
    setSortParam,
    setLoading,
    createSortRow,
    visibilityGroups,
    setVisibilityGroups
  };

  const verizonRoles = verizon_roles;
  const baseContextValue = {
    projectsAllowance: projects_allowance,
    buzzbackUser: buzzback_user,
    demoUser: is_demo_user,
    admin: is_bb_admin,
    projectPlatform: project_platform,
    projectExpress: project_express,
    selectedForComparison,
    setSelectedForComparison,
    toggleSelectedForComparison,
    databaseComparisonModal,
    setDatabaseComparisonModal,
    verizonRoles,
    verizonViewer: includes(verizonRoles, 'verizon_viewer'),
    verizonEditor: includes(verizonRoles, 'verizon_editor'),
    baseFilters: base_filters,
    comparisonMode,
    setComparisonMode
  };

  const hasProjects = !!projects?.length;

  const onClearFilters = () => {
    setFilters('');
    loadProjects("", createSortRow());
  };

  const handleCloseComparisonManagerPopup = (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    setDatabaseComparisonModal(false);
  };

  return (
    <BaseContext.Provider value={ baseContextValue }>
      {
        loadedFirstTime &&
        <div className="content -grey-background verizon-modifier -comparison -research">
          <IndexData.Provider value={ contextValue }>
            <Header
              newProjectPath={ new_project_path }
              setShowList={ setShowList }
            />

            {
              comparisonMode === 'database' &&
              <>
                <div className="projects_controls -sticky">
                  <div className="projects_controls-grid -gap-md">
                    {total > per_page &&
                      <div className="projects_controls-grid-item">
                        <PlateOptions
                          mode={ stateMode }
                          setMode={ setMode }
                          sort={ stateSort }
                          dir={ stateDir }
                          page={ page }
                          setPage={ setPage }
                          total={ total }
                          perPage={ per_page }
                        />
                      </div>
                    }
                    { filterExists(filterParams) &&
                      <FiltersInfo
                        filterParams={ filterParams }
                        baseFilters={ base_filters }
                        onClearFilters={ onClearFilters }
                      />
                    }
                    <div className="projects_controls-grid-item -auto_margin">
                      <Filters
                        show={ showFilters }
                        onChange={ setShowFilters }
                        filters={ exportFilters(filterParams, base_filters) }
                        sort={ exportSort(filterParams) }
                        projectsCount={ projects?.length }
                        loading={ loading }
                      />
                      {
                        showFilters &&
                        <div className="filters-modal-place">
                          <FiltersModal
                            onSubmit={ loadProjects }
                            onClose={ setShowFilters }
                            filters={ filters }
                            setFilters={ setFilters }
                            filterParams={ filterParams }
                          />
                        </div>
                      }
                    </div>
                  </div>
                </div>

                {
                  !hasProjects && !filterExists(filterParams) &&
                  <div className="content_body -express">
                    <div className="express-intro">
                      <div className="express-intro_text">
                        <h1 className="express-intro_title">No projects.</h1>
                      </div>
                    </div>
                  </div>
                }
                {
                  !hasProjects && filterExists(filterParams) &&
                  <div className="content_body -express">
                    <div className="express-intro">
                      <div className="express-intro_text">
                        <h1 className="express-intro_title">
                          No projects found. Please try to expand filters.
                        </h1>
                      </div>
                    </div>
                  </div>
                }
                {
                  hasProjects &&
                  <Projects
                    loading={ loading }
                    isAdmin={ is_bb_admin }
                    projects={ projects }
                    mode={ stateMode }
                    canManageVisibilityGroups={ can_manage_visibility_groups }
                  />
                }
              </>
            }

            {comparisonMode === 'comparison' &&
              <Comparison
                loading={ loading }
                setLoading={ setLoading }
                showList={ showList }
                setShowList={ setShowList }
              />}
          </IndexData.Provider>
        </div>
      }
      {
        !loadedFirstTime &&
        <BeforeLoadedProjects />
      }
      {
        databaseComparisonModal &&
        <CaseComparisonManager
          selectedForComparison={ selectedForComparison }
          toggleSelectedForComparison={ toggleSelectedForComparison }
          closePopup={ handleCloseComparisonManagerPopup }
        />
      }
    </BaseContext.Provider>
  );
};

export default App;
