import React from 'react';
import { map } from 'lodash';
import TrGray from '../../../../../verizonCustomerCaseV2024/admin/projects/components/comparison/trGray';
import Arrow from '../../../../../express/researcher/verizon_customer_case_v2024/components/arrow';
import TrData from './trData';
import CommonTrs from './commonTrs';

const Opportunity = ({ pids, data }) => {
  const oaData = data.opportunity_action;
  const cData = data.scorecard_data;

  return (
    <div className="comparisons-table_wrapper -cat-comparison">
      <table className="table comparisons-table">
        <tbody>
          <CommonTrs pids={ pids } data={ data } />

          <tr>
            <th className="th col-name -sticky-left -no-zindex">
              <b>Awareness</b>
            </th>
            {
              map(pids, (pid) => (
                <td key={ pid } className="col-data -center">{cData[pid].awareness}%</td>
              ))
            }
          </tr>

          <tr>
            <th className="th col-name -sticky-left -no-zindex">
              <b>Overall Take Rate</b>
            </th>
            {
              map(pids, (pid) => (
                <td key={ pid } className="col-data -center">
                  <b>{cData[pid].overall_take_rate.percent_text}</b>
                  <Arrow color={ cData[pid].overall_take_rate.arrow } />
                </td>
              ))
            }
          </tr>

          <TrGray colSpan={ pids.length + 1 } text="Likelihood to Seek More Info" />
          <TrData
            pids={ pids }
            data={ oaData }
            text="Top 2 Box"
            hint="&quot;Extremely&quot; or &quot;Very&quot; likely"
            metric="vcc_learn_more"
            code="45"
          />
          <TrData
            pids={ pids }
            data={ oaData }
            text="Bottom 2 Box"
            hint="&quot;Not at all&quot; or &quot;Not very&quot; likely"
            metric="vcc_learn_more"
            code="12"
          />

          <TrGray colSpan={ pids.length + 1 } text="Likelihood to Take Action" />
          <TrData
            pids={ pids }
            data={ oaData }
            text="Top 2 Box"
            hint="&quot;Extremely&quot; or &quot;Very&quot; likely"
            metric="vcc_action_interest"
            code="45"
          />
          <TrData
            pids={ pids }
            data={ oaData }
            text="Bottom 2 Box"
            hint="&quot;Not at all&quot; or &quot;Not very&quot; likely"
            metric="vcc_action_interest"
            code="12"
          />

          <TrGray colSpan={ pids.length + 1 } text="Customer Need" />
          <TrData
            pids={ pids }
            data={ oaData }
            text="Category Need"
            hint="“Absolutely” or “Very much” needed"
            metric="vcc_need"
            code="45"
          />
          <TrData
            pids={ pids }
            data={ oaData }
            text="Satisfaction with Category Solutions"
            hint="“Extremely” or “Very” satisfied"
            metric="vcc_cat_satisfaction"
            code="45"
          />
        </tbody>
      </table>
    </div>
  );
};

export default Opportunity;
