import React, { useContext, Fragment } from 'react';
import { map } from 'lodash';
import Checkbox from '../../../verizon_customer_case_v2024/components/checkbox';
import { baseContext } from '../../../../respondent/common/contexts';
import { IndexData } from '../../../common/index-context';
import ShortAISummaries from '../opportunityAction/shortAISummaries';
import ArrowsNote from '../arrowsNote';
import MetricRow from './metricRow';
import OptionRow from './optionRow';


const ConceptDiagnostics = () => {
  const { model, setModel } = useContext(baseContext);
  const { data } = useContext(IndexData);
  const dataCD = data.concept_diagnostics;

  const shortsAppeal = map(dataCD.vcc_appeal_open_end.ai_summary, (el) => (el.short_summary));
  const shortsNotAppeal = map(dataCD.vcc_not_appeal_open_end.ai_summary, (el) => (el.short_summary));

  return (
    <>
      <div className="comparisons_plates">
        <div className="comparisons_section">
          <h2 className="comparisons_section-title">Concept Diagnostics</h2>
          <Checkbox
            title="Add to Report"
            value={ model.concept_diagnostics }
            paramName="concept_diagnostics"
            onChange={ (v) => { setModel({ ...model, concept_diagnostics: v }); } }
          />
        </div>

        <div className="comparisons_plates-grid">
          <div className="comparisons_plates-grid-item">
            <div className="comparisons_plate -full-height">
              <div className="comparisons_plate-top -center -colored">
                Concept  Diagnostics
              </div>
              <MetricRow
                mainText="Overall Appeal"
                smallText="&quot;Extremely&quot; or &quot;Very&quot; appealing"
                percent={ dataCD.vcc_appeal['45'].percent_text }
                arrow={ dataCD.vcc_appeal['45'].arrow }
              />
              <MetricRow
                mainText="Uniqueness"
                smallText="&quot;Extremely&quot; or &quot;Very&quot; different"
                percent={ dataCD.vcc_cat_difference['45'].percent_text }
                arrow={ dataCD.vcc_cat_difference['45'].arrow }
              />
              <MetricRow
                mainText="Consumer Market Success Prediction"
                smallText="&quot;Extremely&quot; or &quot;Very&quot; successful"
                percent={ dataCD.vcc_market_success['45'].percent_text }
                arrow={ dataCD.vcc_market_success['45'].arrow }
              />

              <hr />

              <MetricRow
                mainText="Tell a Friend"
                smallText="&quot;Extremely&quot; or &quot;Very&quot; likely"
                percent={ dataCD.vcc_tell_a_friend['45'].percent_text }
                arrow={ dataCD.vcc_tell_a_friend['45'].arrow }
              />
              {data.priced === 'priced' &&
                <MetricRow
                  mainText="Value"
                  smallText="&quot;Extremely&quot; or &quot;Very&quot; good value"
                  percent={ dataCD.vcc_cat_worthwhile['45'].percent_text }
                  arrow={ dataCD.vcc_cat_worthwhile['45'].arrow }
                />}
              {
                dataCD.vcc_churn_potential.n !== 0 &&
                <MetricRow
                  mainText="Likelihood to Stay (among Customers)"
                  smallText="&quot;Much&quot; or &quot;Somewhat&quot; more likely"
                  percent={ dataCD.vcc_churn_potential['45'].percent_text }
                  arrow={ dataCD.vcc_churn_potential['45'].arrow }
                />
              }
              {
                dataCD.vcc_acquisition_potential.n !== 0 &&
                <MetricRow
                  mainText="Likelihood to Switch (among Prospects)"
                  smallText="“Extremely” or “Very” likely"
                  percent={ dataCD.vcc_acquisition_potential['45'].percent_text }
                  arrow={ dataCD.vcc_acquisition_potential['45'].arrow }
                />
              }
            </div>
          </div>

          <div className="comparisons_plates-grid-item">
            <div className="comparisons_plate -full-height">
              <div className="comparisons_plate-top -center -colored">
                VZ Opinion Impact
              </div>
              <h3 className="comparisons_plate-title -margin-bottom-md">
                Total Respondents
              </h3>
              <OptionRow
                text="Improves"
                percent={ dataCD.vcc_cat_opinion['3'].percent_text }
                arrow={ dataCD.vcc_cat_opinion['3'].arrow }
              />
              <OptionRow
                text="No Impact"
                percent={ dataCD.vcc_cat_opinion['2'].percent_text }
                arrow={ dataCD.vcc_cat_opinion['2'].arrow }
              />
              <OptionRow
                text="Worsens"
                percent={ dataCD.vcc_cat_opinion['1'].percent_text }
                arrow={ dataCD.vcc_cat_opinion['1'].arrow }
              />

              <hr />

              {map(data.additional_subgroups_data, (el, index) => {
                return (
                  <Fragment key={ index }>
                    <h3 className="comparisons_plate-title -margin-bottom-md">{el.title}</h3>
                    <OptionRow
                      text="Improves"
                      percent={ el.vcc_cat_opinion['3'].percent_text }
                      arrow={ el.vcc_cat_opinion['3'].arrow }
                    />
                    <OptionRow
                      text="No Impact"
                      percent={ el.vcc_cat_opinion['2'].percent_text }
                      arrow={ el.vcc_cat_opinion['2'].arrow }
                    />
                    <OptionRow
                      text="Worsens"
                      percent={ el.vcc_cat_opinion['1'].percent_text }
                      arrow={ el.vcc_cat_opinion['1'].arrow }
                    />
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
        <div className="comparisons_plate-legend -margin-xs">
          <ArrowsNote arrows={ data.arrows_mode } text={ data.arrows_label_text } blackText />
        </div>

        <div className="comparisons_section">
          <h2 className="comparisons_section-title">Unaided Likes / Dislikes</h2>
        </div>
        <div className="comparisons_plates-grid">
          <div className="comparisons_plates-grid-item">
            <div className="comparisons_plate -full-height">
              <ShortAISummaries
                text={ `Unaided, top themes shown among the ${dataCD.vcc_appeal_open_end.percent}% 
                        (n=${dataCD.vcc_appeal_open_end.n}) of respondents who said the idea is extremely to very appealing.` }
                shorts={ shortsAppeal }
              />
            </div>
          </div>
          <div className="comparisons_plates-grid-item">
            <div className="comparisons_plate -full-height">
              <ShortAISummaries
                text={ `Unaided, top themes shown among the ${dataCD.vcc_not_appeal_open_end.percent}% 
                        (n=${dataCD.vcc_not_appeal_open_end.n}) of respondents who said the idea is somewhat to not at all appealing.` }
                shorts={ shortsNotAppeal }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConceptDiagnostics;
