import React from 'react';
import { map } from 'lodash';
import Arrow from '../../../verizon_customer_case_v2024/components/arrow';

const BarsTable = ({ records }) => {
  return (
    <table className="bar-table">
      <tbody>
        {map(records, (record, index) => (
          <tr key={ index }>
            <td>{record[0]}</td>
            <td>
              {record[1]}
              <Arrow color={ record[3] } />
            </td>
            <td>
              <div className="bar-100" />
              <div className="bar-red" style={ { width: `${record[2]}%` } } />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default BarsTable;
