import React, { useState, useEffect, useRef, useContext } from 'react';
import cn from "classnames";
import SvgIcon from '../../../../common/components/svg-icon';
import Wysiwyg from '../../../../common/components/wysiwyg';
import {
  firstNotDeletedIndex,
  reorderQuestions
} from './helper';
import { QuestionContext, QuestionsContext } from './contexts';
import ScreeningQuestionAnswers from "./ScreeningQuestionAnswers";
import ToggleShowStimuli from "./ToggleShowStimuli";

const ScreeningQuestion = ({
  options, question, screenerQuestions,
  setScreenerQuestions, questionIndex,
  needQuota, needLogicEdit, needOther, needNone,
  needFivePtScaleToggle, needDedicatedPage,
  gridData, needRows, images, withImages, deleteButtonModifier, needLocation, main
}) => {
  const manageFivePtToggleCondition = () => (
    (question.answers.length >= 5 && question.kind === 'Single') ||
    (question.kind === 'Grid' && question.scales.length === 5)
  );
  const fivePtScaleCheck = () => (question.as_point_scale);

  const [ selectIsOpened, setSelectIsOpened ] = useState(false);
  const [ manageFivePtToggle, setManageFivePtToggle ] = useState(manageFivePtToggleCondition);
  const [ fivePtScaleMode, setFivePtScaleMode ] = useState(fivePtScaleCheck);
  const [ customSubgroupsMode, setCustomSubgroupsMode ] = useState(question.use_custom_subgroups || false);
  const [ showStimuli, setShowStimuli ] = useState(question.show_stimuli || false);
  question.toggleManageQuota = needQuota;
  question.availableToManageQuotas = needQuota;
  question.uiTitle = () => ('Screener Question');

  const hasErrorKind = question.errors && question.errors.kind;
  const hasErrorText = question.errors && question.errors.text;

  const kindName = (name) => {
    return options.kinds_main[name];
  };

  const onSelectClick = () => {
    if (fivePtScaleMode) {
      return;
    }
    setSelectIsOpened(!selectIsOpened);
  };

  const selectOption = (event, name) => {
    event.preventDefault();
    event.stopPropagation();

    question.kind = name;

    updateManageFivePtToggle();
    setScreenerQuestions([ ...screenerQuestions ]);
    setSelectIsOpened(false);
  };

  const updateManageFivePtToggle = () => {
    if (manageFivePtToggleCondition()) {
      setManageFivePtToggle(true);
    } else {
      setManageFivePtToggle(false);
    }
  };

  const delQuestion = (event) => {
    event.preventDefault();
    question._destroy = true;
    reorderQuestions(screenerQuestions);
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const onChangeText = (newHtml) => {
    question.text = newHtml;
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setSelectIsOpened(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ ref ]);

  const contextValue = {
    needLogicEdit, needNone, needOther, manageFivePtToggle,
    setManageFivePtToggle, fivePtScaleMode, setFivePtScaleMode,
    needFivePtScaleToggle, updateManageFivePtToggle, needDedicatedPage,
    gridData, needRows, images, withImages, main, customSubgroupsMode, setCustomSubgroupsMode,
    showStimuli, setShowStimuli
  };

  return (
    <QuestionContext.Provider value={ contextValue }>
      <div
        className={
          cn({
            'form-question -screening': !question._destroy,
            'destroyed-question': question._destroy,
            '-first-actual': firstNotDeletedIndex(question, questionIndex, screenerQuestions)
          })
        }
      >
        <div className="form_section">
          <div className="form_section-line">
            <div className="form_grid -no-top-margin">
              <div
                className={
                  cn(
                    "form_grid-item -width-full -relative -multi-elements",
                    { 'has-error': hasErrorKind }
                  )
                }
              >
                <div
                  className={
                    cn(
                      "form-field -block -select",
                      {
                        '-not-selected': !question.kind || fivePtScaleMode,
                        '-opened': selectIsOpened,
                        '-disabled': fivePtScaleMode
                      }
                    )
                  }
                  onClick={ onSelectClick }
                >
                  {!question.kind && <span>Select Question Type</span>}
                  <span>{ kindName(question.kind) }</span>
                </div>

                {
                  selectIsOpened &&
                  <div
                    ref={ ref }
                    className="form_grid-item -width-2-3 -select-options"
                  >
                    <div className="form-question_type">
                      <div
                        className="form-question_type-col -first"
                        onClick={ (event) => {selectOption(event, 'Single');} }
                      >
                        <div className="form-question_type-title">
                          { kindName('Single') }
                        </div>
                        <div className="form-question_type-text">
                          respondents may only select one answer
                        </div>
                      </div>

                      <div className="form-question_type-divider">
                        or
                      </div>

                      <div
                        className="form-question_type-col"
                        onClick={ (event) => {selectOption(event, 'Multi');} }
                      >
                        <div className="form-question_type-title">
                          { kindName('Multi') }
                        </div>
                        <div className="form-question_type-text">
                          respondents may select more than one answer
                        </div>
                      </div>

                      {
                        main &&
                        <>
                          <div className="form-question_type-divider">
                            or
                          </div>

                          <div
                            className="form-question_type-col"
                            onClick={ (event) => {selectOption(event, 'Open');} }
                          >
                            <div className="form-question_type-title">
                              { kindName('Open') }
                            </div>
                            <div className="form-question_type-text">
                              respondents type their answer
                            </div>
                          </div></>
                      }
                    </div>
                  </div>
                }
              </div>

              <div className="form_grid-item -multi-elements">
                <button
                  className={ `button -secondary -white-back ${deleteButtonModifier ? deleteButtonModifier : ''}` }
                  onClick={ delQuestion }
                >
                  <SvgIcon name="i-delete" className="button_icon -delete" />
                  <span className="button_text">
                    Delete
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className={ cn("form_section-line", { 'has-error': hasErrorText }) }>
            <Wysiwyg
              customPicker
              toolbarClassName="wysiwyg-menu"
              editorClassName="wysiwyg-textarea"
              placeholder="Add"
              enableIndent
              value={ question.text }
              onChange={ onChangeText }
            />
          </div>
        </div>
        <ScreeningQuestionAnswers
          options={ options }
          question={ question }
          screenerQuestions={ screenerQuestions }
          setScreenerQuestions={ setScreenerQuestions }
          questionIndex={ questionIndex }
          needLocation={ needLocation }
        />
        {
          main &&
          <div className="form_section-title-item -grid -bordered">
            <ToggleShowStimuli
              key="show_stimuli_toggle"
              question={ question }
              questions={ screenerQuestions }
              setQuestions={ setScreenerQuestions }
              customSubgroupsMode={ customSubgroupsMode }
              setCustomSubgroupsMode={ setCustomSubgroupsMode }
            />
          </div>
        }
      </div>
    </QuestionContext.Provider>
  );
};

export default ScreeningQuestion;
