import React, { useContext, useState } from 'react';
import { QuestionContext } from "./contexts";

const ToggleShowStimuli = ({
  question, questions, setQuestions
}) => {
  const { showStimuli, setShowStimuli } = useContext(QuestionContext);
  const [ id ] = useState(() => (`Custom Question-${Date.now()}`));

  const onManageUpdate = (event) => {
    question.show_stimuli = !showStimuli;
    setShowStimuli(!showStimuli);
    setQuestions([ ...questions ]);
  };

  return (
    <>
      {
        (
          <div className="toggle -manage -five-point-scale" >
            <div className="form_section-title -grid">
              <div className="form_section-title-item -grid">
                <input
                  className="hide"
                  type="checkbox"
                  checked={ showStimuli }
                  id={ id }
                  onChange={ () => {} }
                />
                <label className="form-switch" htmlFor={ id } onClick={ onManageUpdate } >
                  {`Toggle ${question.answers.length}-point scale question`}
                </label>
              </div>
            </div>
            <div className="form_section-title -grid">
              <div className="form_section-title-item -grid">
                <div className="form_section-text -bold">Show Stimuli</div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default ToggleShowStimuli;
