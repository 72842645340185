import React, { useState } from 'react';
import { filter, map, includes } from "lodash";
import { initConditionList, filteredQuestionsForFields } from '../../common/custom_questions/helper';
import { HighLevelContext } from '../../common/custom_questions/contexts';
import ScreeningQuestion from './ScreeningQuestion';
import HiddenFields from './components/HiddenFields';

const App = ({ data, options, grid_data, metricsHasChanges, platform, title, main, prefix }) => {
  const initScreenerQuestions = () => (
    map(prefix === "QF" ? data.custom_metrics : data.survey_metrics, (q) => initConditionList(q, {}, {}))
  );
  const [ metricQuestions, setMetricQuestions ] = useState(initScreenerQuestions);
  const filteredQuestions = (
    filter(metricQuestions, (q) => includes(q.name, prefix) || includes(q.prefix, prefix))
  );

  const [ initialJSON ] = useState(() => {
    return JSON.stringify(metricQuestions);
  });

  const [ conceptsCountValue, setConceptsCountValue ] = useState(() => (
    data.concepts_count_for_custom_metrics || options.custom_metrics_concepts_number_data.max
  ));
  const numberLimit = options.number_limit;
  const hasNumberLimit = !!numberLimit;
  const filteredList = filter(filteredQuestions, (q) => !q._destroy);
  const emptyListQuestions = !filteredList.length;
  const addQuestionDisabled = (hasNumberLimit && filteredList.length >= numberLimit);

  const conceptsCountData = {
    ...options.custom_metrics_concepts_number_data,
    ...{
      value: conceptsCountValue,
      setValue: (value) => {
        setConceptsCountValue(value);
      }
    }
  };
  conceptsCountData.display = !!filteredList.length && conceptsCountData.enabled;

  const updateList = (newValue) => {
    setMetricQuestions(newValue);
  };

  const addQuestion = (event) => {
    event.preventDefault();
    updateList(
      [
        ...metricQuestions,
        {
          text: "",
          answers: [ "" ],
          kind: "",
          condition: {},
          condition_list: [],
          answers_order: "specific",
          multi_condition: 1,
          quotas: {},
          manage_quota: false,
          scales: [ "" ],
          answers_subgroups: [ { id: 1, subgroups: [] } ],
          pinned_answers: [ "" ],
          show_stimuli: false,
          subgroups: [ ],
          use_custom_subgroups: false,
          survey_location: "default",
          position: filteredList.length,
          tmpIndex: metricQuestions.length,
          prefix
        }
      ]
    );
  };

  const highLevelContextValue = { conceptsCountData };

  const [ initialValue ] = useState(() => {
    return highLevelContextValue.conceptsCountData.value;
  });

  const isUpdated = () => {
    const s1 = initialJSON;
    const s2 = JSON.stringify(metricQuestions);
    const n1 = parseInt(initialValue);
    const n2 = parseInt(highLevelContextValue.conceptsCountData.value);

    const regExp = /(\\n|\\r|\\t)/g;

    const result = s1.replace(regExp, '') !== s2.replace(regExp, '') || (n1 || 0) !== (n2 || 0);
    if (metricsHasChanges) {
      metricsHasChanges.current = result;
    }

    return result;
  };

  return (
    <HighLevelContext.Provider value={ highLevelContextValue }>
      <div className="custom-metric-questions -max-screener-and-custom-metrics-width">
        <HiddenFields
          questions={ filteredQuestionsForFields(filteredQuestions) }
          options={ options }
          updated={ isUpdated() }
          conceptsCountEnabled={ conceptsCountData.enabled }
          conceptsCountValue={ conceptsCountValue }
          type={ prefix === "QF" ? "custom_metrics" : "survey_metrics" }
        />
        <div className="form_section-title -grid">
          <div className="form_section-title-item -custom-v_cat-metrics">
            <h3>{title}</h3>
          </div>
          {
            !addQuestionDisabled &&
            <div className="form_section-title-item">
              {
                <button className="button -white" onClick={ addQuestion } disabled={ addQuestionDisabled }>
                  Add Question
                </button>
              }
            </div>
          }
        </div>

        {
          !emptyListQuestions &&
          <>
            <div className="form-items-list">
              {
                map(filteredQuestions, (question, index) => (
                  <ScreeningQuestion
                    key={ `Question${index}-${question.name}-${question.tmpIndex}-${question.prefix}` }
                    options={ options }
                    question={ question }
                    screenerQuestions={ metricQuestions }
                    setScreenerQuestions={ updateList }
                    questionIndex={ index }
                    gridData={ grid_data }
                    images={ question.answer_images }
                    needOther
                    needNone
                    needFivePtScaleToggle
                    deleteButtonModifier={ platform === "verizon" ? '-va-delete' : '' }
                    main={ main }
                  />
                ))
              }
            </div>
          </>
        }
      </div>
    </HighLevelContext.Provider>
  );
};

export default App;
