import { each } from 'lodash';

const runActualPreloading = (src, instance, field, callback) => {
  const img = new Image();
  img.onload = function () {
    callback();
  };
  img.src = src;
  instance[field] = img;
};

const runPreload = (concepts, dispatchChangeLoadingState, kind = 'cit') => {
  if (kind === 'cit') {
    runPreloadCit(concepts, dispatchChangeLoadingState);
  } else {
    runPreloadCat(concepts, dispatchChangeLoadingState);
  }
};

const runPreloadCit = (concepts, dispatchChangeLoadingState) => {
  if ((concepts || []).length) {
    let numToLoad = 0;
    let currentlyLoaded = 0;
    each(concepts, (concept) => {
      const fullConceptData = concept.full_concept_data;
      if (fullConceptData.additional_image_url) {
        numToLoad += 1;
        runActualPreloading(
          fullConceptData.additional_image_url,
          fullConceptData,
          'additional_image_object',
          () => {
            currentlyLoaded += 1;
            if (currentlyLoaded === numToLoad) {
              dispatchChangeLoadingState(false);
            }
          }
        );
      }

      if (fullConceptData.process_steps_kind === 'images') {
        each(fullConceptData.steps, (step) => {
          numToLoad += 1;
          runActualPreloading(
            step.image_src,
            step,
            'image_object',
            () => {
              currentlyLoaded += 1;
              if (currentlyLoaded === numToLoad) {
                dispatchChangeLoadingState(false);
              }
            }
          );
        });
      }
    });
    if (!numToLoad) {
      dispatchChangeLoadingState(false);
    }
  } else {
    dispatchChangeLoadingState(false);
  }
};

const runPreloadCat = (concepts, dispatchChangeLoadingState) => {
  if (!!concepts && !!concepts.length) {
    const numToLoad = concepts.length;
    let currentlyLoaded = 0;
    each(concepts, (concept) => {
      if (concept.url) {
        runActualPreloading(
          concept.url,
          concept,
          'object',
          () => {
            currentlyLoaded += 1;
            if (currentlyLoaded === numToLoad) {
              dispatchChangeLoadingState(false);
            }
          }
        );
      } else {
        currentlyLoaded += 1;
        if (currentlyLoaded === numToLoad) {
          dispatchChangeLoadingState(false);
        }
      }
    });
  } else {
    dispatchChangeLoadingState(false);
  }
};

export default runPreload;
