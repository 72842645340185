import React from 'react';

const NoteRow = ({ boldText, text }) => {
  return (
    <div className="plate-note">
      <b>{boldText}</b> {text}
    </div>
  );
};


export default NoteRow;
