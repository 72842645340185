import React, { useContext } from 'react';
import { map, includes } from "lodash";
import { baseContext } from '../../../../respondent/common/contexts';
import { IndexData } from '../../../common/index-context';
import Checkbox from '../../../verizon_customer_case_v2024/components/checkbox';
import renderRawHtml from '../../../../../common/render_raw_html';
import ArrowsNote from '../arrowsNote';
import QuestionOpen from './questionOpen';
import BarsTable from './barsTable';
import QuestionSingle from './questionSingle';

const CustomQuestion = () => {
  const { model, setModel } = useContext(baseContext);
  const { data } = useContext(IndexData);
  const dataCQ = data.custom_question;

  return (
    <div className="comparisons_plates">
      <div className="comparisons_section">
        <h2 className="comparisons_section-title">Custom Question</h2>
        <Checkbox
          title="Add to Report"
          value={ model.custom_question }
          paramName="custom_question"
          onChange={ (v) => { setModel({ ...model, custom_question: v }); } }
        />
      </div>

      <div className="comparisons_plates-grid">
        <div className="comparisons_plates-grid-item">
          {dataCQ.kind === 'Open' &&
            <QuestionOpen
              projectId={ data.project_id }
              conceptId={ data.concept_id }
              openEndData={ dataCQ }
            />}

          {(dataCQ.kind === 'Multi' || dataCQ.kind === 'Single' && !dataCQ.as_point_scale) &&
            <div className="comparisons_plate -full-height">
              <h3 className="comparisons_plate-title -center -no-transform" { ...renderRawHtml(dataCQ.text) } />
              <BarsTable records={ map(dataCQ.answers, (el) => ([ el.text, el.percent_text, el.percent, el.arrow ])) } />
            </div>
          }
          {dataCQ.kind === 'Single' && dataCQ.as_point_scale &&
            <div className="comparisons_plate -full-height">
              <h3 className="comparisons_plate-title -center -no-transform" { ...renderRawHtml(dataCQ.text) } />
              <QuestionSingle dataCQ={ dataCQ } />
            </div>
          }
        </div>
      </div>
      {
        includes([ 'Single', 'Multi' ], dataCQ.kind) &&
        <div className="comparisons_plate-legend -margin-xs">
          <ArrowsNote arrows={ data.arrows_mode } text={ data.arrows_label_text } blackText />
        </div>
      }
    </div>
  );
};

export default CustomQuestion;
