import React, { useState } from "react";

const BrandedTabs = ({ initValue, onChange }) => {
  const [ brandTypeVal, setBrandTypeVal ] = useState(initValue || '');

  const handleOnChange = (event) => {
    setBrandTypeVal(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div className="form_section">
      <div className="form_grid">
        <div className="form_grid-item -width-full">
          <div className="form_section-title-item">
            <h3>Branded</h3>
          </div>

          <div className="form_section-tabs -sm-margin">
            <div className="radio-tabs -borderer -gray-bg -block">
              <label id="label-product" className="radio-tabs_item -full-width -inline">
                <input
                  id="project_brand_type_product"
                  type="radio"
                  checked={ brandTypeVal === "branded" }
                  value="branded"
                  onChange={ handleOnChange }
                />
                <div className="radio-tabs_label -width-1-2">Branded</div>
              </label>
              <label id="label-service" className="radio-tabs_item -full-width">
                <input
                  id="project_brand_type_service"
                  type="radio"
                  checked={ brandTypeVal === "unbranded" }
                  value="unbranded"
                  onChange={ handleOnChange }
                />
                <div className="radio-tabs_label -width-1-2">Unbranded</div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandedTabs;
