import React, { useContext } from 'react';
import { round } from 'lodash';
import { IndexData } from '../../../common/index-context';
import OpenEndFeedback from '../../../verizon_customer_case_v2024/components/openEndFeedback';


const CustomerFeedback = () => {
  const { data } = useContext(IndexData);

  const labels = data.labels;

  const actionStats = {
    high: {
      percent: round(data?.action_interest_feedback?.stats?.high || 0), nsize: data?.action_interest_feedback?.stats?.high_n
    },
    low: {
      percent: round(data?.action_interest_feedback?.stats?.low || 0), nsize: data?.action_interest_feedback?.stats?.low_n
    }
  };

  const donutTextActionInterest = {
    high: `Mentions among the ${actionStats.high.percent}% (n=${actionStats.high.nsize}) of respondents who are extremely/very likely to take action.`,
    low: `Mentions among the ${actionStats.low.percent}% (n=${actionStats.low.nsize}) of respondents who are somewhat to not at all likely to take action.`
  };

  return (
    <>
      <h2 className="comparisons_section-title">Customer Feedback – Likelihood to Take Action</h2>
      <OpenEndFeedback
        projectId={ data.project_id }
        conceptId={ data.concept_id }
        openEndData={ data.action_interest_feedback }
        entityTitle="Customer Feedback - Likelihood to Take Action"
        titles={ labels.action_interest.slider_titles }
        donutCaptions={ labels.action_interest.donut_labels }
        donutTexts={ donutTextActionInterest }
        slidePrefix={ 'action_interest' }
        questionText={ data.action_interest_question_text }
        switchColors
      />
    </>
  );
};

export default CustomerFeedback;
