import React from 'react';
import SvgIcon from "../../../../../common/components/svg-icon";
import HeaderLinks from '../../../../common/components/header-links';

const Filters = ({ show, onChange, filters, sort, loading }) => {
  const onFilterClick = () => {
    onChange(!show);
  };

  const reports = [
    {
      title: 'Database Report',
      name: 'v_cat2024_database_report',
      kinds: [ 'google' ],
      kindTitles: [ 'Google Sheets' ]
    }
  ];

  return (
    <div className="projects_controls-grid">
      <div className="projects_controls-grid-item">
        <button
          className="button -bordered-white"
          onClick={ onFilterClick }
        >
          <SvgIcon className="icon-link_icon" name="i-sliders" />
          <span className="icon-link_text">Filter</span>
        </button>
      </div>
      <div className="projects_controls-grid-item">
        <HeaderLinks
          reports={ reports }
          filter={ JSON.stringify(filters) }
          sort={ JSON.stringify(sort) }
          loading={ loading }
        />
      </div>
    </div>
  );
};

export default Filters;
