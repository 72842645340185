import { find } from 'lodash';
import Http from '../../../common/http';

const copyProject = (projectId, title, copyPath, goodCallback, badCallback) => {
  Http.post(copyPath, { title }).then(
    (http) => {
      if (goodCallback) {
        goodCallback();
      }
    },
    (reject) => {
      console.log('something went wrong', reject);
      if (badCallback) {
        badCallback();
      }
    });
};

const destroyProject = (projectTitle, deletePath, isUnpublished, setLoading, loadProjects) => {
  const confirmationText = isUnpublished ?
    `Do you really want to delete "${projectTitle}"?`
    :
    `Do you really want to delete already launched "${projectTitle}"?`;

  if (!window.confirm(confirmationText)) {
    setLoading(false);
    return;
  }

  setLoading(true);
  Http.del(
    deletePath
  ).then(
    (http) => {
      loadProjects();
      setLoading(false);
    },
    (reject) => {
      console.log('something went wrong', reject);
      setLoading(false);
    });
};

const copyingTimeout = 5000;
let copyingTimeoutId = null;
const getProjects = (q, page, goodCallback, badCallback, options = {}) => {
  clearTimeout(copyingTimeoutId);
  const customUrl = options?.customUrl;
  const filterOptions = options?.filters;
  const url = customUrl || '/express/api/projects';
  Http.get(`${url}?q=${q}&page=${page}&${filterOptions}`).then(
    (http) => {
      const data = http.data;
      goodCallback(data);
      const someIsCopying = find(data.projects, (el) => el.copying);
      if (someIsCopying) {
        copyingTimeoutId = setTimeout(() => {
          getProjects(q, page, goodCallback, badCallback, options);
        }, copyingTimeout);
      }
    },
    (reject) => {
      console.log('something went wrong', reject);
      if (badCallback) {
        badCallback(reject);
      }
    }
  );
};

const getMlProjects = (id, goodCallback, badCallback, onFirstCallback) => {
  clearTimeout(copyingTimeoutId);
  Http.get(
    `/express/api/ml_projects/${id}`
  ).then(
    (http) => {
      const data = http.data;
      goodCallback(data);
      if (onFirstCallback) {
        onFirstCallback(data);
      }
      const someIsCopying = find(data.projects, (el) => el.copying);
      if (someIsCopying) {
        copyingTimeoutId = setTimeout(() => {
          getMlProjects(id, goodCallback, badCallback);
        }, copyingTimeout);
      }
    },
    (reject) => {
      console.log('something went wrong', reject);
      if (badCallback) {
        badCallback(reject);
      }
    }
  );
};

const shareProject = (path, clientId, goodCallback, badCallback) => {
  Http.post(path, { client_id: clientId }).then(
    (http) => {
      goodCallback(http);
    },
    (reject) => {
      console.log('something went wrong', reject);
      badCallback(reject);
    }
  );
};

const demoShareProject = (path, demoValue, goodCallback, badCallback) => {
  Http.put(path, { demo: demoValue }).then(
    (http) => {
      goodCallback(http);
    },
    (reject) => {
      console.log('something went wrong', reject);
      badCallback(reject);
    }
  );
};

const revokeProjectFromSharing = (path, clientId, goodCallback, badCallback) => {
  Http.post(path, { client_id: clientId }
  ).then(
    (http) => {
      goodCallback(http);
    },
    (reject) => {
      console.log('something went wrong', reject);
      badCallback(reject);
    }
  );
};

export {
  copyProject, destroyProject,
  getProjects,
  shareProject, revokeProjectFromSharing,
  demoShareProject, getMlProjects
};
