import React, { Fragment } from 'react';
import { map } from 'lodash';
import PoweredByBuzzback
  from '../../../../../express/researcher/verizon_customer_case_v2024/common/components/poweredByBuzzback';
import SortableHeader from "../app-index/sortableHeader";
import InfoToolTip from '../../../../../express/admin/common/infoToolTip';
import ProjectAsTableRow from "./project-as-table-row";

const Projects = ({ loading, isAdmin, projects, mode, canManageVisibilityGroups }) => {
  return (
    <div className="content_body -relative -projects">
      { loading && <div className="page-loader -inner-loader -transparent -fit-half-height" /> }
      {
        projects.length > 0 &&
        <div className="projects">
          <div className="projects_list_table">
            <table className="table">
              <tbody>
                <tr className="tr-head -sticky">
                  <th className="project-comparison_selection-header -verizon_header -center -sticky-left" field="comparison_selection">
                    Select to Compare&nbsp;
                    <InfoToolTip
                      className="comparison-title"
                      remote
                      text={
                        <>
                          Select as many as desired to export to sheets.
                          {' '}
                          Select up to four to export to slides.
                        </>
                      }
                      position="bottom-right"
                    />
                  </th>
                  {canManageVisibilityGroups &&
                    <th className="-verizon_header">
                      Visibility Groups
                    </th>}
                  <SortableHeader
                    className="project-case_name-header -verizon_header"
                    field="concept_title"
                    title="Case Name"
                  />
                  <SortableHeader
                    className="project-case_id-header -verizon_header"
                    field="case_id"
                    title="Case ID"
                  />
                  <SortableHeader
                    className="project-branded-header -verizon_header"
                    field="branded"
                    title="Branded / Unbranded"
                  />
                  <SortableHeader
                    className="project-submission_date-header -verizon_header"
                    field="submission_date"
                    title="Date Submitted"
                  />
                  <SortableHeader
                    className="project-take_rate-header -verizon_header"
                    field="overall_take_rate"
                    title="Take Rate"
                  />
                  <SortableHeader
                    className="project-development_stage-header -verizon_header"
                    field="development_stage"
                    title="Development Stage"
                  />
                  <SortableHeader
                    className="project-vertical-header -verizon_header"
                    field="vertical"
                    title="Vertical"
                  />
                  <SortableHeader
                    className="project-test_type-header -verizon_header"
                    field="audience_category"
                    title="Test Type"
                  />
                  <SortableHeader
                    className="project-sample-header -verizon_header -sample-cat"
                    field="segment"
                    title="Sample"
                  />
                  <SortableHeader
                    className="project-interest_subgroups-header -verizon_header -subgroups-cat"
                    field="subgroups"
                    title="Targeted Subgroup"
                  />
                  <SortableHeader
                    className="project-tags-header -verizon_header"
                    field="status_text"
                    title="Status"
                  />
                  <SortableHeader
                    className="project-tags-header -verizon_header"
                    field="respondents_count"
                    title="Completes"
                  />
                  <th className="project-actions-header" />
                </tr>
                {
                  map(projects, (project, index) => (
                    <Fragment key={ `ProjectListItem${project.id}${index}` }>
                      <ProjectAsTableRow
                        project={ project }
                        isAdmin={ isAdmin }
                        canManageVisibilityGroups={ canManageVisibilityGroups }
                        mode={ mode }
                        index={ index }
                        total={ projects.length }
                      />
                    </Fragment>
                  ))
                }
              </tbody>
            </table>
          </div>
          <PoweredByBuzzback />
        </div>
      }
    </div>
  );
};

export default Projects;
