import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IndexData } from "../../common/index-context";
import PoweredByBuzzback from '../../verizon_customer_case_v2024/common/components/poweredByBuzzback';
import ComparisonsIntro from "./comparisonsIntro";
import ComparisonsMenu from "./comparisonsMenu";
import ComparisonsFilter from './comparisonsFilter';
import OpportunityAction from './opportunityAction';
import ConceptDiagnostics from './conceptDiagnostics';
import PricingFeedback from './pricingFeedback';
import StudyDetails from './studyDetails';
import CustomQuestion from './customQuestion';
import CustomQuestionDemo from './customQuestionDemo';
import CustomerFeedback from './customerFeedback';
import IdeaAppeal from './ideaAppeal';

const Comparisons = () => {
  const { data } = useContext(IndexData);

  const [ firstTimeRenderedWithData, setFirstTimeRenderedWithData ] = useState(false);

  const onLastElementRender = useCallback((node) => {
    if (data && node) {
      setFirstTimeRenderedWithData(true);
    }
  }, []);

  useEffect(() => {
    if (firstTimeRenderedWithData) {
      setTimeout(
        () => {
          const id = window.location.hash.replace("#", '');
          const element = document.getElementById(id);
          element?.scrollIntoView({ block: "start", behavior: "smooth" });
        },
        100
      );
    }
  }, [ firstTimeRenderedWithData ]);

  return (
    <div className="content_body">
      <div className={ `comparisons vcc-scorecard` }>
        <ComparisonsMenu />

        <ComparisonsFilter />

        <ComparisonsIntro />

        <div id="opportunity-action" className="anchor-point" />
        {data.opportunity_action && <OpportunityAction />}

        <div id="concept-diagnostics" className="anchor-point" />
        {data.concept_diagnostics && <ConceptDiagnostics />}

        <div id="pricing-feedback" className="anchor-point" />
        {data.pricing_feedback && <PricingFeedback />}

        <div id="custom-question" className="anchor-point" />
        {data.custom_question && <CustomQuestion />}

        <div id="custom-question-demo" className="anchor-point" />
        {data.custom_question_demo && <CustomQuestionDemo />}

        <div id="customer-feedback" className="anchor-point" />
        {data.action_interest_feedback && <CustomerFeedback />}

        <div id="idea-appeal" className="anchor-point" />
        {data.appeal_feedback && <IdeaAppeal />}

        <div id="study" className="anchor-point" />
        {data.methodology && <StudyDetails ref={ onLastElementRender } />}

        <PoweredByBuzzback />
      </div>
    </div>
  );
};

export default Comparisons;
