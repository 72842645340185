import React from 'react';
import cn from "classnames";
import { max } from "lodash";
import Arrow from '../../../verizon_customer_case_v2024/components/arrow';

const BarWithMarks = ({ value, text, color, arrow, average, averageText, rangeMin, rangeMax }) => {
  const realMax = max([ value, average, rangeMax ]);
  const valueStyle = { left: `${100.0 * value / realMax}%` };
  const averageStyle = { left: `${100.0 * average / realMax}%` };

  const textColor = arrow ? 'black' : color;

  return (
    <div className="bar-with-marks">
      <div className="bar">
        <div className="min-label">{rangeMin}%</div>
        <div className="max-label">{realMax}%</div>
        <div className={ cn("main-value", textColor) } style={ valueStyle }>
          {text}
          <Arrow color={ arrow } />
        </div>
        <div className="main-mark" style={ valueStyle } />
        <div className="average-mark" style={ averageStyle } />
        <div className="average-value" style={ averageStyle }>avg {averageText}</div>
      </div>
    </div>
  );
};

export default BarWithMarks;
