import React from 'react';
import { map } from 'lodash';
import Arrow from '../../../../../express/researcher/verizon_customer_case_v2024/components/arrow';
import CommonTrs from './commonTrs';
import TrData from './trData';

const Diagnostics = ({ pids, data }) => {
  const cdData = data.concept_diagnostics;
  const sdData = data.scorecard_data;

  return (
    <div className="comparisons-table_wrapper -cat-comparison">
      <table className="table comparisons-table">
        <tbody>
          <CommonTrs pids={ pids } data={ data } />

          <tr>
            <th colSpan={ pids.length + 1 } className="th-section -sticky-left -black">
              <b>Concept  Diagnostics</b>
            </th>
          </tr>

          <TrData
            pids={ pids }
            data={ cdData }
            text="Overall Appeal"
            hint="&quot;Extremely&quot; or &quot;Very&quot; appealing"
            metric="vcc_appeal"
            code="45"
          />

          <TrData
            pids={ pids }
            data={ cdData }
            text="Uniqueness"
            hint="&quot;Extremely&quot; or &quot;Very&quot; different"
            metric="vcc_cat_difference"
            code="45"
          />

          <TrData
            pids={ pids }
            data={ cdData }
            text="Consumer Market Success Prediction"
            hint="&quot;Extremely&quot; or &quot;Very&quot; successful"
            metric="vcc_market_success"
            code="45"
          />

          <TrData
            pids={ pids }
            data={ cdData }
            text="Tell a Friend"
            hint="&quot;Extremely&quot; or &quot;Very&quot; likely"
            metric="vcc_tell_a_friend"
            code="45"
          />

          <TrData
            pids={ pids }
            data={ cdData }
            text="Value"
            nonBoldText="(if Priced)"
            hint="&quot;Extremely&quot; or &quot;Very&quot; good value"
            metric="vcc_cat_worthwhile"
            code="45"
            forceNA={ (pid) => (sdData[pid].priced !== 'priced') }
          />

          <TrData
            pids={ pids }
            data={ cdData }
            text="Likelihood to Stay"
            nonBoldText="(among Customers)"
            hint="&quot;Much&quot; or &quot;Somewhat&quot; more likely"
            metric="vcc_churn_potential"
            code="45"
            forceNA={ (pid) => (cdData[pid]['vcc_churn_potential'].n === 0) }
          />

          <TrData
            pids={ pids }
            data={ cdData }
            text="Likelihood to Switch"
            nonBoldText="(among Prospects)"
            hint="“Extremely” or “Very” likely"
            metric="vcc_acquisition_potential"
            code="45"
            forceNA={ (pid) => (cdData[pid]['vcc_acquisition_potential'].n === 0) }
          />

          <tr>
            <th colSpan={ pids.length + 1 } className="th-section -sticky-left -black">
              <b>VZ Opinion Impact</b>
            </th>
          </tr>

          <TrData
            pids={ pids }
            data={ cdData }
            text="Improves"
            metric="vcc_cat_opinion"
            code="3"
          />

          <TrData
            pids={ pids }
            data={ cdData }
            text="No Impact"
            metric="vcc_cat_opinion"
            code="2"
          />

          <TrData
            pids={ pids }
            data={ cdData }
            text="Worsens"
            metric="vcc_cat_opinion"
            code="1"
          />

          <tr>
            <th colSpan={ pids.length + 1 } className="th-section -sticky-left -black">
              <b>Willingness to  Pay</b>
            </th>
          </tr>

          <tr>
            <td className="col-name -sticky-left">
              <b>Pay Frequency</b>
            </td>
            {map(pids, (pid) => (
              <td key={ pid } className="col-data -top -center">
                {sdData[pid].priced === 'unpriced' && <b>{sdData[pid].pay_frequency_text}</b>}
                {sdData[pid].priced !== 'unpriced' && <span className="note">N/A</span>}
              </td>
            ))}
          </tr>

          <TrData
            pids={ pids }
            data={ cdData }
            text="Top 2 Box"
            hint="&quot;Extremely&quot; or &quot;Very&quot; likely"
            metric="vcc_willingness_to_pay"
            code="45"
            forceNA={ (pid) => (sdData[pid].priced !== 'unpriced') }
          />

          <TrData
            pids={ pids }
            data={ cdData }
            text="Bottom 2 Box"
            hint="&quot;Not at all&quot; or &quot;Not very&quot; likely"
            metric="vcc_willingness_to_pay"
            code="12"
            forceNA={ (pid) => (sdData[pid].priced !== 'unpriced') }
          />

          <tr>
            <td className="col-name -sticky-left">
              <b>Average</b>
            </td>
            {
              map(pids, (pid) => (
                <td key={ pid } className="col-data -top -center">
                  {
                    sdData[pid].priced === 'unpriced' &&
                    <>
                      <b>{ cdData[pid].vcc_how_much['AVG'].percent_text }</b>
                      {
                        !!cdData[pid].vcc_how_much['AVG'].arrow &&
                        <Arrow color={ cdData[pid].vcc_how_much['AVG'].arrow } />
                      }
                    </>
                  }
                  { sdData[pid].priced !== 'unpriced' && <span className="note">N/A</span> }
                </td>
              ))
            }
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Diagnostics;
