import React, { Fragment, useRef, useState } from 'react';
import { isEmpty, map, find } from "lodash";
import Header from "../../common/header";
import WithSubmit from "../../common/WithSubmit";
import PageLoader from "../../common/PageLoader";
import FieldHeaderWithText from "../../common/FieldHeaderWithText";
import Select from "../../verizon_customer_case_v2024/common/select";
import Radio from "../../common/radio";
import HiddenFields from "./hiddenFields";
import cn from "classnames";

const App = ({ data, options, errors }) => {
  const initAwareness = () => {
    if (errors['verizon_express_setting.awareness_other']) {
      return options.awareness_options[1];
    } else if (!data.verizon_express_setting.awareness) {
      return options.awareness_options[0];
    }

    return find(options.awareness_options, (el) => (el.value == data.verizon_express_setting.awareness)) || options.awareness_options[1];
  };

  const [ model, setModel ] = useState(() => {
    return {
      projectId: data.id,
      caseID: data.verizon_express_setting.case_id || '',
      conceptTitle: data.verizon_express_setting.concept_title || '',
      conceptType: data.verizon_express_setting.concept_type || '',
      actionWord: data.verizon_express_setting.action_word || '',
      processDescription: data.verizon_express_setting.process_description || '',
      category: data.verizon_express_setting.category || '',
      includeDeviation: data.verizon_express_setting.include_deviation || '',
      awareness: initAwareness(),
      awarenessOther: (data.verizon_express_setting.awareness_other || '').toString(),
      errors
    };
  });

  const initHasChanges = useRef(options.generate || !isEmpty(errors));
  const hasChanges = useRef(initHasChanges.current);

  const awarenessSelected = (value) => (model.awareness?.value === value);

  const toggleAwareness = (item) => {
    setModel({ ...model, awareness: item });
  };

  const handleAwarenessChange = (event) => {
    setModel({ ...model, awarenessOther: event.target.value });
  };

  return (
    <WithSubmit formId="form" errors={ errors } hasChangesFunc={ () => (hasChanges) } options={ options }>
      <HiddenFields data={ data } options={ options } model={ model } />
      <div className="content -relative -grey-background">
        <Header title="Concept Details" />
        <div className="content_body">
          <div className="form">
            {!!options.jid && <PageLoader options={ options } />}

            <Select
              id="project-ConceptType"
              title="Concept Type"
              initValue={ model.conceptType }
              options={ options.concept_type_options }
              onChange={ (v) => { setModel({ ...model, conceptType: v }); } }
              errors={ errors['verizon_express_setting.concept_type'] }
            />

            <Select
              id="project-ActionWord"
              title="Action Word"
              initValue={ model.actionWord }
              options={ options.action_word_options }
              onChange={ (v) => {setModel({ ...model, actionWord: v });} }
              errors={ errors['verizon_express_setting.action_word'] }
            />

            <FieldHeaderWithText
              id="project-Category"
              title="Category"
              errors={ errors['verizon_express_setting.category'] }
              initValue={ model.category }
              onChange={ (v) => { setModel({ ...model, category: v }); } }
            />

            <div className="form_section">
              <div className="form_group">
                <h3>Awareness</h3>
                <div className="form_section -follow-up">
                  {
                    map(options.awareness_options, (item, index) => {
                      const enabled = awarenessSelected(item.value);
                      return (
                        <Fragment key={ `${item.value}${index}` }>
                          <div className="form_metrics-item -compact">
                            <Radio
                              label={ item.title }
                              name={ "awareness" }
                              value={ item.value }
                              bool={ enabled }
                              onChange={ () => { toggleAwareness(item); } }
                            />
                          </div>
                        </Fragment>
                      );
                    })
                  }
                </div>
              </div>
            </div>

            {
              awarenessSelected('other') &&
              <div className="form_grid">
                <div className="form_grid-item -grow">
                  <input
                    className={ cn("form-field -block", { 'has-error': errors['verizon_express_setting.awareness_other'] }) }
                    min="1"
                    max="100"
                    size="250"
                    type="number"
                    value={ model.awarenessOther }
                    onChange={ handleAwarenessChange }
                  />
                  <div className="form-error">{errors['verizon_express_setting.awareness_other']}</div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </WithSubmit>
  );
};

export default App;
