import React, { useContext } from "react";
import { IndexData } from "../../common/index-context";

const ComparisonsIntro = () => {
  const { data } = useContext(IndexData);

  return (
    <>
      <div className="comparisons_intro-grid">
        <div className="comparisons_intro-grid-item -fix">
          <div className="comparisons_intro-plate -bg-red">
            <div className="comparisons_intro-plate-value">
              N={ data.n }
            </div>
            <div className="comparisons_intro-plate-note">
              Sample Size
            </div>
          </div>
        </div>
        <div className="comparisons_intro-grid-item">
          <div className="comparisons_intro-text">
            Data shown among
            <br />
            { data.segment_full_text }
            <br />
            { data.branded_text }
          </div>
        </div>
      </div>
    </>
  );
};

export default ComparisonsIntro;
