import React, { useState, useContext, useRef, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { each, filter, map, intersection, findIndex, includes } from "lodash";
import cn from "classnames";
import Select from "react-select";
import SvgIcon from '../../../../common/components/svg-icon';
import { vgSelectStyles } from "../../../../verizonCatV2024/admin/projects/components/helpers";
import { QuestionContext } from './contexts';
import AdditionalAnswerLine from './lines/AdditionalAnswerLine';

const SQAList = ({
  options, question, questionIndex, screenerQuestions, setScreenerQuestions, subgroups
}) => {
  const {
    needNone, needOther,
    setManageFivePtToggle, setFivePtScaleMode,
    customSubgroupsMode, main
  } = useContext(QuestionContext);

  const [ , setFocusLastOnEnter ] = useState(false);
  const getPinnedAnswers = () => (
    map(question.answers,
      (_answer, index) => (
        {
          id: index,
          pinned: includes([ "true", true ], question.pinned_answers?.[index])
        }
      )
    )
  );

  const getAnswersSubgroups = () => (
    map(question.answers_subgroups,
      (item) => (
        {
          id: item.id,
          subgroups: item?.subgroups || []
        }
      )
    )
  );
  const [ answersSubgroups, setAnswersSubgroups ] = useState(getAnswersSubgroups());
  const [ pinnedAnswers, setPinnedAnswers ] = useState(getPinnedAnswers());
  const refs = useRef([]);

  useEffect(() => {
    each(answersSubgroups, (item) => (item.subgroups = intersection(item.subgroups, map(subgroups, (el) => (el.label)))));
    setAnswersSubgroups([ ...answersSubgroups ]);
    question.answers_subgroups = answersSubgroups;
    setScreenerQuestions([ ...screenerQuestions ]);
  }, [ subgroups ]);

  useEffect(() => {
    question.pinned_answers = getPinnedAnswers();
    question.answers_subgroups = getAnswersSubgroups();
    setScreenerQuestions([ ...screenerQuestions ]);
  }, [ ]);

  const onChangeAnswer = (event, index) => {
    question.answers[index] = event.target.value;
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const delAnswer = (event, answer, index) => {
    event.preventDefault();
    const additionaIndex = index + 1;

    if (question.answers.length <= 1) {
      return;
    }

    question.answers.splice(index, 1);

    const subgroupIndex = findIndex(answersSubgroups, (item) => (item.id == additionaIndex));
    if (subgroupIndex !== -1) {
      answersSubgroups.splice(subgroupIndex, 1);
      each(answersSubgroups, (el, i) => {
        if (el.id >= 0) {
          el.id = (i + 1).toString();
        }
      });
      setAnswersSubgroups([ ...answersSubgroups ]);
    }
    question.answers_subgroups = answersSubgroups;


    pinnedAnswers.splice(index, 1);
    each(pinnedAnswers, (el, i) => {
      el.id = i.toString();
    });
    setPinnedAnswers([ ...pinnedAnswers ]);
    question.pinned_answers = pinnedAnswers;

    updateFivePtToggleStates();
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const pushAnswer = () => {
    question.answers.push('');
    updateFivePtToggleStates();
  };

  const addAnswer = (event) => {
    event.preventDefault();
    pushAnswer();
    addAnswerSubgroups();
    addPinnedAnswer();
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const extractData = (event) => {
    const text = event.clipboardData.getData('text/plain');
    const lines = text.replace(/\r/g, '').replace(/\n$/, '').split("\n");
    if (lines.length === 1 && lines[0].indexOf("\t") === -1) {
      return null;
    }
    event.preventDefault();
    return lines;
  };

  const handlePaste = (event, fromIndex) => {
    const lines = extractData(event);
    if (lines != null) {
      each(lines, (line, li) => {
        const correctedLi = li + fromIndex;
        question.answers[correctedLi] = line;
      });
    }
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const answerChangeRef = (item, index) => {
    if (item) {
      refs.current[index] = item;
    }

    if (!item || question.answers.length - 1 !== index) {
      return;
    }
    setFocusLastOnEnter(
      (focusLastRequest) => {
        if (focusLastRequest) {
          item.focus();
        }
        return false;
      }
    );
  };

  const handleKeyPress = (event, index) => {
    if (event.charCode === 13) {
      event.preventDefault();
      if (question.answers.length - 1 === index) {
        pushAnswer();
        addAnswerSubgroups();
        addPinnedAnswer();
        setScreenerQuestions([ ...screenerQuestions ]);
        setFocusLastOnEnter(true);
      } else {
        refs.current[index + 1].focus();
      }
    }
  };

  const onSwitchOtherAnswer = (index) => {
    addAnswerSubgroups(index);
  };

  const updateFivePtToggleStates = () => {
    const toggleEnableCondition = question.answers.length >= 5 && question.kind === 'Single' && main;
    setManageFivePtToggle(toggleEnableCondition);
    if (question.answers.length < 5) {
      setFivePtScaleMode(false);
      question.as_point_scale = false;
      setScreenerQuestions([ ...screenerQuestions ]);
    }
  };

  const addAnswerSubgroups = (newKey) => {
    const baseFilteredData = filter(answersSubgroups, (item) => (item.id > 0));
    const additionalData = filter(answersSubgroups, (item) => (item.id < 0));
    const key = newKey || baseFilteredData.length;
    if (key > 0) {
      baseFilteredData.push({
        id: key + 1,
        subgroups: []
      });
    } else {
      additionalData.push({
        id: key,
        subgroups: []
      });
    }
    question.answers_subgroups = [ ...baseFilteredData, ...additionalData ];
    setAnswersSubgroups([ ...baseFilteredData, ...additionalData ]);
  };

  const addPinnedAnswer = () => {
    const key = question.answers.length - 1;
    pinnedAnswers.push({
      id: key,
      pinned: false
    });
    question.pinned_answers = pinnedAnswers;
    setPinnedAnswers([ ...pinnedAnswers ]);
  };

  const handleChangePinnedAnswer = (event, answer, index) => {
    event.preventDefault();
    const newData = [ ...pinnedAnswers ];
    newData[index].pinned = !newData[index].pinned;
    question.pinned_answers = newData;
    setPinnedAnswers([ ...newData ]);
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const handleAddSubgroups = (index, data) => {
    let itemIndex = findIndex(answersSubgroups, (item) => (item.id == index));
    if (itemIndex === -1) {
      answersSubgroups.push({
        id: index,
        subgroups: []
      });
      itemIndex = answersSubgroups.length - 1;
    }
    if (data.action === "select-option") {
      answersSubgroups[itemIndex].subgroups = [ ...answersSubgroups[itemIndex].subgroups, data.option.label ];
    }
    if (data.action === "remove-value") {
      const idx = findIndex(answersSubgroups[itemIndex].subgroups, (item) => (item === data.removedValue.label));
      if (idx !== -1) {
        answersSubgroups[itemIndex].subgroups.splice(idx, 1);
      }
    }
    if (data.action === "clear") {
      answersSubgroups[itemIndex].subgroups = [];
    }
    question.answers_subgroups = answersSubgroups;
    setAnswersSubgroups([ ...answersSubgroups ]);
    setScreenerQuestions([ ...screenerQuestions ]);
  };

  const getSubgroups = (index) => {
    const itemIndex = findIndex(answersSubgroups, (item) => (item.id == index));
    if ((index === -2 && !question['other_answer']) || (index === -1 && !question['none_answer'])) {
      return false;
    }
    return filter(subgroups, (item) => (
      includes(answersSubgroups[itemIndex]?.subgroups, item.label)));
  };

  const handleOnDragEnd = (result) => {
    const from = result?.source?.index,
      to = result?.destination?.index;

    if (to === undefined) {
      return;
    }

    each(question.condition_list, (el) => {
      const elIndex = parseInt(el.index);
      if (elIndex === from) {
        el.index = to;
      }
      if (from < to &&  from < elIndex  && elIndex <= to) {
        el.index = elIndex - 1;
      }
      if (from > to &&  to <= elIndex  && elIndex < from) {
        el.index = elIndex + 1;
      }
    });

    const items = Array.from(question.answers);
    const subgroupItems = Array.from(answersSubgroups);
    const pinItems = Array.from(pinnedAnswers);
    const [ reorderedItem ] = items.splice(result.source.index, 1);
    const [ reorderedSubgroupItem ] = subgroupItems.splice(result.source.index, 1);
    const [ reorderedPinItem ] = pinItems.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    if (reorderedSubgroupItem) {
      subgroupItems.splice(result.destination.index, 0, reorderedSubgroupItem);
    }
    pinItems.splice(result.destination.index, 0, reorderedPinItem);
    each(subgroupItems, (item, index) => {
      if (item.id >= 0) {
        item.id = index + 1;
      }
    });
    each(pinItems, (item, index) => (item.id = index));
    question.answers = items;
    question.answers_subgroups = subgroupItems;
    question.pinned_answers = pinItems;
    setAnswersSubgroups([ ...subgroupItems ]);
    setPinnedAnswers([ ...pinItems ]);

    setScreenerQuestions([ ...screenerQuestions ]);
  };

  return (
    <DragDropContext onDragEnd={ handleOnDragEnd }>
      <Droppable droppableId="answers-area">
        {
          (provided) => (
            <ul
              className="form-question_answers"
              { ...provided.droppableProps }
              ref={ provided.innerRef }
            >
              {
                map(question.answers, (answer, index) => (
                  <Draggable
                    key={ index }
                    draggableId={ `x${questionIndex}-${index}` }
                    index={ index }
                    isDragDisabled={ question.answers_order !== 'specific' }
                  >
                    {
                      (provided) => (
                        <li
                          id={ `x${questionIndex}-${index}` }
                          { ...provided.draggableProps }
                          ref={ provided.innerRef }
                          className="form-question_answer"
                        >
                          <div className="form-question_grid">
                            <div
                              className={
                                cn(
                                  "form-question_grid-item -first-answer-item",
                                  { '-transparent': question.answers_order !== 'specific' }
                                )
                              }
                            >
                              <div
                                className="form-question_answer-move"
                                { ...provided.dragHandleProps }
                              >
                                <SvgIcon name="i-move" />
                              </div>
                            </div>
                            <input
                              ref={ (item) => answerChangeRef(item, index) }
                              className="form-field -block -with-margins"
                              type="text"
                              value={ question.answers[index] }
                              onChange={ (event) => {
                                onChangeAnswer(event, index);
                              } }
                              onPaste={ (event) => handlePaste(event, index) }
                              onKeyPress={ (event) => handleKeyPress(event, index) }
                              placeholder="Add Answer Text"
                            />

                            <div className="form-question_grid-item -buttons">
                              {
                                question.answers_order !== 'specific' &&
                                <button
                                  className="button -secondary -circle -delete-color form-question_grid-button -white-back"
                                  onClick={ (event) => {handleChangePinnedAnswer(event, answer, index);} }
                                  disabled={ question.answers.length === 1 }
                                >
                                  <SvgIcon name={ pinnedAnswers[index]?.pinned ? "i-pinned" : "i-unpinned" } />
                                </button>
                              }
                              <button
                                className="button -secondary -circle -delete-color form-question_grid-button -white-back"
                                onClick={ (event) => {delAnswer(event, answer, index);} }
                                disabled={ question.answers.length === 1 }
                              >
                                <SvgIcon name="i-delete" />
                              </button>

                              {
                                question.answers.length - 1 !== index &&
                                <button className="button -circle form-question_grid-button -transparent" />
                              }
                              {
                                question.answers.length - 1 === index &&
                                <button
                                  className="button -circle form-question_grid-button"
                                  onClick={ (event) => {addAnswer(event);} }
                                >
                                  <SvgIcon name="i-add" />
                                </button>
                              }
                            </div>
                          </div>
                          {
                            customSubgroupsMode &&
                            <div className="form-question_answer">
                              <div className="form-question_grid">
                                <div
                                  className={
                                    cn(
                                      "form-question_grid-item -grow -with-margins",
                                      { 'has-error': question?.errors?.answers }
                                    )
                                  }
                                >
                                  <Select
                                    key={ `answer_subgroups-${index}` }
                                    value={ getSubgroups(index + 1) }
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={ subgroups }
                                    placeholder="Select subgroup"
                                    onChange={ (event, action) => { handleAddSubgroups(index + 1, action); } }
                                    isSearchable
                                    isMulti
                                    autosize={ false }
                                    maxMenuHeight={ 150 }
                                    styles={ vgSelectStyles }
                                  />
                                </div>
                                <div className="form-question_grid-item -buttons">
                                  <button className="button -circle form-question_grid-button -transparent" />
                                  <button className="button -circle form-question_grid-button -transparent" />
                                  {
                                    question.answers_order !== 'specific' &&
                                    <button className="button -circle form-question_grid-button -transparent" />
                                  }
                                </div>
                              </div>
                            </div>
                          }
                        </li>
                      )
                    }
                  </Draggable>
                ))
              }
              {provided.placeholder}

              {
                needOther &&
                <AdditionalAnswerLine
                  key={ `other-${main ? 'qf' : 'qs'}` }
                  fieldBoolName={ 'other_answer' }
                  fieldTextName={ 'other_answer_text' }
                  fieldOptionsName={ 'other_specify' }
                  fieldConditionsKey={ 'other' }
                  question={ question }
                  questionIndex={ questionIndex }
                  options={ options }
                  screenerQuestions={ screenerQuestions }
                  setScreenerQuestions={ setScreenerQuestions }
                  onSwitchAdditionalAnswer={ () => { onSwitchOtherAnswer(-2); } }
                  subgroups={ subgroups }
                  needSubgroup={ customSubgroupsMode }
                  handleAddSubgroups={ handleAddSubgroups }
                  getSubgroups={ getSubgroups }
                />
              }
              {
                needNone &&
                <AdditionalAnswerLine
                  key={ `none-${main ? 'qf' : 'qs'}` }
                  fieldBoolName={ 'none_answer' }
                  fieldTextName={ 'none_answer_text' }
                  fieldOptionsName={ 'none_of_the_above' }
                  fieldConditionsKey={ 'noneOfTheAbove' }
                  question={ question }
                  questionIndex={ questionIndex }
                  options={ options }
                  screenerQuestions={ screenerQuestions }
                  setScreenerQuestions={ setScreenerQuestions }
                  onSwitchAdditionalAnswer={ () => { onSwitchOtherAnswer(-1); } }
                  subgroups={ subgroups }
                  needSubgroup={ customSubgroupsMode }
                  handleAddSubgroups={ handleAddSubgroups }
                  getSubgroups={ getSubgroups }
                />
              }
            </ul>
          )
        }
      </Droppable>
    </DragDropContext>
  );
};


export default SQAList;
