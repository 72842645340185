import React, { useState, useLayoutEffect } from 'react';
import { filter, find } from 'lodash';
import SetOfQuestions from './five_point_scale/set_of_questions';

const FivePointScale = (props) => {
  const autoNextStepForIndex = 1000;
  const {
    questions, nextStep, customMetricConcepts, concept, onlyFirstPage, onlySecondPage, custom
  } = props;
  const getList = (onOnePage) => (
    filter(
      questions,
      (question) => (
        onOnePage ? !question.display_dedicated_page : question.display_dedicated_page
      )
    )
  );
  const [ questionsOnOnePage ] = useState(() => getList(true));
  const [ questionsOnSeparatePage ] = useState(() => getList(false));
  const [ pagesCount ] = useState(() => (1 + questionsOnSeparatePage.length));
  const [ pageIndex, setPageIndex ] = useState(() => {
    if (onlyFirstPage) {
      return questionsOnOnePage.length ? 0 : autoNextStepForIndex;
    }
    return (questionsOnOnePage.length && !onlySecondPage) ? 0 : 1;
  });
  useLayoutEffect(() => {
    if (pageIndex === autoNextStepForIndex) {
      nextStep();
    }
  }, []);

  const pageWithAll = !pageIndex;
  const submit = () => {
    const canShowSeparatePageQuestions = !customMetricConcepts || (
      !!find(
        customMetricConcepts,
        (el) => el.confirmit_concept_id === concept.confirmit_concept_id
      )
    );
    if (pageIndex < pagesCount - 1 && canShowSeparatePageQuestions && !onlyFirstPage) {
      setPageIndex(pageIndex + 1);
    } else {
      nextStep();
    }
  };
  const baseProps = { ...props, ...{ nextStep: submit, questions: null } };
  delete (baseProps['questions']);

  const separatePageQuestion = () => (
    questionsOnSeparatePage[pageIndex - 1]
  );

  const separatePageQuestionIsGrid = () => (
    separatePageQuestion().kind === 'Grid'
  );
  return (
    <>
      {
        pageIndex !== autoNextStepForIndex &&
        <>
          {
            pageWithAll &&
            <SetOfQuestions { ...baseProps } questions={ questionsOnOnePage } custom={ custom }  />
          }
          {
            !pageWithAll && separatePageQuestionIsGrid() &&
              <SetOfQuestions { ...baseProps } questions={ [ questionsOnSeparatePage[pageIndex - 1] ] } />
          }
          {
            !pageWithAll && !separatePageQuestionIsGrid() &&
            <SetOfQuestions { ...baseProps } questions={ [ questionsOnSeparatePage[pageIndex - 1] ] } />
          }
        </>
      }
    </>
  );
};

export default FivePointScale;
